<p-overlayPanel #opNotes [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <textarea
            [rows]="5"
            [cols]="30"
            pInputTextarea
            [(ngModel)]="this.activeItem4Editing.lineNotes"
            [disabled]="true"
        ></textarea>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opReceiveNote [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <textarea
            [rows]="7"
            [cols]="50"
            pInputTextarea
            [(ngModel)]="localActiveItem.notes"
            [disabled]="true"
        ></textarea>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel
    #op
    [showCloseIcon]="true"
    [style]="{ width: '600px', height: '500px' }"
>
    <ng-template pTemplate>
        <p-table
            #dt1
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [loading]="isListLoading"
            [value]="searchList"
            [(selection)]="selectedProduct"
            (onRowSelect)="onRowSelect($event)"
            [globalFilterFields]="['itemName']"
            [scrollable]="true"
            scrollHeight="380px"
            scrollWidth="580px"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            (input)="
                                dt1.filterGlobal(
                                    $event.target.value,
                                    'contains'
                                )
                            "
                            placeholder="Search keyword"
                        />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>SKU/Barcode</th>
                    <th pSortableColumn="itemName">
                        Product Name <p-sortIcon field="itemName"></p-sortIcon>
                    </th>
                    <th style="text-align: right">Stock in hand</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product>
                <tr [pSelectableRow]="rowData">
                    <td>{{ product.itemSkuId }}<br />{{ product.barcode }}</td>
                    <td>
                        {{ product.itemName | slice: 0:48 }}
                        <span *ngIf="product.itemName.length > 48">...</span>
                    </td>
                    <td style="text-align: center">
                        {{ product.stockInHand }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<div class="flex align-items-center" >
    <i class="pi pi-arrow-left back-arrow cursor-pointer mb-3" (click)="backButtonClicked()"></i> 
    <h2 class="bs-page-header mb-3">Transfer Receive Form</h2>
</div>

<div class="grid">
    <div class="xl:col-3 lg:col-3 md:col-6 col-12">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="text-700 font-medium text-lg mb-2">Transfer ID</div>
            <div class="text-600">{{ transferId }}</div>
            <br />
            <div class="text-700 font-medium text-lg mb-2">Sender</div>
            <div class="text-600">{{ localActiveItem?.senderBy }}</div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 md:col-6 col-12">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="text-700 font-medium text-lg mb-2">Creation Date</div>
            <div class="text-600">{{ creationDate | date: "fullDate" }}</div>
            <br />
            <div class="text-700 font-medium text-lg mb-2">
                Expected Receiving Date
            </div>
            <div class="text-600">{{ expectedDate | date: "fullDate" }}</div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 md:col-6 col-12">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="text-700 font-medium text-lg mb-2">From Location</div>
            <div class="text-600">{{ fromLocation.locationName }}</div>
            <br />
            <div class="text-700 font-medium text-lg mb-2">To Location</div>
            <div class="text-600">{{ toLocation.locationName }}</div>
        </div>
    </div>
    <div class="xl:col-3 lg:col-3 md:col-6 col-12">
        <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
        >
            <div class="flex justify-content-between">
                <div class="flex align-items-center ml-2 font-medium text-600">
                    Transferred Items
                </div>
                <div class="flex align-items-center font-bold text-lg text-700">
                    {{ totalReceivedQuantity }}
                </div>
            </div>
            <div class="flex justify-content-between mt-3">
                <div class="flex align-items-center ml-2 font-medium text-600">
                    Shipping Cost
                </div>
                <div class="flex align-items-center font-bold text-lg text-700">
                    $ {{ shippingCost | number: "1.2-2" }}
                </div>
            </div>
            <div class="flex justify-content-between mt-3">
                <div class="flex align-items-center ml-2 font-medium text-600">
                    Total Cost
                </div>
                <div class="flex align-items-center font-bold text-lg text-700">
                    $ {{ grandTotal | number: "1.2-2" }}
                </div>
            </div>
            <div class="flex justify-content-center mt-3">
                <div class="flex align-items-center ml-2 font-medium text-600">
                    <!-- Click for Receiveing Note -->
                    <!-- [label]="localActiveItem?.notes.length>0?'Click to view Receiveing Note':'No receiving note'" -->
                    <p-button
                        label="Click to view Transfer Note"
                        class="p-button-secondary"
                        styleClass="p-button-sm"
                        (onClick)="showReceivingNote($event)"
                    ></p-button>
                    <!-- [disabled]="localActiveItem?.notes.length<1" -->
                    <!--  -->
                </div>
                <!-- <div class="flex align-items-center font-bold text-lg text-700">
                    <button
                        pButton
                        type="button"
                        styleClass="p-button-sm"
                        icon="pi pi-book"
                        class="p-button-rounded p-button-info p-button-text"
                        iconPos="right"
                        (click)="showReceivingNote($event)"
                        [disabled]="notes.length<1"
                    ></button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<br />

<div class="conn-card">
    <div
        class="shadow-2 p-2 h-full flex flex-column surface-card"
        style="border-radius: 3px"
    >
        <div class="flex justify-content-between">
            <div class="flex">
                <div class="flex align-items-center justify-content-center">
                    <div
                        class="flex align-items-center ml-2 font-medium text-600"
                    >
                        Receivied By
                        <span class="ml-2">
                            <input
                                type="text"
                                pInputText
                                placeholder="Quick search by scanning barcode"
                                [disabled]="true"
                                [(ngModel)]="receivedBy"
                            />
                        </span>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center">
                    <div
                        class="flex align-items-center ml-5 font-medium text-600"
                    >
                        Receiving Date
                        <span class="ml-2">
                            <p-calendar
                                [(ngModel)]="receivingDate"
                                showIcon="true"
                                [style]="{ width: '150px' }"
                                [disabled]="isViewOnly"
                            ></p-calendar>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex align-items-center justify-content-center">
                <div
                    *ngIf="!isNew"
                    class="flex align-items-center justify-content-center m-2"
                >
                    <button
                        *ngIf="
                            localActiveItem.transfersInStatus == 1 ||
                            localActiveItem.transfersInStatus == 5
                        "
                        pButton
                        type="button"
                        [icon]="isEdit ? 'pi pi-times' : 'pi pi-pencil'"
                        [class]="
                            isEdit
                                ? 'p-button-rounded p-button-outlined'
                                : 'p-button-rounded p-button-outlined'
                        "
                        iconPos="right"
                        (click)="editButtonClick()"
                    ></button>
                </div>

                <div class="flex align-items-center justify-content-center">
                    <p-chip
                        *ngIf="!isNew"
                        [label]="
                            _abd.getTransfersInStatus(
                                activeItem.transfersInStatus
                            )
                        "
                        [styleClass]="
                            _abd.getTransfersInClass(
                                activeItem.transfersInStatus
                            )
                        "
                    ></p-chip>
                    <!-- <p-chip
                        *ngIf="isNew"
                        label="New"
                        [styleClass]="_abd.getTransfersInClass(0)"
                    ></p-chip> -->
                </div>

                <!-- <div class="flex align-items-center justify-content-center m-2">
                    <button
                        *ngIf="activeItem.transfersOutStatus == 1 || isNew"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                        iconPos="right"
                        (click)="removeItem()"
                        [disabled]="isViewOnly || isEnableDeleteButton"
                    ></button>
                </div> -->
            </div>
        </div>
    </div>

    <br />
    <div>
        <p-table
            [value]="dataList"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
        >
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th style="text-align: center; width: 30px">
                        <p-checkbox
                            [binary]="true"
                            (onChange)="selectAllChanged($event)"
                            [disabled]="isViewOnly || !dataList.length"
                        ></p-checkbox>
                    </th> -->
                    <th>Sr#</th>
                    <th>SKU</th>
                    <th>Barcode</th>
                    <th>Description</th>
                    <th>Notes</th>
                    <th>Expiry Date</th>
                    <th>UOM</th>
                    <th>Transfered Qty</th>
                    <th style="text-align: center">Received Qty</th>
                    <th style="text-align: right">Unit Cost ($)</th>
                    <th style="text-align: right">Total Cost ($)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <!-- <td style="text-align: center">
                        <p-checkbox
                            [(ngModel)]="item.selected"
                            [binary]="true"
                            (onChange)="selectionChanged($event)"
                            [disabled]="isViewOnly"
                        ></p-checkbox>
                    </td> -->
                    <td>{{ item.srNo }}</td>
                    <!-- <td>{{ item.itemSKUId }}<br />{{ item.barcode }}</td> -->
                    <!-- <td>
                        {{ item.skuItemCode }}
                        {{ item.productName }}
                    </td> -->
                    <td>
                        {{ item.skuItemCode }}
                    </td>
                    <td>
                        {{ item.barcode }}
                    </td>
                    <td>
                        {{ item.description | slice: 0:25 }}
                        <span *ngIf="item.description?.length > 25">...</span>
                    </td>
                    <td (onFocus)="lineNotesEditClick(e, item)">
                        <button
                            pButton
                            type="button"
                            icon="pi pi-book"
                            [ngClass]="
                                item.lineNotes?.length > 0
                                    ? 'p-button-rounded'
                                    : 'p-button-rounded  p-button-text'
                            "
                            (click)="lineNotesEditClick($event, item)"
                            [disabled]="isViewOnly"
                        ></button>
                        <!-- (click)="lineNotesEditClick($event, item)" -->
                    </td>
                    <td style="width: 180px">
                        <p-calendar
                            appendTo="body"
                            [(ngModel)]="item.expiryDate"
                            [showIcon]="true"
                            inputId="icon"
                            [disabled]="true"
                        ></p-calendar>
                    </td>
                    <td>{{ item.uomDescription }}</td>
                    <td style="text-align: center">
                        {{ item.transferQuantity }}
                    </td>
                    <!-- <td>{{ product.transferQuantity }}</td> -->
                    <td>
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.receivedQuantity"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="
                                        receivedQuantityChanged($event, item)
                                    "
                                    [ngClass]="[
                                        !receivedQuantityValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'center'
                                    }"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!receivedQuantityValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                cannot be greater than transferred quantity
                            </span>
                        </div>
                    </td>
                    <!-- <td style="text-align: right">
                        {{ item.costPrice | number: "1.2-2" }}
                    </td> -->
                    <td style="min-width: 90px">
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.costPrice"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    [inputStyle]="{
                                        width: '110px',
                                        'text-align': 'right'
                                    }"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    (onInput)="costPriceChanged($event, item)"
                                    [ngClass]="[
                                        !costPriceValid(item)
                                            ? 'ng-invalid ng-dirty border-2 border-red-500 flex align-items-center justify-content-center border-round-md'
                                            : ''
                                    ]"
                                    [disabled]="isViewOnly"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                        <div
                            class="flex justify-content-start text-red-500 mt-1"
                            *ngIf="!costPriceValid(item) && !isViewOnly"
                        >
                            <span style="font-size: 12px">
                                Cost price cannot be less than zero (0)
                            </span>
                        </div>
                    </td>
                    <td>
                        <!-- {{ item.totalCost | number: "1.2-2" }} -->
                        <div class="flex justify-content-center">
                            <div class="">
                                <p-inputNumber
                                    [(ngModel)]="item.totalCost"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    inputId="minmax-buttons"
                                    [min]="0"
                                    placeholder="0"
                                    [inputStyle]="{
                                        width: '130px',
                                        'text-align': 'right'
                                    }"
                                    [disabled]="true"
                                >
                                </p-inputNumber>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="11" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="7"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Total Products:
                    </td>
                    <td
                        style="text-align: center"
                        class="text-700 font-medium text-lg"
                    >
                        {{ calculateTransferQuantityTotal() }}
                    </td>
                    <td
                        style="text-align: center"
                        class="text-700 font-medium text-lg"
                    >
                        {{ calculateReceivedQuantityTotal() }}
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td
                        colspan="10"
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        Received Products Cost:
                    </td>
                    <td
                        style="text-align: right"
                        class="text-700 font-medium text-lg"
                    >
                        {{ grandTotal - shippingCost | currency }}
                    </td>
                </tr>
                <!-- <tr class="summary-line" *ngIf="doShowFooter()">
                    <td colspan="10" style="text-align: right">
                        Shipping Cost:
                    </td>
                    <td>
                        <p-inputNumber
                            [disabled]="!dataList.length || isViewOnly"
                            [(ngModel)]="shippingCost"
                            mode="decimal"
                            placeholder="0.00"
                            [minFractionDigits]="2"
                            (onInput)="shippingCostChanged($event)"
                            [maxFractionDigits]="2"
                            [inputStyle]="{ 'text-align': 'right' }"
                        ></p-inputNumber>
                    </td>
                </tr>
                <tr class="summary-line" *ngIf="doShowFooter()">
                    <td colspan="10" style="text-align: right">Total Cost:</td>
                    <td>
                        <div
                            class="flex align-items-center justify-content-center m-1"
                        >
                            <p-inputNumber
                                [disabled]="true"
                                [(ngModel)]="grandTotal"
                                mode="decimal"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                [disabled]="true"
                                [inputStyle]="{ 'text-align': 'right' }"
                            ></p-inputNumber>
                        </div>
                    </td>
                </tr> -->
            </ng-template>
        </p-table>
    </div>

    <br />
    <!-- <p-divider type="dashed"></p-divider> -->
    <div class="grid" *ngIf="dataList.length > 0">
        <div class="field col">
            <label for="notes" class="text-700 font-medium text-lg"
                >Receiving Notes</label
            >
            <textarea
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                class="w-full"
                [(ngModel)]="receivedNotes"
                [disabled]="isViewOnly"
            ></textarea>
        </div>
    </div>

    <br />
    <br />
    <div
        class="flex justify-content-end flex-wrap"
        *ngIf="
            localActiveItem.transfersInStatus == 1 ||
            localActiveItem.transfersInStatus == 2 ||
            localActiveItem.transfersInStatus == 5 ||
            isNew
        "
    >
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="
                (localActiveItem.transfersInStatus == 1 ||
                    localActiveItem.transfersInStatus == 5 ||
                    isNew) &&
                !this._productService.isReject
            "
        >
            <button
                pButton
                type="button"
                label="Save as Draft"
                (click)="saveTransferInventory(5)"
                class="p-button-info"
                [disabled]="!isOk2Save()"
            ></button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="!this._productService.isReject"
        >
            <p-button
                [label]="saveWhatString()"
                (onClick)="saveTransferInventory(getSaveWhat())"
                [disabled]="!isOk2Save()"
            ></p-button>
        </div>
        <div
            class="flex align-items-center justify-content-center m-2"
            *ngIf="
                localActiveItem.transfersInStatus == 1 &&
                this._productService.isReject
            "
        >
            <button
                pButton
                label="Reject"
                class="p-button-danger"
                (click)="cancelTransfer()"
            ></button>
        </div>
    </div>
</div>
