import { BizzCustomersService } from './../../_services/bizz-customers.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PaginatorComponent } from 'src/app/_forms/paginator/paginator.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Table } from 'primeng/table';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { formatDate } from '@angular/common';
import { Paginator } from 'primeng/paginator';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-bizz-customers-list',
  templateUrl: './bizz-customers-list.component.html',
  styleUrls: ['./bizz-customers-list.component.scss']
})
export class BizzCustomersListComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('dt') dataTable: Table;
  @ViewChild('op') op: OverlayPanel
  @ViewChild('p', { static: false }) paginator: Paginator;
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  //order
  orderParam = ''
  orderBy = 'asc'

  dataList: any = null
  activeUserInfo:any = {
    email:null,
    phone:null
  }
  noData:boolean = false;
  //isSubscriptionCompleted:boolean=false;
  // summaryHeader: [['#','Full Name','Status']],
  // colflds: ['index','fullName', 'status'],
  colHeader = [['Contact Name','Business Name','Current Plan','Plan Price','ACC. Status','Next Payment','Revenue Limit','Current Revenue','Contact Email','Contact Number']];
  colflds = [ 'customerName', 'businessName',  'currentPlan','price', 'isActive','nextPayment','currentPlanRevenue','revenueGenerated','email','phone'];
  searchText:any=''
  lastSearchText = ''



  constructor(
    private _bizzCustomerService: BizzCustomersService,
    private _spinner: NgxSpinnerService,
    private _filterService:FilterServiceService,
    private _router: Router,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    this._globals.componentName = `${this._globals.whiteLabel.wlName} Customers`
    this._globals.showProductDelButton = false
    this.getDataList()
  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }



      let filter = this._filterService.getFilters(this._globals.componentName);
    if (filter) {
        if (filter.searchText) {
            this._filterService.searchText = filter.searchText;
            this._filterService.selectedSubscriptionPlan = filter.subscriptionPlan;
            this._filterService.selectedAccountStatus = filter.accountStatus;
            this._filterService.selectedRevenueLimit = filter.revenueLimit;
            this._filterService.dateRange = filter.dateRange;
        }
    }

    let oorderby = this._filterService.getOrderby(
        this._globals.componentName
    );
    if (oorderby) {
        this.orderParam = oorderby.orderParam;
        this.orderBy = oorderby.orderBy;
    }

      this.getDataList()
      clearInterval(ti)
    }, 700);
  }


  getDataList() {
    
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows
    
    let outfilter = {
      filterValue : this._filterService.searchText,
      subscriptionPlan:this._filterService.selectedSubscriptionPlan,
      accountStatus:this._filterService.selectedAccountStatus,
      revenueLimit:this._filterService.selectedRevenueLimit,
      dateRange:this._filterService.dateRange
    }
    this._filterService.updateFilters(this._globals.componentName, outfilter)
    this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })
    this._spinner.show()
    let model:any = {
      "PageNo": this.page,
      "PageSize": this.rows,
      "filterValue": this._filterService.searchText,
      "orderParam":this.orderParam,
      "orderBy":this.orderBy,
    }
    // "startDate": formatDate(this._filterService.dateRangeNextPaymentDueDate[0], 'MM/dd/yyyy', 'en-US'),
    // "endDate": formatDate(this._filterService.dateRangeNextPaymentDueDate[1], 'MM/dd/yyyy', 'en-US'),
    if(this._filterService.dateRangeNextPaymentDueDate?.length > 0 && this._filterService.dateRangeNextPaymentDueDate !==  null){
      model.startDate = formatDate(this._filterService.dateRangeNextPaymentDueDate[0], 'MM/dd/yyyy', 'en-US')
      model.endDate = formatDate(this._filterService.dateRangeNextPaymentDueDate[1], 'MM/dd/yyyy', 'en-US')
    }
    if(this._filterService.selectedSubscriptionPlan !== null){
      model.planName = this._filterService.selectedSubscriptionPlan?.viewValue
    }
    if(this._filterService.selectedAccountStatus !== null){
      model.accountStatus = this._filterService.selectedAccountStatus?.value
    }
     this._bizzCustomerService.getAllCustomers(model).subscribe(res => {
      if (res?.success) {
        this._spinner.hide();
        this.dataList = res.data
        // this.dataList = res.data.filter(f=>(f?.isSubscriptionCompleted || f?.isFreeTrial))
        this.totalRecords = res.totalRecords
        // this.dataList=this.dataList.filter(f=>(f?.isSubscriptionCompleted || f?.isFreeTrial))
        // this.populateDataList(res)

      }else{
          this._spinner.hide();
      }
    })
  }
  getDate(status,date){
    if(status.accountStatusString=='Deactivated Account'){
      return '-'
    }else{
      return date
    }
  }

  actionButtonClick(value:any){
    this.activeUserInfo = {
      email:value.email,
      phone:this._globals.generatePhoneFormatUS(value?.userPhone) 
    }
  }
  clearSearchText() {
    this.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }
  searchFilterText(){
    this.noData = false;
    this.dataTable.filterGlobal(this.searchText, 'contains');
    setTimeout(() => {
      let filtered = this.dataTable.filteredValue && this.dataTable.filteredValue.length > 0;
    //  let filter =  this.dataTable.filteredValue ? true : false
      if(this.searchText && !filtered)
    {
      // setTimeout(() => {
        this.noData = true
      // }, 500);
      }
  else{this.noData = false;}  
   }, 1000); 
 


}
async populateDataList(res: any) {
  try {
    const dataList = await Promise.all(
      res.data.map(async (element) => {
        const customerDetail = await this.getBusinessDetailInfo(element?.urrentSubscription?.businessNameWithDomain);
        return {
          ...element,
          customerDetail,
        };
      })
    );
     this.totalRecords = res.totalRecords
    this.dataList = dataList; 
      this._spinner.hide();
  } catch (error) {
     this._spinner.hide()
  }
}
getBusinessDetailInfo(businessDomainName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      this._bizzCustomerService.getBusinessDetailInfo(businessDomainName).subscribe(
        (response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject('Failed to get business details');
          }
        },
        (error) => {
            this._spinner.hide();
          reject(error);
        }
      );
    } catch (error) {
       this._spinner.hide();
      reject(error); 
    }
  });
}

getPercentage(currentValue, totalValue) {
  if(currentValue !== 0){
  currentValue = currentValue.replace(/[^0-9.-]+/g, "")
  currentValue = Number(currentValue)
  let percentage = 0
      percentage = ( totalValue / currentValue ) * 100;

  let circle = null;
  if (percentage <= 95) {
    circle = 'green-circle'
  } else if (percentage > 95 && percentage < 100) {
    circle = 'orange-circle'
  } else if(percentage > 100){
    circle = 'red-circle'
  }else {
    circle = ''
  }

  return {
      circle:circle
  };
}
}
   clearFilterSearch(){
    this.searchText = ''
    this.searchFilterText()
  }

  searchTextSearch() {
    if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return
    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

  implementLocalFilter() {

  }
  getPlanInterval(interval){
  
  if(interval === 1){
    return "month"
  }else{
    return "year"
  }
   
 }
  callbackFuncFromFilter(event) {
    this.filterButtonPressed()
  }

  filterButtonPressed() {
    this.getDataList();
  }

  getPrice(item:any)
  {
    try
    {
      if(item.isFreeTrial)
      return 0.00;
      if(item.invoice==null)
      return 0.00;
      if(item.subscriptionInvoice==null)
      return 0.00;
      if(item.subscriptionInvoice.plans[0])
      return item.subscriptionInvoice.plans[0].price;
      return 0.00;
    }
    catch{
      return 0.00;
    }
  }
 getAccountStatus(item:any)
  {
    try
    {

      if(item.accountStatusString=='Deactivated Account')
      return 'Deactivated'
      return 'Active'
    }
    catch{
      return 0.00;
    }
  }
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this.getDataList()
  }
  clear(table: Table) {
    table.clear();
}
  onRowSelect(e) {
    if(e.data.currentSubscription != undefined){
        this._bizzCustomerService.activeItem = JSON.parse(JSON.stringify(e.data))
        this._router.navigate(['bizz/customers-detail'])
    }
    else{
        alert('There is no subscription available yet')
    }

  }
  getCurrentEmail(){
   if(this._bizzCustomerService.activeItem !== ''){
    return this._bizzCustomerService.activeItem?.email
   }else{
    return '-'
   }
  }
  getCurrentPhone(){
    if(this._bizzCustomerService.activeItem !== ''){
      return this._bizzCustomerService.activeItem?.email
     }else{
      return '-'
     }
  }
  exportExcel() {
  
    let list = this.dataList.map((v)=>{
      return{
        businessName:v.businessName,
        customerName:v.firstName+' '+v.lastName,
        currentPlan:v.currentSubscription.planName,
        price:'$' + v.currentSubscription.total.toFixed(2) + '/' + (v.invoice.planInterval === 1 ? 'month' : 'year'),
        isActive:v.accountStatusString=='Deactivated Account' ? false : true,
        nextPayment:v.accountStatusString=='Deactivated Account' ? '-' : formatDate(v.currentSubscription.subscriptionExpire , 'dd MMM, yyyy', 'en-US'),
        currentPlanRevenue:'$' +v.currentPlanRevenue.toFixed(2)+'/month',
        revenueGenerated:'$' +v.revenueGenerated.toFixed(2)+'/month',
        phone:v.userPhone,
        email:v.email
      }
    })

    this._abd.exportExcel(list, this.colHeader[0], this.colflds, `${this._globals.whiteLabel.wlName} Customers`,false)
  }

  exportPdf() {
 
    let list = this.dataList.map((v)=>{
      return{
        businessName:v.businessName,
        customerName:v.firstName+' '+v.lastName,
        currentPlan:v.currentSubscription.planName,
        price:'$' + v.currentSubscription.total.toFixed(2) + '/' + (v.invoice.planInterval === 1 ? 'month' : 'year'),
        isActive:v.accountStatusString=='Deactivated Account' ? false : true,
        nextPayment:v.accountStatusString=='Deactivated Account' ? '-' : formatDate(v.currentSubscription.subscriptionExpire , 'dd MMM, yyyy', 'en-US'),
        currentPlanRevenue:'$' +v.currentPlanRevenue.toFixed(2)+'/month',
        revenueGenerated:'$' +v.revenueGenerated.toFixed(2)+'/month',
        phone:v.userPhone,
        email:v.email
      }
  })

    this._abd.exportPdf(list, this.colHeader, this.colflds, `${this._globals.whiteLabel.wlName} Customers`, false,false,false)
  }

  onRowUnselect(e) { }

  onPage(e) {
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'firstName':
        this.orderParam = 'FirstName'
        break;
      case 'businessName':
        this.orderParam = 'BusinessName'
        break;
      case 'nextPaymentInterval':
        this.orderParam = 'NextPaymentInterval'
        break;
      default:
        this.orderParam = 'FirstName'
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  ngOnDestroy() {
    this._globals.componentName = ''
  }
}
