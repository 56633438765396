import { Router, RouterModule } from '@angular/router';
import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-inventory-adjustment',
  templateUrl: './inventory-adjustment.component.html',
  styleUrls: ['./inventory-adjustment.component.scss']
})
export class InventoryAdjustmentComponent implements OnInit {
  userName = ''
  creationDate = new Date()
  expectedDate = new Date(new Date().setDate(new Date().getDate() + 7))

  searchText = ''
  lastSearchText = ''
  searchList: any = ''
  selectedProduct: any = ''
  activeItem: any = ''
  activeItem4Editing: any = ''
  localActiveItem: any = ''
  activeItemDetails: any = ''
  isViewOnly = false
  isEdit = false
  isNew = false
  transferId = ''
  id = 0
  requestedById: any = ''
  selectAll = false

  isLocationError = false
  isEnableDeleteButton = true
  isLoading = false
  isListLoading = false
  dataList: any = []
  locations: any = []
  fromLocation: any = ''
  toLocation: any = ''

  notes = ''
  productCost = 0.0
  shippingCost = 0.0
  grandTotal = 0


  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  @ViewChild('opNotes') opNotes: OverlayPanel

  AdjustmentType = [
    {
      id: 7,
      title: "Inventory Count",
      value: "InventoryCount"
    },
    {
      id: 4,
      title: "Transfer In",
      value: "StockTransferIn"
    },
    {
      id: 5,
      title: "Transfer Out",
      value: "StockTransferOut"

    },
    {
      id: 6,
      title: "Purchase",
      value: "DirectPurchase"
    },
    {
      id: 8,
      title: "Damage",
      value: "Damage"
    },
    {
      id: 9,
      title: "Return to Sender",
      value: "ReturnToSender"
    },
    {
      id: 10,
      title: "Lost",
      value: "Lost"
    },
    {
      id: 11,
      title: "Expired",
      value: "Expired"
    },
    {
      id: 2,
      title: "Auto Adjustment",
      value: "AutoAdjustment"
    }
  ]

  adjustmentTypeModel = <any>{};
  constructor(
    private _productService: ProductsService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    private route: Router,
    private _spinner: NgxSpinnerService,
    public _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Inventory Adjustment'
    this.adjustmentTypeModel = this.AdjustmentType[0]
    this.locations = this._abd.initActLocations()
    this.isViewOnly = this._productService.isViewOnly
    this.isEdit = this._productService.isEdit
    this.isNew = this._productService.isNew

    this.userName = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    this.requestedById = this._globals.userInfo.userID
    if (this.isViewOnly) {
      this.isNew = false
      this.activeItem = this._productService.activeItem

      // console.log('ACTIVE-ITEM', this.activeItem)
      this.requestedById = this.activeItem.senderById
      this._spinner.show()
      this._productService.viewInventoryAdjustmentDetails(this.activeItem.adjustmentId).subscribe(res => {
        if (res.success) {
          this.localActiveItem = res.data
          this.id = this.localActiveItem.id
          this.userName = this.localActiveItem.user
          this.dataList = res.data.items
          this.notes = res.data.notes
          this.creationDate = new Date(this.activeItem.dated)
          this.adjustmentTypeModel = this.AdjustmentType.filter(f => f.id == res.data.adjustmentType)[0]
          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].costPrice = this.dataList[i].unitCost
            this.dataList[i].srNo = i + 1
            this.dataList[i].productName = this.dataList[i].itemName
            this.dataList[i].stockInHand = this.dataList[i].quantity
            this.dataList[i].isValueChanged = false
            this.dataList[i].isCostValueChanged = false
            this.dataList[i].uomDescription = this._abd.uom.find(f => f.id == this.dataList[i].uomId).unitName
            this.dataList[i].lineNotes = this.dataList[i].reason
          }
          this.calculateTotals()

          let lid = res.data.locationId
          this.fromLocation = this._abd.locations.find(f => f.id == lid)


          if (!this.fromLocation) this._abd.showErrorAndQuit('Invalid Location')
        }

        this._spinner.hide()
      })
    }
    // else {
    //     try {
    //       this.fromLocation = this._abd.selectedLocation
    //       if (this.fromLocation) this.toLocation = this.locations.find(f => f.locationId !== this.fromLocation.locationId)
    //       this._abd.selectedExtra1Location = this.toLocation
    //       this.transferId = Math.floor(Date.now() / 1000).toString()
    //       this.userName = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    //       this.requestedById = this._globals.userInfo.userID
    //       if (!this.localActiveItem) {
    //         this.localActiveItem = {
    //           transferNumber: ''
    //         }
    //       }
    //       this.localActiveItem.transferNumber = this.transferId
    //     } catch { }
    //   }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  searchButtonClick(e) {
    if (this.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
    // this.op.toggle(e)

  }

  performSearch(e) {
    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 500);
    params = params.append('locationId', this.fromLocation.id)
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        for (let i = 0; i < this.searchList.length; i++) {
          // this.searchList[i].transferQuantity = 0
          this.searchList[i].totalCost = 0
          this.searchList[i].selected = false
          //this.searchList[i].costPrice = 12.13 // to be deleted
        }

        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 0) this.op.hide()
        this.op.show(e)
      }
    })
  }

  selectAllChanged(event) {
    if (event.checked) {
      if (this.dataList.length) this.isEnableDeleteButton = false
      else {
        this.isEnableDeleteButton = true
      }

      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      this.isEnableDeleteButton = true
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }

  }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {

        ret = true
        break
      }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) {
        this.selectAll = true
      }
      else this.selectAll = false
    }

    const sel = this.dataList.filter(f => f.selected)
    if (sel.length > 0) {
      this.isEnableDeleteButton = false
    }
    else
      this.isEnableDeleteButton = true
  }

  onRowSelect(e) {
    this.insertRow(e.data)
    //this._messageService.add({severity: 'info', summary: 'Product Selected', detail: event.data.name});
  }
  backButtonClicked(){
    window.history.back() 
}
  insertRow(e) {
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }

    let sku = ''
    if (this.isEdit)
      sku = data.skuItemCode
    else
      sku = data.skuCode

    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomDescription = uom.unitName
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.itemSkuId
    data.isValueChanged = true
    data.lineNotes = ''
    data.quantity = 1
    data.subTotal = data.quantity * data.costPrice
    //data.skuItemCode =  data.skuCode
    this.op.hide()

    this.dataList.push(data)
    setTimeout(() => {

      this.calculateTotals()
    }, 500);
  }

  //   updateQuantity(e: any , item: any){

  //     item.quantity = e.value

  //     this.calculateTotals()
  //   }

  //   transferQuantityChanged(e, item) {
  //     try {
  //       item.transferQuantity = e.value
  //       if (!item.transferQuantity) item.transferQuantity = 0
  //       item.totalCost = item.costPrice * e.value
  //       item.isValueChanged = true
  //     }
  //     catch {
  //       item.totalCost = 0.0
  //       e.value = 0
  //     }
  //     this.calculateTotals()
  //   }

  //   transferQuantityValid(item: any) {
  //     if (!item.isValueChanged) return true
  //     if (!item.transferQuantity) return false
  //     if (item.transferQuantity > item.stockInHand) return false
  //     return true
  //   }

  //   costPriceChanged(e, item) {
  //     try {
  //       item.costPrice = e.value
  //       if (!item.costPrice) item.costPrice = 0
  //       item.totalCost = item.costPrice * item.transferQuantity
  //       item.isCostValueChanged = true
  //     }
  //     catch {
  //       item.totalCost = 0.0
  //       e.value = 0
  //     }
  //     this.calculateTotals()
  //   }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  validateLocation() { }
  calculateTotals() {
    this.grandTotal = 0
    for (let i = 0; i < this.dataList.length; i++) {
      //this.grandTotal += this.dataList[i].costPrice
      this.dataList[i].subTotal = this.dataList[i].costPrice * this.dataList[i].quantity
      this.dataList[i].srNo = i + 1
    }
    //console.log( this.dataList.reduce((a,b)=> a+b.subTotal,0))
    this.productCost = this.dataList.reduce((a, b) => a + b.subTotal, 0)
  }

  removeItem() {
    this.dataList = this.dataList.filter(f => !f.selected)
    for (let i = 0; i < this.searchList.length; i++) {
      this.searchList[i].selected = false
    }
    this.isEnableDeleteButton = true
    this.calculateTotals()
    if (this.dataList.length == 0) {
      this.selectAll = false;
    }
  }
  saveAdjustmentInventory(what: any) {

    let inventoryAdjustmentData = [];
    let self = this;
    this.dataList.map(function (value) {
      inventoryAdjustmentData.push({
        "wareHouseId": self.fromLocation.locationId,
        "itemSKUId": value.itemSKUId,
        "quantity": value.quantity,
        "reason": value.lineNotes,
        "adjustmentType": self.adjustmentTypeModel.value,
        "addedBy": self._globals.userInfo.userID,
        "Dated": formatDate(self.creationDate, 'yyyy-MM-dd H:mm', 'en-US')
      })
    })


    this._spinner.show()
    this._productService.inventoryAdjustmentPost(inventoryAdjustmentData).subscribe(res => {
      if (res.success) {
        this._messageService.add({
          severity: 'info',
          summary: 'Success', detail: 'Inventory Adjustment posted successfully!'
        });
        this.dataList = [];
        this._spinner.hide()
        if (what == 1) {
          window.history.back()
          // this.route.navigate(["/app-inventory-adjustment-list"]);
        }

        //window.history.back()

        return false;
      }
    })
  }

  editButtonClick() {
    this._productService.isViewOnly = !this._productService.isViewOnly
    this.isViewOnly = !this.isViewOnly
    this.isEdit = !this.isEdit
    this._productService.isEdit = !this._productService.isEdit
  }

  calculateItemTotal() {
    let total = 0
    for (let i = 0; i < this.dataList.length; i++) {
      total += 1
    }
    return total
  }

  doShowFooter() {
    if (this.dataList.length > 0) return true
    else false
  }

  lineNotesEditClick(e, item) {
    this.activeItem4Editing = item
    this.opNotes.toggle(e)
  }

  cancelAdjustment() {
    window.history.back()
  }

}
