<p-overlayPanel
    #op
    [style]="{
        'min-width': '200px',
        padding: '-20px',
        margin: '-20px'
    }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <ng-container *ngxPermissionsOnly="['2009']">
                <button
                  *ngIf="isNotSupportUser === true"
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  class="p-button-text"
                  [style]="{ width: '100%' }"
                  icon="pi pi-pencil"
                  iconPos="right"
                  (click)="optionEditClicked()"
                ></button>
              </ng-container>
            <button
               *ngxPermissionsOnly="['2010']"
                pButton
                pRipple
                type="button"
                label="Change Status"
                class="p-button-danger p-button-text"
                icon="pi pi-users"
                iconPos="right"
                (click)="optionDeleteClicked()"
                [disabled]="_userService.activeItem.type === 'Client'"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="fadeindown animation-duration-300 flex" *ngIf="1 == 1">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            *ngIf="showReportTopFilter"
            [paraReportName]="'users'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
            (exportCsvFn)="dt.exportCSV()"
            (deleteItemFn)="deleteMultipleItem()"
        ></app-report-top-filter-bar>
        <div class="conn-flex"></div>
        <br />

        <p-table
            *ngIf="dataList?.length"
            #dt
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            [autoLayout]="true"
            responsiveLayout="scroll"
            selectionMode="single"
            [(selection)]="selectedProduct2"
            dataKey="userID"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            stateStorage="local"
            stateKey="statedemo-local"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th style="text-align: left;">Phone</th>
                    <th style="text-align: center">Status</th>
                    <th style="text-align: center" *ngxPermissionsOnly="['2009','2010']">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" class="text-center">
                    <td>
                        <span class="conn-flex-image-first-letter">
                            {{ item.firstName | slice: 0:1
                            }}{{ item.lastName | slice: 0:1 }}</span
                        >
                        {{ item.firstName + " " + item.lastName }}
                    </td>
                    <td>{{ item.username | lowercase }}</td>
                    <td>{{ _globals.generatePhoneFormatUS(item.phone) }}</td>
                    <td
                        style="text-align: center"
                        class="{{
                            item.status == true
                                ? 'Active badge'
                                : 'In-Active badge'
                        }}"
                    >
                        <span>{{
                            item.status == true ? "Active" : "Inactive"
                        }}</span>
                    </td>
                    <td style="text-align: center"  *ngxPermissionsOnly="['2009','2010']">
                        <button
                            pButton
                            pRipple
                            icon="pi pi-angle-down"
                            iconPos="right"
                            type="button"
                            label="Actions"
                            class="n-action-button"
                            (click)="op.toggle($event)"
                            (click)="actionButtonClick(item)"
                            ></button>
                            <!-- [disabled]="isEditButtonEnabled(item)" -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex" *ngIf="dataList?.length == 0">
            <div class="text-align-center" style="width: 100%">
                <span class="control-description text-muted text-align-center">
                    No data found!</span
                >
            </div>
        </div>
        <div *ngIf="totalRecords > 0">
            <p-paginator
                #p
                *ngIf="dataList?.length"
                (onPageChange)="onPageChange($event)"
                [rows]="rows"
                [totalRecords]="totalRecords"
                [totalRecords]="totalRecords"
                [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            >
            </p-paginator>
        </div>

        <!-- <div>
            <app-paginator
                #paginator
                *ngIf="dataList?.length"
                (paginatorCallbackFn)="onPage($event)"
                [page]="page"
                [rows]="rows"
                [totalRecords]="totalRecords"
                [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            ></app-paginator>
        </div> -->
    </div>
</div>

<p-dialog
    *ngIf="
        (_userService.isViewOnly || _userService.showNewEdit) &&
        _router.url.includes('setup/business-setup')
    "
    [closable]="false"
    [modal]="true"
    header="New User"
    [visible]="true"
    [style]="{ width: '80%' }"
>
    <app-new-edit-user
        [isNew]="false"
        (onSubmit)="refrestDataList($event)"
    ></app-new-edit-user>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showNewEditUser"
    [closable]="false"
    [modal]="true"
    header="New User"
    [visible]="true"
    [style]="{ width: '60%' }"
>
    <app-new-edit-user
        [isNew]="true"
        (onSubmit)="refrestDataList($event)"
    ></app-new-edit-user>
</p-dialog>
