import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-configuration-information',
  templateUrl: './configuration-information.component.html',
  styleUrls: ['./configuration-information.component.scss']
})
export class ConfigurationInformationComponent implements OnInit {

  mitems : any

  constructor(
        public _businessSetupService: BusinessSetupService,
        public _abd: AppBaseDataService,
        private _userService: UserService,
        ) {

        }

    ngOnInit(): void {
        this.widgetState()
     
    }

    widgetState(){
      this._abd.showSpinner()
        let nameHolder = this._businessSetupService;
        // console.log( 'data'+this._abd.systemConfigs)
        this.mitems = [
            {
                id: 1,
                partialCSSid: 'configuration-tax-icon',
                partialHeader: 'Tax Rate',
                additionalCSSinformaiton: nameHolder.taxWidgetBS$,
                isSaved: this._abd.systemConfigs.taxConfig.isSaved
            },
            {
                id: 2,
                partialCSSid: 'configuration-invoice-icon',
                partialHeader: 'Invoice Configuration',
                additionalCSSinformaiton: nameHolder.invoiceWidgetBS$,
                isSaved: this._abd.systemConfigs.cashHandlingConfig.isSaved
            },
            {
                id: 3, partialCSSid:
                    'configuration-printing-icon',
                partialHeader: 'Receipt Configuration',
                additionalCSSinformaiton: nameHolder.printingWidgetBS$,
                isSaved: this._abd.systemConfigs.printingConfig.isSaved
            },
            {
                id: 4,
                partialCSSid: 'configuration-security-icon',
                partialHeader: 'Security Configuration',
                additionalCSSinformaiton: nameHolder.securityWidgetBS$,
                isSaved: this._abd.systemConfigs.securityConfig.isSaved
            },
            {
                id: 5,
                partialCSSid: 'configuration-cashdrop-icon',
                partialHeader: 'Cashdrop Configuration',
                additionalCSSinformaiton: nameHolder.cashdropWidgetBS$,
                isSaved: this._abd.systemConfigs.cashDropConfig.isSaved
            },
            {
                id: 6,
                partialCSSid: 'configuration-cashdrawer-icon',
                partialHeader: 'Cashdrawer Configuration',
                additionalCSSinformaiton: nameHolder.cashdrawerWidgetBS$,
                isSaved: this._abd.systemConfigs.cashDrawerConfig.isSaved
            },
            {
                id: 7,
                partialCSSid: 'configuration-tender-icon',
                partialHeader: 'Tender Configuration',
                additionalCSSinformaiton: nameHolder.tenderWidgetBS$,
                isSaved: this._abd.systemConfigs.tenderScreenConfig.isSaved
            },
        ]

        this._businessSetupService.initWidget("tax");
        this._businessSetupService.initWidget("invoice");
        this._businessSetupService.initWidget("printing");
        this._businessSetupService.initWidget("cashdrop");
        this._businessSetupService.initWidget("cashdrawer");
        this._businessSetupService.initWidget("security");
        this._businessSetupService.initWidget("tender");
        this._abd.hideSpinner()
      }

    changeWidgetState(){
        this.widgetState()
    }

  onClick(item: any) {
    switch (item){
      case 1:
        this._businessSetupService.showTaxSetting = true;

        break;
      case 2:
        this._businessSetupService.showInvoiceConfiguration = true;

        break;
      case 3:
        this._businessSetupService.showPrintingConfiguration = true;

        break;
      case 4:
        this._businessSetupService.showSecurityConfiguration = true;

        break;
      case 5:
        this._businessSetupService.showCashdropConfiguration = true;

        break;
      case 6:
        this._businessSetupService.showCashdrawerConfiguration = true;

        break;
      case 7:
        this._businessSetupService.showTenderConfiguration = true;

        break;
    }
  }

}
