import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { Status } from 'src/app/_models/coupon-management';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-bizz-customer-account-info',
  templateUrl: './bizz-customer-account-info.component.html',
  styleUrls: ['./bizz-customer-account-info.component.scss']
})
export class BizzCustomerAccountInfoComponent implements OnInit {
 
businessDetailInfo:any = null


  constructor(
    public _bizzCustomerService: BizzCustomersService,
    private _connMessageService:ConnMessageService,
    public _abd:AppBaseDataService,
    private _confirmationService: ConfirmationService,

  ) { }

  ngOnInit(): void {
       

  }
}
