import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsStates } from 'src/app/_data/us-states';
import { BaseService } from 'src/app/_services/base.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { ConfirmationService } from 'primeng/api';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
interface Status {
  id: number,
  value: string
}
@Component({
  selector: 'app-sales-invoice-detail',
  templateUrl: './sales-invoice-detail.component.html',
  styleUrls: ['./sales-invoice-detail.component.scss']
})
export class SalesInvoiceDetailComponent implements OnInit {
  invoiceNumber: any = ''
  usaStates = UsStates

  _globals = GlobalService

  constructor(
    private _confirmationService: ConfirmationService,
    private _bs: BaseService,
    private _connMessageService: ConnMessageService,
    public _reportService: ReportsService,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    this.invoiceNumber = this._reportService.selectedInvoiceRow.invoiceNumber
    this.invoiceNumber = this._reportService.selectedInvoiceRow.invoiceNumber
    this._abd.showSpinner()
    this._reportService.searchInvoiceByID(this.invoiceNumber).subscribe(res => {
      this._abd.hideSpinner()
      this._reportService.selectedInvoiceDetails = res.data

      const detail = [
        { label: 'Transaction Type', value: res.data.invoiceTypeString },
        { label: 'Dated', value: formatDate(new Date(res.data.dated), 'MM/dd/yy hh:mm a', 'en-US') },
      ]

        if(res.data.isOnlineOrder)
        {
          detail.push( { label: 'Shipping Status', value: res.data.shippingStatusString })
          detail.push({ label: 'Placement', value: 'Online'})
          detail.push({ label: 'Shipping Location', value: res.data.locationName })

        }
        else
        {
          detail.push({ label: 'Placement', value:  'In-Store'}),
          { label: 'Location', value: res.data.locationName },
          detail.push({ label: 'Register', value: res.data.registerName },
          { label: 'Cashier', value: res.data.userName },

          )

        }
        if(res.data.refInvoiceNumber)
        {
          detail.push({ label: 'Refunded Against', value: res.data.refInvoiceNumber })
        }
        if(res.data.returnedInvoices)
        {
          let returnInvoiceNumbers=''
          res.data.returnedInvoices?.forEach(element => {
            returnInvoiceNumbers=returnInvoiceNumbers+' '+element
          });

      }
      if (res.data.refInvoiceNumber) {
        detail.push({ label: 'Refunded Against', value: res.data.refInvoiceNumber })
      }
      if (res.data.returnedInvoices) {
        let returnInvoiceNumbers = ''
        res.data.returnedInvoices?.forEach(element => {
          returnInvoiceNumbers = returnInvoiceNumbers + ' ' + element
        });

        detail.push({ label: 'Refunded Via', value: returnInvoiceNumbers })
      }
      const customerDetails = [
        { label: 'Customer Name', value: res.data.customerName },
        { label: 'Phone', value: this._globals.generatePhoneFormatUS(res.data.customerPhone) },
        { label: 'Awarded Points', value: res.data.pointsAwarded },
        { label: 'Reward Discount ($)', value: res.data.rewardDiscount },
        { label: 'Points Redeemed', value: res.data.redeemedPoints },


      ]

      const documents = [
        { label: 'Receipt', value: res.data.invoiceNumber },

        // { label: 'Shipping', value: 'N/A' },

        // { label: 'Electronic Tender', value: 'N/A' },
        // { label: 'Messages', value: '' },
        // { label: 'Emails', value: '' },
      ]

      let paymentAddress: any = null
      if (res.data.invoiceShipping.length) {
        // let state = this.usaStates.find(f=>f.abbreviation == res.data.invoiceShipping[0].state)
        // if(!state) state=this.usaStates.find(f=>f.name == res.data.invoiceShipping[0].state)
        const obj = {
          address1: res.data.invoiceShipping[0].address1,
          address2: res.data.invoiceShipping[0].address2,
          city: res.data.invoiceShipping[0].city,
          state: res.data.invoiceShipping[0].state + ' ' + res.data.invoiceShipping[0].zipCode
        }
        paymentAddress = obj
      }
    
      let billingAddress: any = null
      if (res.data.invoiceBilling.length) {
        // let state = this.usaStates.find(f=>f.abbreviation == res.data.invoiceShipping[0].state)
        const obj = {
          address1: res.data.invoiceBilling[0].address1,
          address2: res.data.invoiceBilling[0].address2,
          city: res.data.invoiceBilling[0].city,
          state: res.data.invoiceBilling[0].state + ' ,' + res.data.invoiceBilling[0].zipCode
        }
        billingAddress = obj
      }

      this._reportService.selectedInvoiceDetails.invoiceOrderDetail = detail
      this._reportService.selectedInvoiceDetails.invoiceCustomerDetails = customerDetails
      this._reportService.selectedInvoiceDetails.invoiceDocuments = documents
      this._reportService.selectedInvoiceDetails.paymentAddress = paymentAddress
      this._reportService.selectedInvoiceDetails.billingAddress = billingAddress
      this.SelectedStatus = this.OrderStatus.filter(u => u.value == this._reportService.selectedInvoiceDetails.postedStatusString)[0]
      //console.log('INVOICE DETAIL', this._reportService.selectedInvoiceDetails)
    })
  }
  OrderStatus = [
    { id: 1, value: "Completed" },
    { id: 2, value: "Pending Payment" },
    { id: 3, value: "Processing" },
    { id: 4, value: "Hold" },
    { id: 5, value: "Cancelled" },
    { id: 6, value: "Refunded" },
    { id: 7, value: "Failed" },
    { id: 8, value: "Draft" },
  ]

  SelectedStatus: Status;

  showEditDropDown() {

    if (
      this._reportService.selectedInvoiceDetails.isOnlineOrder
      && (
        this._reportService.selectedInvoiceDetails.postedStatusString == 'Processing' ||
        this._reportService.selectedInvoiceDetails.postedStatusString == 'Pending Payment'
        || this._reportService.selectedInvoiceDetails.postedStatusString == 'Hold'
        || this._reportService.selectedInvoiceDetails.postedStatusString == 'Cancelled'
        || this._reportService.selectedInvoiceDetails.postedStatusString == 'Completed'
      )
    )
      return true;
    return false;


  }
  backButtonClicked(){
        window.history.back() 
  }
  StatusChanged(status)
  {
    this.AllowUpdate=false;

  }
  AllowUpdate: boolean = true;
  ChangeOrderStatus() {
    // console.log(this.SelectedStatus)
    this._confirmationService.confirm({
      message: 'Are you sure that you want to change order status?',
      header: 'Update Order Status',
      icon: 'pi pi-info-circle',
      accept: () => {

        this._spinner.show()
        try {
          let PostedStatus = this.SelectedStatus.value.replace(/\s/g, "");
          let ShippingStatus = PostedStatus == "Completed" ? "Dispatched" : "Pending Delivery"
          ShippingStatus = ShippingStatus.replace(/\s/g, "");
          const data = {
            InvoiceNumber: this._reportService.selectedInvoiceDetails.invoiceNumber,
            PostedStatus: PostedStatus,
            ShippingStatus: ShippingStatus,
            InvoiceNote: this._reportService.selectedInvoiceDetails.invoiceNote,
            CustomerNote: this._reportService.selectedInvoiceDetails.customerNote,

          }
          this._reportService.UpdateInvoiceStatus(data).subscribe(res => {
              if(res.success)
              {
               this._reportService.selectedInvoiceDetails.postedStatusString=this.SelectedStatus.value
               this._connMessageService.showToastMessage("Order Updated Sucessfully", 'info');


              }
              else
              {
                this._connMessageService.showToastMessage("Order failed to updated", 'error');
              }
              this._spinner.hide()
          });


        }
        catch {
          this._spinner.hide()
        }
      },
      reject: () => { }
    })
  }


}
