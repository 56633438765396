<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid">
            <div class="flex flex-column">
                <span> <i class="pi pi-envelope p-2" style="color: var(--brandColor);"></i> {{activeUserInfo.email}} </span>
                <span> <i class="pi pi-phone p-2" style="color: var(--brandColor);"></i> {{activeUserInfo.phone}} </span>
    
    
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<div class="fadeindown animation-duration-500 flex" *ngIf="1 == 1">
    <div class="conn-card" style="width: 100%">
        <app-report-top-filter-bar
            [paraReportName]="'Connect Customers'"
            (filterCallbackFunc)="callbackFuncFromFilter($event)"
            (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
            (exportExcelFn)="exportExcel()"
            (exportPdfFn)="exportPdf()"
            (exportCsvFn)="dt.exportCSV()"
            (deleteItemFn)="deleteMultiple()"

        ></app-report-top-filter-bar>
        <br />
        <!-- <div class="flex justify-content-between align-items-center  p-2">
            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clearFilterSearch()"></button>
             <div class="flex">
                <div class="p-float-label hide-1024" >
                    <input
                        type="text"
                        id="bob"
                        [(ngModel)]="searchText"
                        pInputText
                        [style]="{
                            'min-width': '200px',
                            'font-size': '85.rem'
                        }"
                    />
                    <label for="searchtext">Search keyword</label>
                </div>
                <button
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-text"
                (click)="searchFilterText()"
            ></button>
            <button
            style="color: var(--brandColor)"
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            pTooltip="Export options"
            tooltipPosition="left"
            class="p-button-rounded p-button-text p-button-info"
            (click)="op.toggle($event)"
            [disabled]="disableAllControls"
        ></button>
        <p-overlayPanel #op class="exportpanel">
            <ng-template pTemplate>
                <button
                    style="width: 100%; margin-bottom: 1rem"
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-file-excel"
                    (click)="exportExcel()"
                    pTooltip="XLS"
                    tooltipPosition="bottom"
                    label="Export to Excel"
                    class="p-button-outlined p-button-success"
                ></button>
        
                <button
                    style="width: 100%"
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-file-pdf"
                    (click)="exportPdf()"
                    pTooltip="PDF"
                    label="Export to PDF"
                    tooltipPosition="bottom"
                    class="p-button-outlined p-button-warning"
                ></button>
            </ng-template>
        </p-overlayPanel>
             </div>
        </div> -->
        <p-table
            *ngIf="dataList?.length > 0"
            #dt
            [columns]="csvcols"
            styleClass="p-datatable-striped"
            [autoLayout]="true"
            [tableStyle]="{ width: 'max-content' }"
            [value]="dataList"
            responsiveLayout="scroll"
            showLoader="true"
            rowHover="true"
            [customSort]="true"
            selectionMode="single"
            [(selection)]="selectedRow"
            dataKey="id"
            (onRowSelect)="onRowSelect($event)"
            [lazy]="true"
            (onRowUnselect)="onRowUnselect($event)"
            (onLazyLoad)="customSort($event)"
        >
    <!--    <ng-template pTemplate="caption">
            <div class="flex justify-content-between  ">
                <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt)"></button>
                 <div class="flex">
                    <div class="p-float-label hide-1024" >
                        <input
                            type="text"
                            id="bob"
                            [(ngModel)]="searchText"
                            pInputText
                            [style]="{
                                'min-width': '200px',
                                'font-size': '85.rem'
                            }"
                        />
                        <label for="searchtext">Enter Name,Phone</label>
                    </div>
                    <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-search"
                    class="p-button-text"
                    (click)="searchTextSearch()"
                ></button>
                <button
                style="color: var(--brandColor)"
                pButton
                pRipple
                type="button"
                icon="pi pi-download"
                pTooltip="Export options"
                tooltipPosition="left"
                class="p-button-rounded p-button-text p-button-info"
                (click)="op.toggle($event)"
                [disabled]="disableAllControls"
            ></button>
            <p-overlayPanel #op class="exportpanel">
                <ng-template pTemplate>
                    <button
                        style="width: 100%; margin-bottom: 1rem"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-excel"
                        (click)="exportExcel()"
                        pTooltip="XLS"
                        tooltipPosition="bottom"
                        label="Export to Excel"
                        class="p-button-outlined p-button-success"
                    ></button>
            
                    <button
                        style="width: 100%"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-pdf"
                        (click)="exportPdf()"
                        pTooltip="PDF"
                        label="Export to PDF"
                        tooltipPosition="bottom"
                        class="p-button-outlined p-button-warning"
                    ></button>
                </ng-template>
            </p-overlayPanel>
                 </div>


                 <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span> 
            </div>
        </ng-template>-->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="firstName"> <p-sortIcon field="firstName"></p-sortIcon>Contact Name</th>
                    <th pSortableColumn="businessName"> <p-sortIcon field="businessName"></p-sortIcon>Business Name</th>
                    <th>Current Plan</th>
                    <th>Plan Price</th>
                    <th>Acc. Status</th>
                    <th pSortableColumn="currentSubscription.subscriptionExpire"> <p-sortIcon field="currentSubscription.subscriptionExpire"></p-sortIcon> Next Payment</th>
                    <th>Revenue Limit</th>
                    <th>Current Revenue</th>
                    <th>Contact Info</th>
                </tr> 
                <tr *ngIf="noData">
                <td colspan="8">
                <div class="flex justify-content-center" style="width: 100%">
                    <span class="control-description text-muted text-align-center">
                        No data found!</span
                    >
                </div>
                </td>
            </tr>
            </ng-template>
         
            <ng-template pTemplate="body" let-item >
                <tr
                    [pSelectableRow]="item"
                    class="text-center {{
                        item.selected == true ? 'p-highlight' : ''
                    }}"
                >
                <td class="py-3">{{item?.firstName}} {{ item?.lastName}}</td>
                <td>{{ item?.businessName }}</td>
                <td> {{ item.isFreeTrial ? "Free Trial" : item.currentSubscription?item.currentSubscription.planName:'' }}</td>
                <td>{{item?.currentSubscription?.total | currency: "USD":"symbol"}}/<span>{{getPlanInterval(item?.invoice?.planInterval) }}</span> </td>   
                <td [ngClass]="item?.accountStatusString=='Deactivated Account'?'':''"><span class="" [style]="item?.accountStatusString=='Deactivated Account'?'color:red':'color:var(--brandColor)'">{{getAccountStatus(item)}} </span></td> 
                    <!-- <td>{{item.currentSubscription.total | currency: "USD":"symbol"}}</td>    -->
                <td>{{ getDate(item,item?.currentSubscription?.subscriptionExpire) | date:'dd MMM, yyyy'}}</td>
                <td>{{ "$0.00" }}/<span>month</span></td>
                <!-- <td>{{ item?.customerDetail?.currentPlanRevenue}}/<span>month</span></td> -->
                <td><div class="flex align-items-center"><span class="mx-2"></span> {{ 0 | currency }}/<span>month</span></div></td>  
                <!-- <td><div class="flex align-items-center"><span [ngClass]="getPercentage(item?.customerDetail?.currentPlanRevenue, item?.customerDetail?.totalRevenue)?.circle" class="mx-2"></span> {{ item?.customerDetail?.totalRevenue | currency }}/<span>month</span></div></td>   -->
                    <td class="flex justify-content-center ">
                        <button pButton type="button"       (click)="actionButtonClick(item)" (click)="op.toggle($event)" icon="pi pi-ellipsis-h" class="p-button-raised p-button-rounded"></button>
        </td>
                </tr>
            </ng-template>   
        
        
               

        </p-table>
        <div class="flex" *ngIf="dataList?.length == 0">
            <div class="text-align-center" style="width: 100%">
                <span class="control-description text-muted text-align-center">
                    No data found!</span
                >
            </div>
        </div>
        <ng-container 
        *ngIf="dataList?.length > 0">
            <div>
        <div class="flex flex-column py-5 text-sm">
            <span class="flex flex-row align-items-center py-1"> <span class="green-circle mr-2"></span> <span>Within revenue limit</span></span>
            <span class="flex flex-row align-items-center py-1"><span class="orange-circle mr-2"></span> <span>Approaching to revenue limit</span></span>
            <span class="flex flex-row align-items-center py-1"><span class="red-circle mr-2"></span>    <span>Exceeding revenue limit</span></span>
           
            
            
        </div>
           </div>
    </ng-container>

        <ng-container 
        *ngIf="dataList?.length > 0">
    <p-paginator 
        #p
        [first]="first"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Page: {currentPage} of {totalPages}, Total Records: {totalRecords}"
    >
    </p-paginator></ng-container>

        <!-- <app-paginator
            #paginator
            (paginatorCallbackFn)="onPage($event)"
            [page]="page"
            [totalRecords]="totalRecords"
        ></app-paginator> -->
    </div>
</div>

<!-- <p-dialog
    *ngIf="_customerService.showNewEdit"
    header="Edit Customer "
    [(visible)]="_customerService.showNewEdit"
    [style]="{ width: '60%' }"
    modal="true"
>
    <app-customer-info-change
        (refreshDataEmitter)="getDataList()"
    ></app-customer-info-change>
</p-dialog> -->
