import { BizzCommonService } from './bizz-common.service';
import { BaseService } from '../../_services/base.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BizzCustomersService {
  activeItem:any=''
  currentBusinessDetailInfo:any = null
  isCustomerBusinessDetailLoading = false;
  constructor(
    private _bs: BaseService,
    private _bizzCommonService: BizzCommonService
  ) { }

  // getAllCustomers(pageno: any, pagesize: any, filterValue:any) {
  //   // const  url = 'https://connect360.azure-api.net/account-svc/api/'+ `GetBusinessCustomers?pageNo=${pageno}&pageSize=${pagesize}&filterValue=${filterValue}`;
  //   const  url = environment.BusinessBaseURL+ `GetBusinessCustomers?pageNo=${pageno}&pageSize=${pagesize}&filterValue=${filterValue}`;
  //   const header = this._bizzCommonService.makeHttpHeader()
  //   return this._bs.httpGet(url).pipe(map(res => {
  //     return res
  //   }))
  // }
  getAllCustomers(model:any) {
     const  url = environment.BusinessBaseURL+ `GetBusinessCustomers`;
    return this._bs.httpGetParams(url,model).pipe(map(res => {
      return res
    }))
  }
  getBusinessDetailInfo(model:any){
    const  url = environment.BusinessBaseURL+ `business/stats?businessName=${model}`;
    const header = this._bizzCommonService.makeHttpHeader()
    return this._bs.httpGet(url).pipe(map(res => {
      return res
    }))
  }
updateBusinessStatus(CustomerId,Status){
  let url = environment.BusinessBaseURL +`/UpdateBusinessStatus/${CustomerId}/${Status}`
  return this._bs.httpPut(url).pipe(map(res => {
    return res
  }))

  }
}
