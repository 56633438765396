import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { CouponCodeService } from 'src/app/_business-dashboard/_services/bizz-coupon-codes.services';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { DiscountTypeList } from 'src/app/_models/coupon-management';
import { UserService } from 'src/app/_services/user.service';
import { GlobalService } from 'src/app/_services/global.service';
import { AuditTrailService } from 'src/app/_services/audit-trial.service';

@Component({
  selector: 'app-filters-options',
  templateUrl: './filters-options.component.html',
  styleUrls: ['./filters-options.component.scss']
})
export class FiltersOptionsComponent implements OnInit {  
  _globals = GlobalService
  dateRange: any = ''
  dateRangeNextPaymentDueDate: any = []
  baseFilterOptions: any = ''
  items: MenuItem[] = [
    { id: '1', label: 'Last 7 days', command: (event) => { this.dateMenuOption(event) } },
    { id: '2', label: 'Last 15 days', command: (event) => { this.dateMenuOption(event) } },
    { id: '3', label: 'Current Month', command: (event) => { this.dateMenuOption(event) } },
    { id: '4', label: 'Last Month', command: (event) => { this.dateMenuOption(event) } },
    { id: '5', label: 'Current Year', command: (event) => { this.dateMenuOption(event) } },
    { id: '6', label: 'Last Year', command: (event) => { this.dateMenuOption(event) } },
  ]

  @Input() paraReportName: any = ''
  @Input() statusList: any = []
  @Output() filterCallbackFuncUpdateFilter = new EventEmitter<any>()
  @ViewChild("cm") cm: Menu
  @ViewChild('op') model

  searchTextLabel = 'Enter Title, SKU or Barcode'
  locationDropdownText = 'Locations'
  registerDropdownText = 'Registers'
  locationExtra1DropdownText = 'Locations'
  showProductTypeDropDown = false;
  labelProductTypeDropDown = 'Sell Method'
  labelCashier = 'Cashier'
  showSearchText = false
  showSearchButton = true;
  showLocationsDropdown = false
  showExtra1LocationsDropdown = false
  showLocationsMultiselect = false
  showDepartmentDropdown = false
  showCatsDropdown = false
  showVendorsDropdown = false
  showManufacturerDropdown = false
  showSingleDate = false
  showDateRange = false
  showDepartmentDropdownMultiselect = false
  showCatsDropdownMultiselect = false
  showRegisterDropdown = false
  showRegistersMultiselect = false
  showTenderType = false
  showCashier = false
  showSelectedInvAdj = false
  showCashierMessage = true

  showTransactionTypesDropdown = false
  showInvoiceStatusDropdown = false
  showInvoiceTypeDropdown = false
  showGroupByDropdown: boolean = false
  groupbyOptions: any = []
  isReport: boolean = false
  showChannelByDropdown: boolean = false
  showTransferTypeByDropdown: boolean = false
  showTransferStatusByDropdown: boolean = false
  showStatus: boolean = false
  showProductSearchTextInput = false;
  showCouponCodeDiscountTypes: boolean = false;
  showPromotionStatus: boolean = false;
  showSubscriptionPlan: boolean = false;
  showDiscountAmountRange: boolean = false;
  subscriptionPlanDropdownIcon: string;
  subscriptionPlanList: any = [];
  rangeValues: number[] = [20,80];
  dateRangeFrom = null;
  dateRangeTo = null;
  couponDiscountTypeList = DiscountTypeList;
  showEmployee: boolean = false;
  showActivityType: boolean = false;
  showOperationType: boolean = false;
  showUsers: boolean = false;
  usersList:any[] = []
  showNextPaymentDueDate:boolean = false
  showAccountStatus:boolean = false
  showSubscriptionPlans:boolean = false
  showRevenueLimit:boolean = false
 
 
  constructor(
    public _filterService: FilterServiceService,
    public _abd: AppBaseDataService,
    public _purchaseService: PurchaseService,
    public _couponCodeService: CouponCodeService,
    public _subscriptionPlanService: SubscriptionPlanService,
    public _userService:UserService,
    public _auditTrailService:AuditTrailService,
  ) { 
  }

  ngOnInit(): void {

    //--------
    let filter = this._filterService.getFilters(this.paraReportName)
    if (filter) {
      if (filter.searchText) this._filterService.searchText = filter.searchText

      if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
      if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

      if (filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
      if (filter.selectedCategory) this._filterService.selectedCategory = filter.selectedCategory
      if (filter.selectedDepartment) this._filterService.selectedDepartment = filter.selectedDepartment
      if (filter.selectedManufacturer) this._filterService.selectedManufacturer = filter.selectedManufacturer
      if (filter.selectedVendor) this._filterService.selectedVendor = filter.selectedVendor

      if (filter.poStatus) {
        const f = this._purchaseService.purchaseStatusList.find(f => f.value == filter.poStatus)
        if (f)
          this._filterService.selectedStatus = f
        else
          this._filterService.selectedStatus = ''
      }

      const rr = this._abd.getProductSellTypeFilter(filter.selectedProductSellTypeFilter)
      if (filter.selectedProductSellTypeFilter) this._filterService.selectedProductSellTypeFilter = rr
    }
    //--------
    this.getSubscriptionPlans()
    // this.dateRange = this._reportService.dateRange
    let self = this;
    this._abd.reports.map(x => {
      if (x.paraReportName === this.paraReportName) {
        this.showGroupByDropdown = x.reportsFilterOption.showGroupByDropdown
        this.showDepartmentDropdown = x.reportsFilterOption.showDepartmentDropdown
        this.searchTextLabel = x.reportsFilterOption.searchTextLabel
        this.showSearchText = x.reportsFilterOption.showSearchText
        this.showLocationsDropdown = x.reportsFilterOption.showLocationsDropdown
        this.showLocationsMultiselect = x.reportsFilterOption.showLocationsMultiselect
        this.showSingleDate = x.reportsFilterOption.showSingleDate
        this.showDateRange = x.reportsFilterOption.showDateRange
        this.isReport = true
        if (x.groupBy != undefined && x.groupBy.length > 0) {
          self._abd.setGroupByOptions(x.groupBy)
        }
      }
      this.baseFilterOptions = this._abd.appCookedData(false)
      // self._abd.groupby = x.groupBy
    })

    if (this.paraReportName === 'inventory-report') {
      this.showDepartmentDropdown = false
      this.showProductTypeDropDown = true;
      this.showSearchText = true
      this.showProductSearchTextInput = false;
      this.showLocationsDropdown = true
      this.baseFilterOptions = this._abd.appCookedData(true)
      // this.showLocationsMultiselect = false
      // this.showSingleDate = false
      this.showDateRange = true
      this.isReport = true
    } else if (this.paraReportName === 'inventory-count') {
      this.showSearchText = true
      this.showProductSearchTextInput = false;
      this.showProductTypeDropDown = true;
      this.showDepartmentDropdown = true
      //this.showSearchText = true
      this.showLocationsDropdown = true
      this.baseFilterOptions = this._abd.appCookedData(true)
      // this.showLocationsMultiselect = false
      this.showCatsDropdown = true
      this.showVendorsDropdown = true
      this.showManufacturerDropdown = true
      this.isReport = true
    } else if (this.paraReportName === 'products') {
      this.showSearchText = true
      this.showProductSearchTextInput = false;
      this.showDepartmentDropdown = true
      this.showCatsDropdown = true
      this.showVendorsDropdown = true
      this.showManufacturerDropdown = true
      this.showProductTypeDropDown = true;
      // this._filterService.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
      this.labelProductTypeDropDown = "Sell Method"
      // this.showDateRange = true
      this.isReport = true
    } else if (this.paraReportName === 'departments') {
      this.searchTextLabel = 'Enter department name'
      this.showSearchText = true
      // this.showLocationsDropdown = false
      // this.showLocationsMultiselect = false
      // this.showSingleDate = false
      // this.showDateRange = false
      this.isReport = true
    } else if (this.paraReportName === 'category') {
      this.searchTextLabel = 'Enter Category'
      this.showSearchText = true
      this.isReport = true
    } else if (this.paraReportName === 'manufacturer') {
      this.searchTextLabel = 'Enter manufacturer name'
      this.showSearchText = true
      this.isReport = true
    } else if (this.paraReportName === 'vendors') {
      this.searchTextLabel = 'Enter vendor name'
      this.showSearchText = true
      this.isReport = true
    } else if (this.paraReportName === 'sales-invoices') {
      const xx = this._abd.locations
      let filter = this._filterService.getFilters('Sales Invoices')

      if (filter) {
        if (filter.locationIds) {
          this.getSetLocations(filter.locationIds)
        }
        if(filter.postedStatus) this._filterService.selectedTransactionType = this._abd.transactionTypes.find(f => f.id == filter.postedStatus)
        if(filter.invoiceType) this._filterService.selectedInvoiceStatus = this._abd.invoiceStatus.find(f => f.id == filter.invoiceType)
        if(filter.orderPlacement) this._filterService.selectedProductSellTypeFilter = this._abd.productSellTypeFilter.find(f => f.Value == filter.orderPlacement)
      }

      this.showSearchText = true
      this.showLocationsMultiselect = true
      this.showDateRange = true
      this.showTransactionTypesDropdown = true
      this.showInvoiceStatusDropdown = true
      this.showProductTypeDropDown = true
      this.isReport = true
    }
    else if (this.paraReportName === 'Customers') {
      this.searchTextLabel = 'Enter Name or Phone'
      this.showSearchText = true
      this.showLocationsMultiselect = false
      this.showDateRange = false
      this.showTransactionTypesDropdown = false
      this.showInvoiceStatusDropdown = false
      this.showInvoiceTypeDropdown = false
      this.isReport = true
    }
    else if (this.paraReportName === 'customer-group-report') {
      this.showSearchText = false
      this.showLocationsMultiselect = true
      this.showDateRange = true
      this.showProductTypeDropDown = false
      this.isReport = true
    }
    else if (this.paraReportName === 'audit-trial') {
     this.showSearchText = false;
      this.showLocationsMultiselect = false;
      this.searchTextLabel = "Search"
      this.isReport = true
      this.showDateRange = true
      this.showActivityType = true
      this.showOperationType = true
      this.showUsers = false
      this.getDataList();
    }
     else if (this.paraReportName === 'Connect Customers') {
      this.searchTextLabel = 'Enter Name or Phone'
      this.showSearchText = true
      this.showLocationsMultiselect = false
      this.showNextPaymentDueDate = true
      this.showAccountStatus = false
      this.showSubscriptionPlans = true
      this.showTransactionTypesDropdown = false
      this.showInvoiceStatusDropdown = false
      this.showInvoiceTypeDropdown = false
      this.isReport = true
    }
    else if (this.paraReportName === 'importinventory') {
      this.searchTextLabel = ''
      this.showSearchText = false
      this.showLocationsMultiselect = false
      this.showDateRange = false
      this.showTransactionTypesDropdown = false
      this.showInvoiceStatusDropdown = false
      this.showInvoiceTypeDropdown = false
      this.isReport = true
    } else if (this.paraReportName === 'daily-sales-report') {
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    }
    else if (this.paraReportName === 'time-tracking-summary-report') {
      // this.showLocationsMultiselect = true
      this.showDateRange = true
      this.isReport = true
    }else if (this.paraReportName === 'time-tracking-detail-report') {
      // this.showLocationsMultiselect = true
      this.showDateRange = true
      this.isReport = true
    } 
    else if (this.paraReportName === 'tip-summary-report') {
      this.showRegistersMultiselect = true
      this.showLocationsMultiselect = true
      this.showCashier = true
      this.showDateRange = true
      this.isReport = true
    }else if (this.paraReportName === 'tip-detail-report') {
      this.showLocationsMultiselect = true
      this.showRegistersMultiselect = true
      this.showCashier = true
      this.showDateRange = true
      this.isReport = true
    } 
    else if (this.paraReportName === 'tender-report') {
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    } else if (this.paraReportName === 'hourly-sales-report') {
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
      this.showDateRange = false
      this.showSingleDate = true
      this.showProductTypeDropDown = true;
    } else if (this.paraReportName === 'department-sales-report') {
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.showDepartmentDropdown = true
      this.showLocationsMultiselect = true
      this.isReport = true

    } else if (this.paraReportName === 'category-sales-report') {
      this.showDateRange = true
      this.showProductTypeDropDown = true
      this.showSearchText = false
      this.showCatsDropdown = true
      this.showGroupByDropdown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.searchTextLabel = 'Enter search text'
      this.isReport = true

    } else if (this.paraReportName === 'sales-tax-report') {
      // this.showProductTypeDropDown = true
      this.showDateRange = true
      this.showLocationsMultiselect = true
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    } else if (this.paraReportName === 'credit-card-sales-report') {
      this.showProductTypeDropDown = true
      // this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    } else if (this.paraReportName === 'credit-card-summary-report') {
      this.showProductTypeDropDown = true
      // this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    } else if (this.paraReportName === 'return-report') {
      this.showProductTypeDropDown = true
      // this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true
    } else if (this.paraReportName === 'discount-sales-report') {

      this.showDateRange = true
      this.showProductTypeDropDown = false
      this.showSearchText = false
      this.showCatsDropdown = false
      this.showGroupByDropdown = true
      this.showLocationsMultiselect = true
      this.searchTextLabel = 'Enter search text'
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.isReport = true

    } else if (this.paraReportName === 'loyalty-discount-report') {

        this.showDateRange = true
        this.showProductTypeDropDown = false
        this.showSearchText = false
        this.showCatsDropdown = false
        this.showGroupByDropdown = false
        this.showLocationsMultiselect = true
        this.searchTextLabel = 'Enter search text'
        this.showProductTypeDropDown = true
        this.labelProductTypeDropDown = 'Sell Method'
        this.isReport = true

      }
      else if (this.paraReportName === 'loyalty-customer-report') {

        this.showDateRange = true
        this.showProductTypeDropDown = false
        this.showSearchText = false
        this.showCatsDropdown = false
        this.showGroupByDropdown = true
        this.showLocationsMultiselect = true
        this.searchTextLabel = 'Enter search text'
        this.showProductTypeDropDown = true
        this.labelProductTypeDropDown = 'Sell Method'
        this.isReport = true
      }
      else if (this.paraReportName === 'tiered-reward-report') {

        this.showDateRange = true
        this.showProductTypeDropDown = false
        this.showSearchText = false
        this.showCatsDropdown = false
        this.showGroupByDropdown = false
        this.showLocationsMultiselect = true
        this.searchTextLabel = 'Enter search text'
        this.showProductTypeDropDown = true
        this.labelProductTypeDropDown = 'Sell Method'
        this.isReport = true
      }
      else if (this.paraReportName === 'tiered-reward-summary-report') {

        this.showDateRange = true
        this.showProductTypeDropDown = false
        this.showSearchText = false
        this.showCatsDropdown = false
        this.showGroupByDropdown = false
        this.showLocationsMultiselect = true
        this.searchTextLabel = 'Enter search text'
        this.showProductTypeDropDown = true
        this.labelProductTypeDropDown = 'Sell Method'
        this.isReport = true
      }
      else if (this.paraReportName === 'tiered-reward-product-report') {

        this.showDateRange = true
        this.showProductTypeDropDown = false
        this.showSearchText = false
        this.showCatsDropdown = false
        this.showGroupByDropdown = false
        this.showLocationsMultiselect = true
        this.searchTextLabel = 'Enter search text'
        this.showProductTypeDropDown = true
        this.labelProductTypeDropDown = 'Sell Method'
        this.isReport = true
      }
    else if (this.paraReportName === 'loyalty-report') {
      this.showDateRange = true
      this.showProductTypeDropDown = true
      this.isReport = true
      this.showLocationsMultiselect = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.showSearchText = false
      this.searchTextLabel = 'Enter search text'
    }
    else if (this.paraReportName === 'customer-group-list') {
      this.showDateRange = false
      this.showProductTypeDropDown = false
      this.isReport = false
      this.showLocationsMultiselect = false
      this.showSearchText = true
      this.searchTextLabel = 'Enter search text'
    }
    else if (this.paraReportName === 'discounts') {
      this.showDateRange = false
      this.showSearchText = false
      this.showCatsDropdown = false
      this.showGroupByDropdown = false
      // this.showLocationsMultiselect = true
      this.showLocationsDropdown = true
      this.searchTextLabel = 'Enter search text'
      this.showProductTypeDropDown = true
      this.labelProductTypeDropDown = 'Channel'
      this.isReport = true
      this.showChannelByDropdown = true
    }
    else if (this.paraReportName === 'coupon-summary') {
      // Coupon Report
      this.showDateRange = true
      this.showCouponCodeDiscountTypes = true
      this.showPromotionStatus = true;
      this.showSubscriptionPlan = true;
      this.showDiscountAmountRange = true;
    }
    else if (this.paraReportName === 'product-report') {
      this.showDateRange = true
      this.showProductTypeDropDown = true
      this.isReport = true
      this.showLocationsMultiselect = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.showSearchText = false
      this.searchTextLabel = 'Enter search text'
      this.showCatsDropdown = false
      this.showCatsDropdownMultiselect = true
      // this.showDepartmentDropdown = true
      this.showDepartmentDropdownMultiselect = true
    }
    else if (this.paraReportName === 'gross-profit-report') {
      this.showDateRange = true
      this.showProductTypeDropDown = true
      this.isReport = true
      this.showLocationsMultiselect = true
      this.labelProductTypeDropDown = 'Sell Method'
      this.showSearchText = false
      this.searchTextLabel = 'Enter search text'
      this.showCatsDropdownMultiselect = false
      this.showDepartmentDropdownMultiselect = false
    }
    else if (this.paraReportName === 'transfer-report') {
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false

      this.locationDropdownText = 'Sender Store'
      this.locationExtra1DropdownText = 'Received Store'
      this.showLocationsDropdown = true
      this.showExtra1LocationsDropdown = true
      this.showTransferTypeByDropdown = true
      this.showTransferStatusByDropdown = true
    }
    else if (this.paraReportName === 'transfer-detail-report') {
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false

      this.locationDropdownText = 'Sender Store'
      this.locationExtra1DropdownText = 'Received Store'
      this.showLocationsDropdown = true
      this.showExtra1LocationsDropdown = true
      this.showTransferTypeByDropdown = true
      this.showTransferStatusByDropdown = true
    }
    else if (this.paraReportName === 'cash-detail-report' || this.paraReportName === 'cash-count-report') {
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false

      this.locationDropdownText = 'Sender Store'
      this.showLocationsMultiselect = true
      this.showCashier = true
    }
    else if (this.paraReportName === 'transfer-list') {
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false

      this.locationDropdownText = 'Sender Store'
      this.locationExtra1DropdownText = 'Received Store'
      this.showLocationsDropdown = true
      this.showExtra1LocationsDropdown = true
    }
    else if (this.paraReportName === 'transfer-receive-list') {
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false

      this.locationDropdownText = 'Received Store'
      this.locationExtra1DropdownText = 'Sender Store'
      this.showLocationsDropdown = true
      this.showExtra1LocationsDropdown = true
    }
    else if (this.paraReportName === 'purchase-orders-list' || this.paraReportName === 'purchase-orders-receive-list') {
      this.showStatus = true
      this.showDateRange = true
      this.showSearchText = true
      this.showVendorsDropdown = true
      this.searchTextLabel = 'Enter PO number'
      this.locationDropdownText = 'Location'
      this.showLocationsDropdown = true
    }
    else if (this.paraReportName === 'cash-reconciliation-report') {
      this.isReport = true
      this.showDateRange = true
      this.showLocationsDropdown = true
      this.showLocationsMultiselect = false
      this.showSearchText = false
      this.labelCashier = 'Counted By'
      this.showCashier = false
      this.showCashierMessage = false
      this.showRegisterDropdown = false
    }
    else if (this.paraReportName === 'purchasing-report') {
      this.showStatus = true
      this.showDateRange = true
      this.showSearchText = true
      this.showVendorsDropdown = true
      this.searchTextLabel = 'Enter PO number'
      this.locationDropdownText = 'Location'
      this.showLocationsDropdown = true
    }
    else if (this.paraReportName === 'app-inventory-adjustment-list') {
      this.showSearchText = true
      this.searchTextLabel = 'Search By User Name'
      this.showLocationsDropdown = true
      this.showSelectedInvAdj = true
      this.showDateRange = true
      this.isReport = true
      this.showSearchText = false
      this.locationDropdownText = 'Location'
      this.showExtra1LocationsDropdown = false
    }

    else if (this.paraReportName === 'app-inventory-adjustement-summary-report') {
      this.showLocationsDropdown = true
      this.showStatus = false
      this.showSelectedInvAdj = true
      this.showDateRange = true
      this.showSearchText = false
      this.searchTextLabel = 'Search By Adjustment ID'
      this.locationDropdownText = 'Location'

    }
    else if (this.paraReportName === 'app-inventory-adjustment-detail') {
      this.showLocationsDropdown = true
      this.showSelectedInvAdj = true
      this.showStatus = false
      this.showDateRange = true
      this.showSearchText = true
      this.searchTextLabel = 'Search By SKU/Barcode/Name'
      this.locationDropdownText = 'Location'
    }
  }

  getSubscriptionPlans(){
    this.subscriptionPlanDropdownIcon = 'pi pi-spin pi-spinner';
    this._subscriptionPlanService.getSubscriptionPlanList().subscribe(res => {
    if (res.success) {
      this.subscriptionPlanDropdownIcon = 'pi pi-chevron-down';
      let subscriptionPlans = res.data
          subscriptionPlans = subscriptionPlans.filter((v)=>v.isTrial !== true)   
          this.subscriptionPlanList = subscriptionPlans.map((v,index,array)=>{
          return{
          id:v.id,
          viewValue:v.title
        }   
      })   
     }
   })
}
  getSetLocations(locs: any) {
    this._filterService.selectedLocation = []

    this._filterService.selectedLocation = []
    for (let i = 0; i < locs.length; i++) {
      const d = this._abd.locations.find(f => f.locationId == locs[i])
      if (d) this._filterService.selectedLocation.push(d)
    }
  }

  ngAfterViewInit() {
    this.dateRange = this._abd.dateRange
    this.dateRangeNextPaymentDueDate = this._abd.dateRangeNextPaymentDueDate
  }

  filterButtonPressed() {
    if (!this._abd.dateRange[0]) this._abd.dateRange[0] = new Date()
    if (!this._abd.dateRange[1]) this._abd.dateRange[1] = this._abd.dateRange[0]

    if (this.paraReportName === 'transfer-list') {

    }

    if (this.paraReportName === 'products') {
      this._abd.productsearchText = this._abd.searchText
    }
    if (this.paraReportName === 'inventory-report') {
      this._abd.OverViewproductsearchText = this._abd.searchText
    }
    
    //this._abd.tempSearchText = this._abd.searchText
    this.filterCallbackFuncUpdateFilter.emit()
  }

  dateMenuOption(event) {
    const opt = event.item.id * 1
    const today = new Date()
    let sd: any = ''
    let ed: any = ''

    switch (opt) {
      case 1:
        this.dateRange = []
        sd = new Date(new Date().setDate(new Date().getDate() - 7))
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 2:
        this.dateRange = []
        sd = new Date(new Date().setDate(new Date().getDate() - 15))
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 3:
        this.dateRange = []
        sd = new Date(today.getFullYear(), today.getMonth(), 1)
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 4:
        this.dateRange = []
        sd = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        ed = new Date(today.getFullYear(), today.getMonth(), 0)
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 5:
        this.dateRange = []
        sd = new Date(today.getFullYear(), 0, 1)
        ed = new Date()
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
      case 6:
        this.dateRange = []
        sd = new Date(today.getFullYear() - 1, 0, 1)
        ed = new Date(today.getFullYear() - 1, 11, 31)
        this.dateRange.push(sd)
        this.dateRange.push(ed)
        break
    }

    this._filterService.dateRange = this.dateRange
    this.cm.hide()
  }

  locationDropdownClear(e) {
    this._filterService.selectedLocation = null
  }
  getDataList() {

    const obj:any = {
      "Name": "GETALLEMPLOYEES",
      "User": {
        "ParentID": (this._globals?.userInfo.parentID != '' ? this._globals.userInfo.userID : this._globals.userInfo.parentID),
        "CompanyName": this._globals?.userInfo.businessSetup[0].businessName.toUpperCase()
      },
      "PageNo": 0,
      "PageSize": 999999,
      "filterValue": this._filterService.searchText
    }
     setTimeout(() => {
      try {
       this._userService.getAllUsers(obj)
      .subscribe((res) => {
     this.usersList = res.data
      })
      } catch { 
        this.usersList=[]
      }
    }, 500);

  }

}
