<div class="grid">
    <div class="mb-12 pt-2">
        <h1 class="fw-bolder d-flex align-items-center text-dark mx-2 wizard-title-active ">
          Configuration
          <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            ngbTooltip="Billing is issued based on your selected account type"
          ></i>
        </h1>

        <div class="text-gray-400 fw-bold fs-6 mx-2">
          Set basic configurations according to your business
        </div>
    </div>
</div>

<div class="flex align-items-center align-content-center p-3 pt-2 mb-12 mt-2" id="skip-warning">
    <div class="col-1 align-content-evenly">
        <span id="skip-icon" style="font-size: 1rem;"
        class="pi pi-info"
    ></span>
    </div>
    <div class="col-9">
        <div class="flex align-items-center align-content-center">

            <div class="col-12">
                <h6 class="fw-bolder d-flex align-items-center text-dark mx-2 mb-0 " >
                     You can skip this step by pressing Finish, all configuration will set to default.
                  </h6>
            </div>
            <!-- <div class="col-12">
                <div class="text-gray-400 fw-bold fs-6 mx-2">
                    Dont worry! you would be able to set your business configurations from the dashboard settings.
                </div>
            </div> -->
        </div>
    </div>
</div>


<div class="grid justify-content-center" >
    <div *ngFor="let item of mitems">

        <app-configuration-button-partial
            [partialCSSid]="item.partialCSSid"
            [partialHeader]="item.partialHeader"
            [isSaved]="item.isSaved"

            [additionalCSSinformaiton]="item.additionalCSSinformaiton"
            (click)="onClick(item.id)"
            (widgetSavedContainer)="changeWidgetState()"
        ></app-configuration-button-partial>
    </div>

</div>

<p-dialog
    *ngIf="_businessSetupService.showTaxSetting"
    header="Tax Rate"
    [(visible)]="_businessSetupService.showTaxSetting"
    [style]="{width: '25%'}"
    modal="true"
>
    <app-tax-setting (widgetSaved)="changeWidgetState()"></app-tax-setting>
</p-dialog>


<p-dialog
    *ngIf="_businessSetupService.showInvoiceConfiguration"
    header="Invoice Configuration"
    [(visible)]="_businessSetupService.showInvoiceConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-invoice-configuration (widgetSaved)="changeWidgetState()"></app-invoice-configuration>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showPrintingConfiguration"
    header="Receipt Configuration"
    [(visible)]="_businessSetupService.showPrintingConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-printing-configuration (widgetSaved)="changeWidgetState()"></app-printing-configuration>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showSecurityConfiguration"
    header="Security Configuration"
    [(visible)]="_businessSetupService.showSecurityConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-security-configuration (widgetSaved)="changeWidgetState()"></app-security-configuration>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showCashdropConfiguration"
    header="Cashdrop Configuration"
    [(visible)]="_businessSetupService.showCashdropConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-cashdrop-configuration (widgetSaved)="changeWidgetState()"></app-cashdrop-configuration>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showCashdrawerConfiguration"
    header="Cashdrawer Configuration"
    [(visible)]="_businessSetupService.showCashdrawerConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-cashdrawer-configuration (widgetSaved)="changeWidgetState()"></app-cashdrawer-configuration>
</p-dialog>

<p-dialog
    *ngIf="_businessSetupService.showTenderConfiguration"
    header="Tender Configuration"
    [(visible)]="_businessSetupService.showTenderConfiguration"
    [style]="{width: '40%'}"
    modal="true"
>
    <app-tender-configuration (widgetSaved)="changeWidgetState()"></app-tender-configuration>
</p-dialog>
