   

<div class="flex align-items-center">
    <i class="pi pi-arrow-left back-arrow cursor-pointer" (click)="backButtonClicked()"></i>  
 <h2 class="bs-page-header">
        Invoice # - {{ _reportService.selectedInvoiceRow.invoiceNumber }}
        <span style="color: red"></span>
    </h2>

</div>

<div class="grid">
    <div class="col-12 md:col-12 lg:col-12 xl:col-4">
        <div class="conn-container">
            <div class="items w-full">
                <div class="items-head">
                    <p>
                        Order Notes Details
                        <span style="font-size: 1rem">
                            <!-- #({{
                                _reportService.selectedInvoiceRow.invoiceNumber
                            }}) -->
                        </span>
                    </p>
                    <table style="width:100%">
                        <thead>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Order Status</td>
                                <td style="text-align: right;">
                                    <div *ngIf="showEditDropDown()" class="manage-oder-block">
                                        <!-- <label style="margin:auto auto;    margin-right: 7px;
                                font-size: 16px;
                                font-weight: bold;">Order Status </label> -->
                                        <p-dropdown style="width: 100%;"  [options]="OrderStatus" [(ngModel)]="SelectedStatus"
                                            (onChange)="StatusChanged($event)" optionLabel="value"></p-dropdown>
                                    </div>
                                    <span *ngIf="!showEditDropDown()" >{{_reportService.selectedInvoiceDetails.postedStatusString}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Invoice Note:</td>
                                <td><textarea style="width:100%" pInputTextarea [(ngModel)]="_reportService.selectedInvoiceDetails.invoiceNote"></textarea></td>
                            </tr>
                            <tr>
                                <td>Customer Note:</td>
                                <td><textarea style="width:100%" pInputTextarea [(ngModel)]="_reportService.selectedInvoiceDetails.customerNote"></textarea></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td style="text-align: right;">
                                    <p-button label="Update Order" icon="pi pi-save" iconPos="right" (onClick)="ChangeOrderStatus()"
                                    class="updatebtn"></p-button></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: right;">
                                <br>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-12 lg:col-12 xl:col-3">
        <div>
            <app-partial-invoice-order-detail></app-partial-invoice-order-detail>
        </div>
    </div>
    
    <div class="col-12 md:col-12 lg:col-12 xl:col-3">
        <div>
            <app-invoice-customer-details></app-invoice-customer-details>
        </div>

    </div>
    <div class="col-6 md:col-6 lg:col-6 xl:col-2">
        <div>
            <app-invoice-documents-details></app-invoice-documents-details>
        </div>
    </div>
</div>

<div *ngIf="_reportService.selectedInvoiceDetails?.isOnlineOrder">
    <hr />
    <div class="grid">
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <div>
                <app-invoice-payment-address></app-invoice-payment-address>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <div>
                <app-invoice-shipping-address></app-invoice-shipping-address>
            </div>
        </div>
    </div>
</div>



<!-- <div>
    <div class="grid-4x">
        <div class="conn-container">
            <div class="items">
                <div class="items-head">
                    <table>
                        <thead>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Order Status</td>
                                <td style="text-align: right;">
                                    <div *ngIf="showEditDropDown()" class="manage-oder-block">



                                        <!-- <label style="margin:auto auto;    margin-right: 7px;
                                font-size: 16px;
                                font-weight: bold;">Order Status </label> -->
                                        <!-- <p-dropdown style="width: 100%;"                               [options]="OrderStatus" [(ngModel)]="SelectedStatus"
                                            (onChange)="StatusChanged($event)" optionLabel="value"></p-dropdown> -->


                                    <!-- </div> -->
                                    <!-- <span *ngIf="!showEditDropDown()" >{{_reportService.selectedInvoiceDetails.postedStatusString}}</span> -->
                                <!-- </td>
                            </tr> -->
                            <!-- <tr>
                                <td>Invoice Note:</td>
                                <td><textarea pInputTextarea [(ngModel)]="_reportService.selectedInvoiceDetails.InvoiceNote"></textarea></td>


                            </tr>
                            <tr>
                                <td>Customer Note:</td>
                                <td><textarea pInputTextarea [(ngModel)]="_reportService.selectedInvoiceDetails.CustomerNote"></textarea></td>

                            </tr>

                            <tr>
                                <td></td>
                                <td style="text-align: right;">
                                    <p-button label="Update Order" icon="pi pi-save" iconPos="right" (onClick)="ChangeOrderStatus()"
                                    class="updatebtn"></p-button></td>

                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: right;">
                                <br>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <!-- <div>
            <app-partial-invoice-order-detail></app-partial-invoice-order-detail>
        </div>
        <div>
            <app-invoice-customer-details></app-invoice-customer-details>
        </div>
        <div>
            <app-invoice-documents-details></app-invoice-documents-details>
        </div>
    </div> -->

    <!-- <div *ngIf="_reportService.selectedInvoiceDetails?.isOnlineOrder">
        <hr />
        <div class="grid-2x">
            <div>
                <app-invoice-payment-address></app-invoice-payment-address>
            </div>

            <div>
                <app-invoice-shipping-address></app-invoice-shipping-address>
            </div>
        </div>
    </div> -->
<!-- </div> -->
<br>
<div class="grid">
   
    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
        <div class="conn-card">
            <div>
                <app-partial-invoice-detail></app-partial-invoice-detail>
            </div>
        </div>
    </div>
</div>

<br />
<div class="grid">
    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
        <div class="conn-card">
            <app-invoice-payment-details></app-invoice-payment-details>
        </div>
    </div>
</div>
