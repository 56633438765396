<p-sidebar
    [(visible)]="displayFilterPanel"
    position="right"
    [baseZIndex]="10000"
    [style]="{ 'min-width': '300px' }"
>
    <app-filters-options
        [paraReportName]="paraReportName"
        [statusList]="statusList"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
    >
    </app-filters-options>
</p-sidebar>

<p-overlayPanel #op class="exportpanel">
    <ng-template pTemplate *ngIf="showExportOption && showTopFilterbar">
        <button
            style="width: 100%; margin-bottom: 1rem"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="exportExcel()"
            pTooltip="XLS"
            tooltipPosition="bottom"
            label="Export to Excel"
            class="p-button-outlined p-button-success"
        ></button>

        <button
            style="width: 100%"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-pdf"
            (click)="exportPdf()"
            pTooltip="PDF"
            label="Export to PDF"
            tooltipPosition="bottom"
            class="p-button-outlined p-button-warning"
        ></button>
    </ng-template>
</p-overlayPanel>

<div class="conn-grid">
    <div>
        <h2 class="bs-page-header">
            {{ _globals.componentName }}
        </h2>
        <p class="text-xl font-bold text-500 ml-2">
            {{ _globals.secondTitle }}
        </p>
    </div>

    <div
        style="margin-top: 5px"
        *ngIf="!displayFilterPanel && showTopFilterbar"
    >
        <div class="child2">
            <div class="p-float-label hide-1024" *ngIf="showDefaultLocation">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '220px'
                    }"
                    class="p-dropdown-1"
                    [options]="baseFilterOptions.locations"
                    [(ngModel)]="_filterService.selectedLocation"
                    optionLabel="locationName"
                    (onChange)="locationChanged($event)"
                    [disabled]="disableAllControls"
                ></p-dropdown>
                <label for="defaultLocations">Select your location</label>
            </div>
   <!-- Activity Type -->
   <div
   class="p-float-label hide-1024"
   *ngIf="showActivityType"
>
        <p-dropdown
        [style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
        class="p-dropdown-1"
        appendTo="body"
        [options]="_auditTrailService?.activityType"
        [(ngModel)]="_filterService.selectedActivityType"
        optionLabel="value"
        optionValue="value"
        [filter]="true"
        ></p-dropdown>
<!-- <p-multiSelect
       [options]="_auditTrailService?.activityType"
       [(ngModel)]="_filterService.selectedActivityType"
       optionLabel="title"
       display="chip"
       appendTo="body"
       [style]="{ width: '100%', 'min-width': '120px' }"
   ></p-multiSelect> -->
   <label for="multiselect">Activity Type</label>
</div>
<!--  /Activity Type -->
<!-- Opertion Type -->
<div

   class="p-float-label hide-1024"
   *ngIf="showOperationType"
>
<p-dropdown
[style]="{ height: '46px', width: '100%', 'min-width': '120px' }"
class="p-dropdown-1"
appendTo="body"
[options]="_auditTrailService?.operationType"
[(ngModel)]="_filterService.selectedOperationType"
optionLabel="name"
optionValue="value"
[filter]="true"
></p-dropdown>
<!-- <p-multiSelect
       [options]="_auditTrailService?.operationType"
       [(ngModel)]="_filterService.selectedOperationType"
       optionLabel="title"
       display="chip"
       appendTo="body"
       [style]="{ width: '100%', 'min-width': '120px' }"
   ></p-multiSelect> -->
   <label for="multiselect" >Opertion Type</label>
</div>
<!--  /Opertion Type -->
            <!-- <form
                [formGroup]="editform"
                (ngSubmit)="callbackFunc('outer-filter')"
                autocomplete="off"
            > -->
            <!-- formControlName="searchText" -->
            <div
                class="p-float-label hide-1024"
                *ngIf="showProductSearchTextInput"
            >
                <input
                    type="text"
                    id="ProductSearchTextInput"
                    [(ngModel)]="_filterService.ProductSearchTextInput"
                    pInputText
                    [style]="{
                        'min-width': '200px',
                        'font-size': '85.rem'
                    }"
                    (ngModelChange)="onSearchTextChanged()"
                />
                <label for="ProductSearchTextInput">{{
                    searchTextLabel
                }}</label>
            </div>
            <div class="p-float-label hide-1024" *ngIf="showSearchText">
                <input
                    type="text"
                    id="bob"
                    [(ngModel)]="_filterService.searchText"
                    pInputText
                    [style]="{
                        'min-width': '200px',
                        'font-size': '85.rem'
                    }"
                    (ngModelChange)="onSearchTextChanged()"
                />
                <label for="searchtext">{{ searchTextLabel }}</label>
            </div>
            <!-- </form> -->
            <div
                class="p-float-label hide-1024"
                *ngIf="showProductTypeDropDown"
            >
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    [options]="_abd.productSellTypeFilter"
                    [(ngModel)]="_filterService.selectedProductSellTypeFilter"
                    optionLabel="Type"
                    [showClear]="false"
                    placeholder=" "
                ></p-dropdown>
                <label for="multiselect">Sell Method</label>
            </div>
            <div
                class="p-float-label hide-1024"
                *ngIf="showSalesChannelDropdown"
            >
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '150px'
                    }"
                    class="p-dropdown-1"
                    [options]="salesChannelList"
                    [(ngModel)]="_filterService.selectedSaleChannel"
                    optionLabel="fullName"
                    [showClear]="true"
                    placeholder=" "
                    appendTo="body"
                  ></p-dropdown>
                <label for="multiselect">Sales Channel</label>
            </div>
          
            <div
                class="p-float-label hide-1024"
                *ngIf="showCouponCodeDiscountTypes"
            >
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '150px'
                    }"
                    class="p-dropdown-1"
                    [options]="couponDiscountTypeList"
                    [(ngModel)]="_filterService.selectedCouponCodeDiscountType"
                    optionLabel="viewValue"
                    [showClear]="true"
                    placeholder=" "
                ></p-dropdown>
                <label for="multiselect">Discount Type</label>
            </div>

            <div
                class="p-float-label hide-1024"
                *ngIf="
                    showLocationsMultiselect &&
                    _abd.initActLocations()?.length > 1
                "
            >
                <p-multiSelect
                    [options]="baseFilterOptions.locations"
                    [(ngModel)]="_filterService.selectedLocation"
                    optionLabel="locationName"
                    display="chip"
                    appendTo="body"
                    [style]="{
                        height: '46px',
                        'min-width': '200px',
                        'max-width': '250px',
                        'font-size': '1rem'
                    }"
                ></p-multiSelect>
                <label for="multiselect">Locations</label>
            </div>

            <div
                class="p-float-label hide-1024"
                *ngIf="
                    showLocationsDropdown && _abd.initActLocations()?.length > 1
                "
            >
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '200px'
                    }"
                    class="p-dropdown-1"
                    [options]="baseFilterOptions.locations"
                    [(ngModel)]="_filterService.selectedLocation"
                    optionLabel="locationName"
                    [showClear]="canClearShowlocationsDropdown"
                    placeholder=" "
                    [disabled]="disableAllControls"
                ></p-dropdown>
                <label for="multiselect">{{ locationsDropdownMessage }}</label>
            </div>

            <div class="p-float-label" *ngIf="showCatsDropdown">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="_abd.categories"
                    [(ngModel)]="_filterService.selectedCategory"
                    optionLabel="categoryName"
                    [showClear]="true"
                    placeholder=" "
                ></p-dropdown>
                <label for="multiselect">Categories</label>
            </div>

            <div class="p-float-label hide-1024" *ngIf="showDepartmentDropdown">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="_abd.actDepartments"
                    [(ngModel)]="_filterService.selectedDepartment"
                    optionLabel="departmentName"
                    [showClear]="true"
                    placeholder=" "
                ></p-dropdown>
                <label for="multiselect">Departments</label>
            </div>

            <div class="p-float-label hide-1024" *ngIf="showGroupByDropdown">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="_abd.groupby$ | async"
                    [(ngModel)]="_filterService.selectedGroup"
                    optionLabel="title"
                    [showClear]="true"
                    placeholder=" "
                >
                </p-dropdown>
                <label for="multiselect">Group By</label>
            </div>

            <div class="p-float-label hide-1024" *ngIf="showSelectedInvAdj">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="_abd.inventoryAdjustmentTypes"
                    [(ngModel)]="_filterService.selectedInventoryAdjustment"
                    optionLabel="title"
                    [showClear]="true"
                    placeholder=" "
                >
                </p-dropdown>
                <label for="multiselect23">Adjustment Type</label>
            </div>

            <div [style]="{ 'min-width': '100%' }" *ngIf="showDateRange">
                <div class="p-inputgroup">
                    <div class="p-float-label">
                        <p-calendar
                            [(ngModel)]="_filterService.dateRange"
                            selectionMode="range"
                            [readonlyInput]="false"
                            inputId="multiple"
                            class="filter"
                            baseZIndex="0"
                            appendTo="body"
                            [style]="{ 'min-width': '100%' }"
                        ></p-calendar>
                        <label for="multiselect">Date Range</label>
                    </div>
                    <button
                        (click)="opd.toggle($event)"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        styleClass="p-button-warn"
                    ></button>
                </div>
            </div>
            <div class="p-float-label hide-1024" *ngIf="showTimeZone">
                <p-dropdown
                    [style]="{
                        height: '46px',
                        width: '100%',
                        'min-width': '120px',
                        'max-width': '160px'
                    }"
                    class="p-dropdown-1"
                    appendTo="body"
                    [options]="timeZoneList"
                    [(ngModel)]="_filterService.selectedTimeZone"
                    optionLabel="label"
                    [filter]="true"
                    placeholder=" "
                >
                </p-dropdown>
                <label for="multiselect23">Time Zone</label>
            </div>
            <div class="p-float-label hide-1024" *ngIf="showSingleDate">
                <p-calendar
                    [(ngModel)]="_filterService.singleDate"
                    selectionMode="single"
                    [readonlyInput]="false"
                    inputId="singledate"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                    [style]="{ 'min-width': '100%' }"
                    [disabled]="disableAllControls"
                >
                </p-calendar>
                <label for="multiselect">Date</label>
            </div>

            <div
                class="p-float-label hide-1024"
                *ngIf="showSingleDateForReconcile"
            >
                <p-calendar
                    [(ngModel)]="_filterService.singleDateForReconcile"
                    selectionMode="single"
                    [readonlyInput]="false"
                    inputId="singledate"
                    class="filter"
                    baseZIndex="0"
                    appendTo="body"
                    [style]="{ 'min-width': '100%' }"
                    [disabled]="disableAllControls"
                >
                </p-calendar>
                <label for="multiselect">Date</label>
            </div>

            <button
                *ngIf="showSearchButton"
                [style]="{ 'margin-left': '-7px' }"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-text"
                (click)="callbackFunc('outer-filter')"
                [disabled]="disableAllControls"
            ></button>
            <!-- <button
                *ngIf="showSearchButton"
                [style]="{ 'margin-left': '-7px' }"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-text hide-1024"
                (click)="callbackFunc('outer-filter')"
                [disabled]="disableAllControls"
            ></button> -->

            <div>
                <button
                    *ngIf="showExportOption"
                    style="color: var(--brandColor)"
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-download"
                    pTooltip="Export options"
                    tooltipPosition="left"
                    class="p-button-rounded p-button-text p-button-info"
                    (click)="op.toggle($event)"
                    [disabled]="disableAllControls"
                ></button>

                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-filter"
                    class="p-button-rounded p-button-text"
                    tooltipPosition="left"
                    pTooltip="Click for filter options"
                    style="margin-left: 20px"
                    (click)="displayFilterPanel = true"
                    *ngIf="showDisplayFilterPanel"
                ></button>
            </div>
        </div>
    </div>
</div>

<div class="timeselector">
    <p-overlayPanel #opd>
        <p-menu
            #cm
            [model]="items"
            (click)="opd.hide()"
            [style]="{ padding: '0px' }"
        ></p-menu>
    </p-overlayPanel>
</div>

<p-divider
    *ngIf="showAddNew"
    align="left"
    type="dashed"
    [style]="{ 'padding-left': '1rem', 'margin-top': '.02rem' }"
>
    <div class="inline-flex align-items-center">
        <ng-template [ngxPermissionsOnly]="permissions"> 
        <button
            pTooltip="Add new"
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-warning p-button-text add-btn"
            (click)="addNewClick()"
        ></button>
        <b
            style="margin-left: 5px; color: var(--action-button)"
            class="add-btn"
            (click)="addNewClick()"
            >{{ showAddNewMessage }}</b
        >
</ng-template>
    &nbsp;&nbsp;&nbsp;
        <button
        *ngIf="showAddNewCustomProductLabel"
            pTooltip="Add new"
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-warning p-button-text add-btn"
            (click)="secondAddNewClick('custom-barcode-label')"
        ></button>
        <b
        *ngIf="showAddNewCustomProductLabel"
            style="margin-left: 5px; color: var(--action-button)"
            class="add-btn"
            (click)="secondAddNewClick()"
            >{{ showAddNewCustomProductLabelMessage }}</b
        >
    &nbsp;&nbsp;&nbsp;
        <button
        *ngIf="showReloadProductLabel"
            pTooltip="Reload"
            pButton
            pRipple
            type="button"
            icon="pi pi-undo"
            class="p-button-rounded p-button-warning p-button-text add-btn"
            (click)="reloadProductLabel()"
        ></button>
        <b
        *ngIf="showReloadProductLabel"
            style="margin-left: 5px; color: var(--action-button)"
            class="add-btn"
            (click)="reloadProductLabel()"
            >{{ showReloadProductLabelMessage }}</b
        >

        <button
            *ngIf="showImport"
            style="margin-left: 20px; color: var(--brandColor)"
            pButton
            pRipple
            pTooltip="Import"
            type="button"
            icon="pi pi-cloud-upload"
            class="p-button-rounded p-button-info p-button-text add-btn"
            (click)="importClick()"
        ></button>
        <b
            *ngIf="showImport"
            class="add-btn"
            (click)="importClick()"
            style="margin-left: 5px; color: var(--action-button)"
            >Import</b
        >

        <ng-container *ngIf="showDirectReceive">
            <button
                style="margin-left: 20px; color: var(--brandColor)"
                pButton
                pRipple
                pTooltip="Direct Receive"
                type="button"
                icon="pi pi-check-circle"
                class="p-button-rounded p-button-info p-button-text add-btn"
                (click)="newDirectPO()"
            ></button>
            <b
                class="add-btn"
                (click)="newDirectPO()"
                style="margin-left: 5px; color: var(--action-button)"
                >Direct Receive</b
            >
        </ng-container>
<!-- Auto PO -->
        <ng-container *ngIf="showAddAutoPOBtn">
            <button
                style="margin-left: 20px; color: var(--brandColor)"
                pButton
                pRipple
                pTooltip="Auto PO"
                type="button"
                icon="pi pi-plus"
                class="p-button-rounded p-button-info p-button-text add-btn"
                (click)="openAutoPO()"
            ></button>
            <b
                class="add-btn"
                (click)="openAutoPO()"
                style="margin-left: 5px; color: var(--action-button)"
                >Auto PO</b
            >
        </ng-container>
        <!-- Auto PO -->

        <ng-container *ngIf="_globals.showProductDelButton">
            <button
                style="margin-left: 20px"
                pButton
                pRipple
                pTooltip="Delete selected"
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="deleteItem()"
            ></button>
            <b
                class="add-btn"
                style="margin-left: 5px; color: var(--action-button)"
                (click)="deleteItem()"
                >Delete</b
            >
        </ng-container>
        <ng-container *ngIf="_barcodeLabelServices.isLabelPrintBtn">
            <button
                style="margin-left: 20px"
                pButton
                pRipple
                pTooltip="Label Print"
                type="button"
                icon="pi pi-print"
                class="p-button-rounded p-button-text"
                (click)="printLabel()"
            ></button>
            <b
                class="add-btn"
                style="margin-left: 5px; color: var(--action-button)"
                (click)="printLabel()"
                >Print Label</b
            >
        </ng-container>
    </div>
</p-divider>

<p-divider
    *ngIf="!showAddNew"
    align="left"
    type="dashed"
    [style]="{ 'padding-left': '1rem', 'margin-top': '1.25rem' }"
>
</p-divider>

<p-dialog
    header="New Location"
    [(visible)]="_locationService.isNewLocation"
    [style]="{ width: '55%' }"
    modal="true"
>
    <app-location-new-edit [isNew]="true"></app-location-new-edit>
</p-dialog>



<!-- <p-dialog
    *ngIf="_departmentService?.showNewNewEdit"
    header="New Department"
    [(visible)]="_departmentService.showNewNewEdit"
    [style]="{ width: '400px' }"
    modal="true"
>
    <app-new-edit-department [isNew]="true"></app-new-edit-department>
</p-dialog> -->

<!-- <p-dialog
    header="New Category"
    [(visible)]="_categoryService.showNewNewEdit"
    [style]="{ width: '400px' }"
    modal="true"
>
    <app-new-edit-category [isNew]="true"></app-new-edit-category>
</p-dialog> -->

<!-- <p-dialog
    *ngIf="_vendorService?.showNewNewEdit"
    header="New Vendors"
    [(visible)]="_vendorService.showNewNewEdit"
    [style]="{ width: '60%' }"
    modal="true"
>
    <app-new-edit-vendor [isNew]="true"></app-new-edit-vendor>
</p-dialog> -->

<!-- <p-dialog
    header="New Manufacturer"
    [(visible)]="_manufacturerService.showNewNewEdit"
    [style]="{ width: '400px' }"
    modal="true"
>
    <app-new-edit-manufacturer [isNew]="true"></app-new-edit-manufacturer>
</p-dialog> -->

<!-- <ng-container *ngIf="_locationService.isNewLocation">
    <p-dialog
        header="New Location"
        [(visible)]="_locationService.isNewLocation"
        [style]="{ width: '55%' }"
        modal="true"
    >
        <app-location-new-edit [isNew]="true"></app-location-new-edit>
    </p-dialog>
</ng-container> -->
