import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { VendorsService } from 'src/app/_services/vendors.service';
import { UsStates } from 'src/app/_data/us-states'
import { Vendor } from 'src/app/_models/vendor';
import { formatDate } from '@angular/common';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-new-edit-vendor',
  templateUrl: './new-edit-vendor.component.html',
  styleUrls: ['./new-edit-vendor.component.scss']
})
export class NewEditVendorComponent implements OnInit {
  whatAction = ''
  privateSelected: any = null
  selectedState: any = null
  usaStates = UsStates
  name: any = ''
  editform: FormGroup
  validationErrors: string[] = []

  showCityWait = false

  @Input() isNew: boolean = false
  @Input() showActionButtons: boolean = true
  @Output() refreshFn = new EventEmitter<any>()
  _globals = GlobalService

  constructor(
    public _abd: AppBaseDataService,
    private _fb: FormBuilder,
    public _vendorService: VendorsService,
    private _productService: ProductsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
  ) { }

  ngOnInit(): void {
    this._vendorService.activeItem

    this.selectedState = this.usaStates[0]
    // this.initForm()
    this.initForm()

    if (this.isNew || !this._vendorService.isViewOnly) {
      this.whatAction = 'Save'
      if (this.isNew) {
        this._vendorService.activeItem = this.initModelNew()
        this.initForm()
        this.initFormEdit()
      }
    } else {
      this.whatAction = 'Edit'
      this.initFormEdit()
    }

    // if (!this.isNew) this.initFormEdit()
    // else {
    //   this._vendorService.activeItem = this.initModelNew()
    //   this.initForm()
    //   this.initFormEdit()
    // }
  }

  ngAfterContentInit() {
    // if (!this.isNew) this.initFormEdit()
  }

  initModelNew(): Vendor {
    const model = {
      id: null,
      vendorName: '',
      vendorId: null,
      account: null,
      company: null,
      street: null,
      city: '',
      state: null,
      zip: null,
      phone: null,
      fax: null,
      createdDate: null,
      isActive: null,
      lastEdited: null,
      addedBy: null,
      partitionKey: null,
      stateName: null,
      vendorWebsite: null,
      vendorReport: null,
      email: null,
      terms: null,
      selected: null,
    }
    return model
  }

  initFormEdit() {
    if (this.isNew) {
      this._vendorService.selectedState = this.usaStates[0]
      return
    }
    //console.log('this._vendorService.activeItem', this._vendorService.activeItem);
    this.editform.patchValue({ phone: this._vendorService.activeItem.phone })
    const st = this.usaStates.filter(s => s.abbreviation == this._vendorService.activeItem.state)
    this._vendorService.selectedState = st[0];
  }

  phoneValidator(): ValidatorFn {
    let value = ''
    return (control: AbstractControl): ValidationErrors | null => {
      try {
        value = control.value.replace(/\D/g, '')
      } catch { }
      return value.length == 10 ? null : { phoneValError: true }
    }
  }

  initForm() {
    let obj = this._vendorService.activeItem

    this.editform = this._fb.group({
      vendorName: [{ value: this._vendorService.activeItem?.vendorName, disabled: this._vendorService.isViewOnly }, Validators.required],
      account: [{ value: this._vendorService.activeItem?.account, disabled: this._vendorService.isViewOnly }],
      company: [{ value: this._vendorService.activeItem?.company, disabled: this._vendorService.isViewOnly }],
      vendorWebsite: [{ value: this._vendorService.activeItem?.vendorWebsite, disabled: this._vendorService.isViewOnly }],
      email: [{ value: this._vendorService.activeItem?.email, disabled: this._vendorService.isViewOnly }, Validators.email],
      phone: [{ value: this._vendorService.activeItem?.phone, disabled: this._vendorService.isViewOnly }, [this._abd.phoneValidator(),Validators.required]],
      street: [{ value: this._vendorService.activeItem?.street, disabled: this._vendorService.isViewOnly }],
      zip: [{ value: this._vendorService.activeItem?.zip, disabled: this._vendorService.isViewOnly }, Validators.maxLength(5),],
      city: [{ value: this._vendorService.activeItem?.city, disabled: this._vendorService.isViewOnly }],
      terms: [{ value: this._vendorService.activeItem?.terms, disabled: this._vendorService.isViewOnly },Validators.maxLength(500)],
    })
  }

  get terms() {
    return this.editform.get('terms');
  }
  validateZipcode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      return !this._globals.validateZipCode(value) ? { invalidzipcode: true } : null;
    }
  }

  zipcodeInputEvent(value: string) {
    if (!value || value.length !== 5) return
    this.showCityWait = true
    this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
      this.showCityWait = false
      if (res.success && res.data.city != undefined) {
        this._vendorService.activeItem.city = res.data.city + '';
        const st = this.usaStates.filter(s => s.abbreviation == res.data.state)
        this._vendorService.selectedState = st[0];
        this.editform.patchValue({ city: res.data.city })
        // //console.log('city-data', this._vendorService.activeItem)
      } else {
        this._vendorService.activeItem.city = "Invalid zip code"
        this.editform.patchValue({ city: "Invalid zip code" })
        this._vendorService.selectedState = this.usaStates[0]
      }
    })
  }

  onSelectItem(e) {
    this.isNew = false
    this.privateSelected = JSON.parse(JSON.stringify(e))
    this.editform.patchValue({ vendorName: e.vendorName })
  }

  isFormValid() {
    try {
      if (!this.editform.get('vendorName').value) return false
      if (this.editform.get('phone').value.replace(/\D/g, '').length < 10) return false

    } catch { return false }
    console.log("this.editform.get('vendorName').value", this.editform.get('vendorName').value)
    return true
  }

  saveFn() {
    if (this.whatAction == 'Edit') {
      this._vendorService.isViewOnly = false
      this.whatAction = 'Save'
      return
    }

    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    const today = new Date()
    let utc: any = new Date(today.toUTCString().slice(0, -4))
    utc = formatDate(new Date(utc), 'MM/dd/yyyy', 'en-US')

    let phone = this.editform.get('phone').value.replace(/\D/g, '')
    // if(phone.length !== 10) this.editform.controls['phone'].setErrors({'incorrect':true})
    // else this.editform.controls['phone'].setErrors(null)

    const obj = {
      vendorName: this.editform.get('vendorName').value, //this._vendorService.activeItem.vendorName.trim(),
      isActive: true,
      id: null,

      account: this.editform.get('account').value, //this._vendorService.activeItem.account,
      company: this.editform.get('company').value, //this._vendorService.activeItem.company,
      vendorWebsite: this.editform.get('vendorWebsite').value, //this._vendorService.activeItem.vendorWebsite,
      phone: phone, //this._vendorService.activeItem.phone,

      street: this.editform.get('street').value, //this._vendorService.activeItem.street,
      city: this.editform.get('city').value, //this._vendorService.activeItem.city,
      state: this.selectedState.abbreviation,
      zip: this.editform.get('zip').value, //this._vendorService.activeItem.zip,
      email: this.editform.get('email').value, //this._vendorService.activeItem.email,
      terms: this.editform.get('terms').value, //this._vendorService.activeItem.terms,

      createdDate: utc,
      isACtive: true
    }
    if (!this.isNew) {
      obj.id = this._vendorService.activeItem.id
    }

    this._spinner.show()
    this._vendorService.createUpdateVendor(obj, this.isNew).subscribe(res => {
      if (res.success) {
        this._connMessageService.showToastMessage(`Vendor saved successfully!`, 'success')
        this.cancelButtonClick()
        // this._abd.getAllVendors()
        this.refreshFn.emit(true)
        this._spinner.hide()
      } else { this._spinner.hide() }
    })
  }

  cancelButtonClick() {
    this._vendorService.showNewEdit = false
    this._vendorService.showNewNewEdit = false
    this._productService.isNewVendor = false
    this._vendorService.isViewOnly = false
  }


}
