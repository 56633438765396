<!-- Account Info -->
<p-divider></p-divider>
<ng-container *ngIf="_bizzCustomerService?.isCustomerBusinessDetailLoading">
        <i class="pi pi-spin pi-spinner  flex justify-content-center align-items-center" style="color: var(--brandColor);font-size: xx-large;" ></i>     
</ng-container>
<ng-container *ngIf="!_bizzCustomerService?.isCustomerBusinessDetailLoading">
   <br />
<div class="nas-grid">
    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Last Login
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.lastLogin | date:'dd MMM, yyyy' }}
    </div>
    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Locations created
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.locationsCount }}
    </div>
</div>
<br />
<div class="nas-grid">
    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Loyalty Program
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.isLoyaltyProgram === true ? 'Active' : 'Inactive'  }}
    </div>
    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Users created
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{_bizzCustomerService?.currentBusinessDetailInfo?.usersCount}}
    </div>
</div>
<br />
<div class="nas-grid">

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Time & Attendance
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.timeAndAttentance === true ? 'Active' : 'Inactive' }}
    </div>

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Registers created
    </div>
    <div class="col" style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.registersCount }}
    </div>
</div>
<br />
<div class="nas-grid">

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Till Management
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.tillManagement === true ? 'Active' : 'Inactive' }}
    </div>

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Customers added
    </div>
    <div class="col" style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.customerCount }}
    </div>
</div>
<br />
<div class="nas-grid">

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Secondary Screen
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.secondaryScreen === true ? 'Active' : 'Inactive' }}
    </div>

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Products added
    </div>
    <div class="col" style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.productsCount }}
    </div>
</div>
<br />
<div class="nas-grid">

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Label Printing
    </div>
    <div class="col" style="
            background-color: var(--bluegray-50);
            border-radius: 5px;
            padding: 10px;
        ">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.labelPrinting === true ? 'Active' : 'Inactive' }}

    </div>

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        No. of Loyalty Invoices created
    </div>
    <div class="col" style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.registersCount }}
    </div>
</div>
<br />
<div class="nas-grid">

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
    </div>
    <div class="col" style="
            border-radius: 5px;
            padding: 10px;
        ">
    </div>

    <div style="color: var(--bluegray-300); font-weight: 600px; margin-top: 10px">
        Total Invoices
    </div>
    <div class="col" style="
    background-color: var(--bluegray-50);
    border-radius: 5px;
    padding: 10px;
">
        {{ _bizzCustomerService?.currentBusinessDetailInfo?.invoicesCount }}
    </div>
</div>
<br /> 
</ng-container>
