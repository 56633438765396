import { DataViewModule } from 'primeng/dataview';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UsStates } from 'src/app/_data/us-states';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { UserService } from 'src/app/_services/user.service';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { GlobalService } from 'src/app/_services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { Router } from '@angular/router';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-new-edit-user',
  templateUrl: './new-edit-user.component.html',
  styleUrls: ['./new-edit-user.component.scss']
})
export class NewEditUserComponent implements OnInit, AfterViewInit {
  editform: FormGroup
  // showCityWait = false
  usaStates = UsStates
  treeData: any = []

  localRights: any = []
  posRights1: TreeNode[]
  posRights2: TreeNode[]

  backOfficeRights1: TreeNode[]
  backOfficeRights2: TreeNode[]

  selectedPosRights1: TreeNode[]
  selectedPosRights2: TreeNode[]
 
  selectedBackOfficeRights1: TreeNode[]
  selectedBackOfficeRights2: TreeNode[]

  localRoles: any = []
  locations: any = []
  selectedLocations: any = []

  treeRights: TreeNode[]
  selectedRights: any = []

  posRights: TreeNode[]
 isSupportUser = true;
  backOfficeRights: TreeNode[]

  selectButtonLabel = 'Select All'
  posSelectButtonLabel = 'Select All'
  backOfficeSelectButtonLabel = 'Select All'

  emailValidatorString = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  passwordValidatorString = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
  displayPasswordError = false
  // dummy: any = ''
  // files1: TreeNode[];

  _globals = GlobalService
  @Input() isNew: boolean
  @Output() onSubmit = new EventEmitter()
  selectRightOfRole: any = [];

  @HostListener('window:popstate', ['$event']) onClickBack(
    event: PopStateEvent
  ) {
    history.pushState(null, document.title, location.href);
    this.cancelButtonClick()
  }

  constructor(
    private _fb: FormBuilder,
    public _userService: UserService,
    public _abd: AppBaseDataService,
    public _locationService: LocationsService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _router: Router,
    public _businessSetupService: BusinessSetupService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _cd:ChangeDetectorRef,
      @Inject(DOCUMENT) document: Document
  ) { }

    ngAfterViewInit(): void {
        if (this._userService.isNew){
            document.querySelectorAll('input[type=password]')[0].setAttribute("autocomplete", "new-password");
        }

    }


  ngOnInit(): void {
    this.locations = this._abd.initActLocations()
    let x = this._userService.activeItem
    // console.log('active-user', x)

    // this.initForm()
    try {
      this.getPosBackOfficeRights()
      this.makeRights()
      this.makeRoles()
      //this.localRoles = JSON.parse(JSON.stringify(this._abd.roles))
      this.makeRightsGroupRoles()
      this.expandAllNodes()
      this.initForm()
      // this.editform.reset();
      this.isNewUser()
      this.initForm()
      this.getUserSelectedLocations()

    } catch {
      this.getUserSelectedLocations()
    }

    if (this._userService.isViewOnly || this.isBusinessManager()) {
      this.enableAll(false)
    }else{
      this.enableAll(true)
    }
  }

  isBusinessManager() {
    let r = this.localRoles
    for (let i = 0; i < r.length; i++)
      if (r[i].roleId == 1001 && r[i].selected)
        return true

    return false
  }

  getPosBackOfficeRights() {
    try {
      this._spinner.show()
      forkJoin([this._userService.getPosRights(),this._userService.getBackOfficeRights()])
      .subscribe(([res1,res2]) => {
        if (res1.success && res2.success) {
          this.makePosRights(res1.data.right)
          this.makeBackOfficeRights(res2.data.right)
          // const backOfficeRights = res2.data.right
          // console.log(backOfficeRights)
        }
        this._spinner.hide()
      })

    }
    catch { this._spinner.hide() }
  }

  makePosRights(data) {
    let objs = []
    for (let i = 0; i < data.length; i++) {
      let ch = data[i].childs
      for (let j = 0; j < ch.length; j++) {
        ch[j].label = ch[j].name
        //ch[j].rightsId = ch[j].rightsId
      }
      const obj = {
        "label": data[i].name,
        "rightId": data[i].rightId,
        "children": ch
      }
      objs.push(obj)
    }
    this.posRights = objs

    this.posRights1 = []
    this.posRights2 = []

    const obj = JSON.parse(JSON.stringify(this.posRights))
    obj.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    const mid = Math.ceil(obj.length / 2)

    this.posRights1 = obj.slice().splice(0, mid)
    this.posRights2 = obj.slice().splice(-mid)

    this.posRights1.forEach(node => {
      this.expandRecursive(node, true);
    });

    this.posRights2.forEach(node => {
      this.expandRecursive(node, true);
    });

    if (!this._userService.isNew) this.setPosRights()
    else {
      this.posSelectButtonLabel == 'Select All'
      this.posSelectAllButtonClick()
    }
  }
  makeBackOfficeRights(data) {
    let objs = []
    for (let i = 0; i < data.length; i++) {
      let ch = data[i].childs
      for (let j = 0; j < ch.length; j++) {
        ch[j].label = ch[j].name
        //ch[j].rightsId = ch[j].rightsId
      }
      const obj = {
        "label": data[i].name,
        "rightId": data[i].rightId,
        "children": ch
      }
      objs.push(obj)
    }
    this.backOfficeRights = objs

    this.backOfficeRights1 = []
    this.backOfficeRights2 = []

    const obj = JSON.parse(JSON.stringify(this.backOfficeRights))
    obj.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    const mid = Math.ceil(obj.length / 2)

    this.backOfficeRights1 = obj.slice().splice(0, mid)
    this.backOfficeRights2 = obj.slice().splice(-mid)

    this.backOfficeRights1.forEach(node => {
      this.expandRecursive(node, true);
    });

    this.backOfficeRights2.forEach(node => {
      this.expandRecursive(node, true);
    });

    if (!this._userService.isNew) this.setbackOfficeRights()
    else {
      this.posSelectButtonLabel == 'Select All'
      this.backOfficeSelectAllButtonClick()
    }
  }

  setPosRights(isSelect?:boolean) {
    let urs = this._userService.activeItem.userRights
    if(isSelect === true){
      urs = this.selectRightOfRole
    }
    // if(this._userService.activeItem.userRights === undefined && this.selectRightOfRole.length > 0 && isSelect === true){
    //   urs = this.selectRightOfRole
    // }
    this.selectedPosRights1 = []
    this.selectedPosRights2 = []

    let sr = []
    if(urs?.length > 0){
    let roles = this.posRights1
   
    for (let x = 0; x < urs.length; x++) {
      for (let i = 0; i < roles.length; i++) {
        let xx1 = (<any>roles[i]).rightId
        if (xx1 == urs[x])
          this.selectedPosRights1.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          let xx = (<any>nodes[k]).rightId
          if (xx == urs[x])
            this.selectedPosRights1.push(nodes[k])
        }
      }
    }

    roles = this.posRights2
    for (let x = 0; x < urs.length; x++) {
      for (let i = 0; i < roles.length; i++) {
        let xx1 = (<any>roles[i]).rightId
        if (xx1 == urs[x])
          this.selectedPosRights2.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          let xx = (<any>nodes[k]).rightId
          if (xx == urs[x])
            this.selectedPosRights2.push(nodes[k])
        }
      }
    }
  }
  if (this._userService.isViewOnly || this.isBusinessManager()) {
    this.enableAll(false)
  }else{
    this.enableAll(true)
  }
  }

  setbackOfficeRights(isSelect?:boolean) {
    let urs = this._userService.activeItem.userRights
    if(isSelect === true){
      urs = this.selectRightOfRole
    }
    // if(this._userService.activeItem.userRights === undefined && this.selectRightOfRole.length > 0){
    //   urs = this.selectRightOfRole
    // }
    this.selectedBackOfficeRights1 = []
    this.selectedBackOfficeRights2 = []

    let sr = []

    let roles = this.backOfficeRights1
    if(urs?.length > 0){  
    for (let x = 0; x < urs.length; x++) {
      for (let i = 0; i < roles.length; i++) {
        let xx1 = (<any>roles[i]).rightId
        if (xx1 == urs[x])
          this.selectedBackOfficeRights1.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          let xx = (<any>nodes[k]).rightId
          if (xx == urs[x])
            this.selectedBackOfficeRights1.push(nodes[k])
        }
      }
    }
 }
    roles = this.backOfficeRights2
    if(urs?.length > 0){
    for (let x = 0; x < urs.length; x++) {
      for (let i = 0; i < roles.length; i++) {
        let xx1 = (<any>roles[i]).rightId
        if (xx1 == urs[x])
          this.selectedBackOfficeRights2.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          let xx = (<any>nodes[k]).rightId
          if (xx == urs[x])
            this.selectedBackOfficeRights2.push(nodes[k])
        }
      }
    }}

    if (this._userService.isViewOnly || this.isBusinessManager()) {
      this.enableAll(false)
    }else{
      this.enableAll(true)
    }
  }

  posSelectAllButtonClick(mode = 'limited') {
    if(this._userService.isViewOnly ) return;
   
    let ids: any = []
    //this.selectedRights = []
    if (this.posSelectButtonLabel == 'Select All' || mode == 'force') {
      if (this.posSelectButtonLabel == 'Select All') this.posSelectButtonLabel = 'Deselect All'  
      this.posRights1.forEach(node => {
        ids.push((<any>(node)).rightId)
      });

      let sr = []
      let roles = this.posRights1
      for (let i = 0; i < roles.length; i++) {
        sr.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          sr.push(nodes[k])
        }
      }
      this.selectedPosRights1 = sr

      sr = []
      roles = this.posRights2
      for (let i = 0; i < roles.length; i++) {
        sr.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          sr.push(nodes[k])
        }
      }
      this.selectedPosRights2 = sr

    } else {
      this.selectedPosRights1 = []
      this.selectedPosRights2 = []
      this.posSelectButtonLabel = 'Select All'
    }
  }
 
  backOfficeSelectAllButtonClick(mode = 'limited') {
    if(this._userService.isViewOnly) return;
    let ids: any = []
    if (this.backOfficeSelectButtonLabel == 'Select All' || mode == 'force') {
      if (this.backOfficeSelectButtonLabel == 'Select All') this.backOfficeSelectButtonLabel = 'Deselect All'
      this.backOfficeRights1.forEach(node => {
        ids.push((<any>(node)).rightId)
      });

      let sr = []
      let roles = this.backOfficeRights1
      for (let i = 0; i < roles.length; i++) {
        sr.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          sr.push(nodes[k])
        }
      }
      this.selectedBackOfficeRights1 = sr

      sr = []
      roles = this.backOfficeRights2
      for (let i = 0; i < roles.length; i++) {
        sr.push(roles[i])
        let nodes = roles[i].children
        for (let k = 0; k < nodes.length; k++) {
          sr.push(nodes[k])
        }
      }
      this.selectedBackOfficeRights2 = sr

    } else {
      this.selectedBackOfficeRights1 = []
      this.selectedBackOfficeRights2 = []
      this.backOfficeSelectButtonLabel = 'Select All'
    }
  }
  onlySelectAllPOSBackOfficeRights(mode){
    if(mode === "select"){
      // this.posSelectAllButtonClick('force')
      let ids: any = []
      this.posRights1.forEach(node => {
        ids.push((<any>(node)).rightId)
      });
      
      this.backOfficeRights1.forEach(node => {
        ids.push((<any>(node)).rightId)
      });
   
   
   
   
      let srp = []
      let rolesp = this.posRights1
      for (let i = 0; i < rolesp.length; i++) {
        srp.push(rolesp[i])
        let nodes = rolesp[i].children
        for (let k = 0; k < nodes.length; k++) {
          srp.push(nodes[k])
        }
      }
      this.selectedPosRights1 = srp

      srp = []
      rolesp = this.posRights2
      for (let i = 0; i < rolesp.length; i++) {
        srp.push(rolesp[i])
        let nodes = rolesp[i].children
        for (let k = 0; k < nodes.length; k++) {
          srp.push(nodes[k])
        }
      }
      this.selectedPosRights2 = srp

      let srb = []
      let rolesb = this.backOfficeRights1
      for (let i = 0; i < rolesb.length; i++) {
        srb.push(rolesb[i])
        let nodes = rolesb[i].children
        for (let k = 0; k < nodes.length; k++) {
          srb.push(nodes[k])
        }
      }
      this.selectedBackOfficeRights1 = srb

      srb = []
      rolesb = this.backOfficeRights2
      for (let i = 0; i < rolesb.length; i++) {
        srb.push(rolesb[i])
        let nodes = rolesb[i].children
        for (let k = 0; k < nodes.length; k++) {
          srb.push(nodes[k])
        }
      }
      this.selectedBackOfficeRights2 = srb
      this.posSelectButtonLabel = 'Deselect All'
      this.backOfficeSelectButtonLabel = 'Deselect All'
         this._cd.detectChanges()
    }
    // else{
    //   this.selectedPosRights1 = []
    //   this.selectedPosRights2 = []
    //   this.posSelectButtonLabel = 'Select All'

    //   this.selectedBackOfficeRights1 = []
    //   this.selectedBackOfficeRights2 = []
    //   this.backOfficeSelectButtonLabel = 'Select All'
    // }
  }
nodeUnselect(event: any) {
  this.checkIfRelative(event)
    // this._messageService.add({ severity: 'info', summary: 'Node Unselected', detail: event.node.label });
}
checkIfRelative(event: any) {
  // Credit Card Sales/Summary
  if (event.node.rightId === 2052) {
    this.filterRightsArray(2053, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2053) {
    this.filterRightsArray(2052, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  // Reward Report/Summary/Discount 
  else if (event.node.rightId === 2048) {
    this.filterRightsArray(2049, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2049) {
    this.filterRightsArray(2048, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  // Daily / Hourly Sale Report
  else if (event.node.rightId === 2048) {
    this.filterRightsArray(2049, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2049) {
    this.filterRightsArray(2048, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  //  Cash Summary / Count
  else if (event.node.rightId === 2066) {
    this.filterRightsArray(2067, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2067) {
    this.filterRightsArray(2066, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  //  Transfer - / Details
  else if (event.node.rightId === 2071) {
    this.filterRightsArray(2072, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2072) {
    this.filterRightsArray(2071, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  //  Adjustment Summary / Details
  else if (event.node.rightId === 2073) {
    this.filterRightsArray(2074, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2074) {
    this.filterRightsArray(2073, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  //  Tip Summary / Details
  else if (event.node.rightId === 2082) {
    this.filterRightsArray(2083, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2083) {
    this.filterRightsArray(2082, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
  //  Time Tracking Summary / Details
  else if (event.node.rightId === 2085) {
    this.filterRightsArray(2086, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  } else if (event.node.rightId === 2086) {
    this.filterRightsArray(2085, this.selectedBackOfficeRights1, this.selectedBackOfficeRights2);
  }
}
  filterRightsArray(rightIdToFilter: number, targetArray1: any[], targetArray2: any[]) {
    if (targetArray1 !== undefined && targetArray1.length > 0) {
      this._confirmationService.confirm({
        message: `this is still Showing some where else`,
        header: 'Still Showing',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.selectedBackOfficeRights1 = targetArray1.filter((f: any) => f.rightId !== rightIdToFilter);
        }
      });
    } else if (targetArray2 !== undefined && targetArray2.length > 0) {
      if(targetArray2.find((x)=>x.rightId === rightIdToFilter)){
              this._confirmationService.confirm({
        message: `User have still access are you sure to disable all.`,
        header: 'User Rights',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.selectedBackOfficeRights2 = targetArray2.filter((f: any) => f.rightId !== rightIdToFilter);
        },
        // reject: () => { }
      });
      }

    }
  }
  expandAllNodes() {
    const temp = JSON.parse(JSON.stringify(this.treeRights))
    temp.forEach(node => {
      this.expandRecursive(node, true);
    });
    this.treeRights = JSON.parse(JSON.stringify(temp))
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  isNewUser() {
    if (this._userService.isNew) {
      this.blankActiveItem()
    } else {
      const state = this.usaStates.find(s => s.abbreviation == this._userService.activeItem.state)
      this._abd.zipcodeCityStateObj.city = this._userService.activeItem.city
      this._abd.zipcodeCityStateObj.zipcode = this._userService.activeItem.postalCode
      this._abd.zipcodeCityStateObj.stateObj = state

      this.selectedPosRights1 = []
      this.selectedPosRights2 = []

      this.selectedBackOfficeRights1 = []
      this.selectedBackOfficeRights2 = []

      const roles = this._userService.activeItem.role
      const rights = this._globals.userInfo.UserRights

      for (let i = 0; i < this.localRoles.length; i++) {
        this.localRoles[i].selected = false
      }

      for (let i = 0; i < roles.length; i++) {
        for (let j = 0; j < this.localRoles.length; j++) {
          if (roles[i] == this.localRoles[j].roleId) {
            this.localRoles[j].selected = true
          }
        }
      }
      this.rightsOnChange(rights)

      this.locations = this._abd.initActLocations()
      this.selectedLocations = []
      let uls = this._globals.userInfo.userLocations

      let locs = JSON.parse(JSON.stringify(this.locations))
      for (let i = 0; i < uls.length; i++) {
        for (let j = 0; j < this.locations.length; j++) {
          if (uls[i] == locs[j].locationId) {
            this.selectedLocations.push(locs[j])
            this.locations.splice(j, 1)
            break
          }
        }
      }


      //this.posSelectAllButtonClick()
      const userrights = this._userService.activeItem.userRights
      // for (let i = 0; i < userrights.length; i++) {
      //   let obj = this.posRights1
      //   for (let j = 0; j < obj.length; j++) {
      //     if (userrights[i] == obj[j].rightId) {
      //       this.selectedPosRights1.push(obj[j])
      //       break
      //     }
      //   }

      //   obj = this.posRights2
      //   for (let j = 0; j < obj.length; j++) {
      //     if (userrights[i] == obj[j].rightId) {
      //       this.selectedPosRights2.push(obj[j])
      //       break
      //     }
      //   }
      // }

      // if (this._userService.isViewOnly) {
      //   this.posRights1.forEach(node => {
      //     this.enableRecursive(node, false);
      //   });

      //   this.posRights2.forEach(node => {
      //     this.enableRecursive(node, false);
      //   });

      //   this.treeRights.forEach(node => {
      //     this.enableRecursive(node, false);
      //   });
      // }
    }

  }


  enableAll(mode: boolean) {
    try {
      this.treeRights.forEach(node => {
        this.enableRecursive(node, mode);
      });

      this.posRights1.forEach(node => {
        this.enableRecursive(node, mode);
      });

      this.posRights2.forEach(node => {
        this.enableRecursive(node, mode);
      });
 
      this.backOfficeRights1.forEach(node => {
        this.enableRecursive(node, mode);
      });

      this.backOfficeRights2.forEach(node => {
        this.enableRecursive(node, mode);
      });
    } catch { }
  }

  private enableRecursive(node: TreeNode, isSelectable: boolean) {
    node.selectable = isSelectable;
    if (node.children) {
      node.children.forEach(childNode => {
        this.enableRecursive(childNode, isSelectable);
      });
    }
  }

  initForm() {
    // this.editform.reset()
    let obj = this._userService.activeItem

    this.editform = this._fb.group({
      firstName: [{ value: this._userService.activeItem?.firstName, disabled: this._userService.isViewOnly }, Validators.required],
      lastName: [{ value: this._userService.activeItem?.lastName, disabled: this._userService.isViewOnly }, Validators.required],
      username: [{ value: this._userService.activeItem?.username, disabled: !this._userService.isNew }, Validators.required],
      domainName: [{ value: this._globals.userInfo.businessSetup[0].businessName, disabled: true }],
      email: [{ value: this._userService.activeItem?.email, disabled: this._userService.isViewOnly }, [Validators.required, this.emailFormatValidator()]],
      phone: [{ value: this._userService.activeItem?.phone, disabled: this._userService.isViewOnly }, this._abd.phoneValidator()],
      street: [{ value: this._userService.activeItem?.street, disabled: this._userService.isViewOnly }, Validators.required],
      // postalCode: [{ value: this._userService.activeItem?.postalCode, disabled: this._userService.isViewOnly }, Validators.required],
      // city: [{ value: this._userService.activeItem?.city, disabled: this._userService.isViewOnly }, Validators.required],
      // state: [{ value: this._userService.activeItem?.state, disabled: this._userService.isViewOnly }, Validators.required],
      overrideCode1: [{ value: this._userService.activeItem?.overrideCode, disabled: this._userService.isViewOnly }],
      overrideCode2: [{ value: this._userService.activeItem?.overrideCode2, disabled: this._userService.isViewOnly }],
      password: [
        '',
        [Validators.required, this.passwordStrengthValidator()],
      ],
      confirmPassword: [
        '',
        [Validators.required, this.confirmPasswordStrengthValidator()],
      ],
    })
  }

  blankActiveItem() {
    for (let i = 0; i < this.localRoles.length; i++) {
      this.localRoles[i].selected = false
    }

    // this.usaStates = UsStates
    this.locations = this._abd.initActLocations()
    this.selectedLocations = []

    this.localRoles[0].selected = true
    this.rightsOnChange()

    this._userService.activeItem = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      postalCode: '',
      state: '',
      photo: '',
      password: '',
      username: '',
      role: [],
      userID: '',
      overrideCode1: '',
      overrideCode2: ''
    }

    this.editform.setValue({
      firstName: '',
      lastName: '',
      username: '',
      domainName: '',
      email: '',
      phone: '',
      street: '',
      // postalCode: '',
      // city: '',
      // state: '',
      overrideCode1: '',
      overrideCode2: '',
      password: '',
      confirmPassword: '',
    })

  }

  makeRights() {
    this.localRights = JSON.parse(JSON.stringify(this._abd.rights.filter(f => f.active)))

    this.localRights = JSON.parse(JSON.stringify(this._abd.rights.filter(f => f.plateform != 1)))
    //this.posSelectAllButtonClick()
    // console.log('this.localRights', this.posRights1)
  }

  makeRoles() {
    let obj = JSON.parse(JSON.stringify(this._abd.roles.filter(f => f.active)))
     let businessManeger = "The Business Manager role have granted all the rights from Back Office and POSAPP by default. You cannot make any customization to it. If you want to make customization, please select the role of a Manager ."
     let admin = "The Admin role is granted all default rights from Back Office. You may customize these settings as required."
     let manager = "The Manager role is granted all default rights from Back Office and POSAPP, similar to the Business Manager. You may customize these settings as required."
     let cashier = "The Cashier role is granted all default rights from POSAPP. You may customize these settings as required."
    this.localRoles = obj.map((v)=>{
    let tooltip ;
    if(v.name === "Business Manager"){ tooltip = businessManeger}
    else if(v.name === "Admin"){ tooltip = admin}
    else if(v.name === "Manager"){ tooltip = manager}
    else if(v.name === "Cashier"){ tooltip = cashier}
      return{...v,toolTip:tooltip}
    })
    // console.log('this.localRoles', obj)
  }

  makeRightsGroupRoles() {
    const obj = JSON.parse(JSON.stringify(this._abd.rightsGroupRoles.filter(f => f.active)))

    this.treeData = []
    for (let i = 0; i < obj.length; i++) {
      const ch = obj[i].children.filter(f => f.active)
      obj.children = ch
    }

    this.treeData = obj
    this.treeRights = obj
    // console.log('after-this._abd.rightsGroupRoles', obj)
  }


  get f() {
    return this.editform.controls;
  }

  getSelectedRoles() {
    const r = this.localRoles
    let ids: any = []
    for (let i = 0; i < r.length; i++) {
      if (r[i].selected)
        ids.push(r[i].roleId)
    }
    return ids
  }

  getSelectedRights() {
    let ids: any = []
    let roles = this.selectedPosRights1
    this.selectedPosRights1.forEach(node => {
      const xx = <any>node
      ids.push(xx.rightId);
    });

    this.selectedPosRights2.forEach(node => {
      const xx = <any>node
      ids.push(xx.rightId);
    });

if(this.selectedBackOfficeRights1 !== undefined){
   this.selectedBackOfficeRights1.forEach(node => {
      const xx = <any>node
      ids.push(xx.rightId);
    });
}
 if(this.selectedBackOfficeRights2 !== undefined){
    this.selectedBackOfficeRights2.forEach(node => {
      const xx = <any>node
      ids.push(xx.rightId);
    });}


    // let sr=[]
    // let roles = this.posRights2
    //   for(let i=0; i<roles.length;i++) {
    //     sr.push(roles[i])
    //     let nodes = roles[i].children
    //     for (let k = 0; k < nodes.length; k++) {
    //       sr.push(nodes[k])
    //     }
    //   }

    // let ids: any = []
    // for (let i = 0; i < this.selectedRights.length; i++) {
    //   ids.push(this.selectedRights[i].rightId)
    // }

    // let obj = this.selectedPosRights1
    // for (let i = 0; i < obj.length; i++) {
    //   ids.push(obj[i].rightId)
    // }

    // obj = this.selectedPosRights2
    // for (let i = 0; i < obj.length; i++) {
    //   ids.push(obj[i].rightId)
    // }

    return ids
  }

  getSelectedLocations() {
    let ids: any = []
    for (let i = 0; i < this.selectedLocations.length; i++) {
      ids.push(this.selectedLocations[i].locationId)
    }
    return ids
  }

  selectAllButtonClick() {
    let ids: any = []
    this.selectedRights = []
    if (this.selectButtonLabel == 'Select All') {
      this.selectButtonLabel = 'Deselect All'
      for (let i = 0; i < this.localRights.length; i++)
        ids.push(this.localRights[i].rightId)
      this.rightsOnChange(ids)
    } else {
      this.selectButtonLabel = 'Select All'
    }
  }
  rightsOnChange(paraRights: any = '') {
    this.selectedRights = []
    let sr = []
    let roles = this.localRoles
    let list =roles.filter((x)=>x.selected === true)
    list = list.map((x)=>x.rightsId.toString())
    list = list.flatMap(str => str.split(','));
    list = Array.from(new Set(list))
    this.selectRightOfRole = list
    let nodes: any = []
    let rightsId: any = []
    if (paraRights == '') {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].selected) {
          const r = roles[i].rightsId
          for (let j = 0; j < r.length; j++)
            rightsId.push(r[j])
        }
      }
    } else {
      rightsId = paraRights
    }
    //console.log('rightsId', rightsId)

    roles = this.treeData
    for (let i = 0; i < roles.length; i++) {
      nodes = this.treeRights[i].children
      // //console.log('nodes', nodes)
      for (let j = 0; j < rightsId.length; j++) {
        for (let k = 0; k < nodes.length; k++) {
          if (nodes[k].rightId == rightsId[j])
            sr.push(nodes[k])
          try {
            const n = nodes[k].children
            if (n.length > 0) {
              for (let x = 0; x < n.length; x++) {
                if (n[x].rightId == rightsId[j])
                  sr.push(n[x])
              }
            }
          } catch { }
        }
      }
    }
    this.selectedRights = sr
    this.setPosRights(true)
    this.setbackOfficeRights(true)
    // if (this.isBusinessManager()) {
    //   this.posSelectAllButtonClick('force')
    //   this.enableAll(false)
    // } else {
    //   this.enableAll(true)
    // }
    // //console.log('this.selectedRights', this.selectedRights)
    return false
  }

  removeSpaceUserName() {
    let x = this.editform.get('username').value.replace(/\s/g, "")
    this.editform.patchValue({ username: x })
    this._userService.activeItem.username = x
  }

  saveFn() {
    const idro = this.getSelectedRoles()
    const idri = this.getSelectedRights()
    const loc = this.getSelectedLocations()

    if (this.editform.get('confirmPassword').value !== this.editform.get('password').value) {
      this._messageService.add({ severity: 'error', summary: 'Password', detail: 'Mismatch passwords!' })
      return
    }

    let password = ''
    if (this._userService.isNew) {
      password = this.editform.get('password').value
      if (!password.match(this.passwordValidatorString)) {
        // this.displayPasswordError = true
        return
      }
    }

    var userName: string = ""
    if (this.isNew == true || this._userService.isNew == true) {
      userName = this.f.username.value + '@' + this._globals.userInfo.companyName
    }
    else {
      userName = this.f.username.value;
    }
    const uid = this._userService.isNew ? '' : this._userService.activeItem.userID
    const obj = {
      "Name": (uid == "" ? "CREATEUSER" : "UPDATEUSER"),
      "User": {
        "UserID": uid,
        "FirstName": this.editform.get('firstName').value,
        "LastName": this.f.lastName.value,
        "Email": this.f.email.value,
        "Username": userName,
        "Password": password,
        "Phone": this.f.phone.value.replace(/\D/g, ''),
        "Role": idro,
        "Street": this.f.street.value,
        "City": this._abd.zipcodeCityStateObj.city,
        "state": this._abd.zipcodeCityStateObj.stateObj.abbreviation,
        "Country": "US",
        "PostalCode": this._abd.zipcodeCityStateObj.zipcode,
        "CompanyName": this._globals.userInfo.companyName,
        "Photo": '',
        "UserLocations": loc,
        "UserRights": idri,
        "overrideCode": this.f.overrideCode1.value,
        "overrideCode2": this.f.overrideCode2.value,
      }
    }

    try {
      //this._abd.showtreeRightsSpinner()
 
      this._spinner.show()
      this._userService.createStaffUser(JSON.stringify(obj)).subscribe(res => {
        if (res.success) {
          this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res => { })
          this._connMessageService.showToastMessage('User updated successfully.', 'success')
          this.blankActiveItem()
          this.onSubmit.emit(true)
          //this._abd.hideSpinner()
          this._spinner.hide()
          this.cancelButtonClick()
        }
      })
    } catch {
      //this._abd.hideSpinner()
      this._spinner.hide()
    }

  }

  emailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this.emailValidatorString)
      return pvalid ? null : { emailStrength: true };
    }
  }

  confirmPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      try {
        const value11 = this.editform.get('password').value
      } catch { return null }
      const value1 = this.editform.get('password').value
      if (!value) return null;

      return value === value1 ? null : { conpasswordStrength: true }
    }
  }

  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const pvalid = value.match(this.passwordValidatorString)
      return pvalid ? null : { passwordStrength: true };
    }
  }

  matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  // zipInputEvent(value: string) {
  //   if (!value || value.length !== 5) return
  //   this.showCityWait = true
  //   this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
  //     this.showCityWait = false
  //     if (res.success && res.data.city != undefined) {
  //       this._userService.activeItem.city = res.data.city + '';
  //       const st = this.usaStates.filter(s => s.abbreviation == res.data.state)
  //       this._userService.activeItem.selectedState = st[0];
  //       this.editform.patchValue({ city: res.data.city })
  //       // //console.log('city-data', this._vendorService.activeItem)
  //     } else {
  //       this._userService.activeItem.city = "Invalid zip code"
  //       this.editform.patchValue({ city: "Invalid zip code" })
  //       this._userService.activeItem.selectedState = this.usaStates[0]
  //     }
  //   })
  // }

  getUserSelectedLocations() {
    this.locations = this._abd.initActLocations()
    this.selectedLocations = []

    const loc = this._userService.activeItem.userLocations
  if(loc !== undefined){
    for (let i = 0; i < loc.length; i++) {
      for (let j = 0; j < this.locations.length; j++) {
        if (this.locations[j].id === loc[i]) {
          this.selectedLocations.push(this.locations[j])
          this.locations.splice(j, 1)
          break
        }
      }
    }
  } 
  }

  isFormValidFn() {
    let isvalid = true

    try {
      const idro = this.getSelectedRoles()
      if (idro.length < 1) isvalid = false

      const idri = this.getSelectedRights()
      if (idri.length < 1) isvalid = false

      const loc = this.getSelectedLocations()
      if (loc.length < 1) isvalid = false

      let password = ''
      if (this._userService.isNew) {
        password = this.editform.get('confirmPassword').value
        if (!password.match(this.passwordValidatorString)) isvalid = false
      }

      if (this.editform.get('confirmPassword').value !== this.editform.get('password').value) isvalid = false

      if (password !== this.editform.get('password').value) isvalid = false

      const email = this.editform.get('email').value
      if (!email.match(this.emailValidatorString)) isvalid = false

      if (this.editform.get('phone').value.replace(/\D/g, '').length !== 10) return false

    } catch {
      return false
    }
    return isvalid

  }

  cancelButtonClick() {
    //console.log(this.f.phone.value)
    this.blankActiveItem()
    this._userService.showNewEdit = false
    this._userService.isViewOnly = false
    this._businessSetupService.showNewEditUser = false;
    this._userService.isNew = false

    if (!this._router.url.includes('setup/business-setup'))
      this._router.navigate(['app/users-list'])
  }

  ngOnDestroy() {
    this.blankActiveItem()
  }




}
