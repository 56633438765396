import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Paginator } from 'primeng/paginator';
import { forkJoin, Observable } from 'rxjs';
import { AllowedChannels, AllowedDaysList, ApplyBy, CustomerType, DiscountValueType, IfOneTimeUse, OverrideAllOtherDiscounts, Validity } from 'src/app/_models/discount';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { AuditTrailService } from 'src/app/_services/audit-trial.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-audit-trial-details',
  templateUrl: './audit-trial-details.component.html',
  styleUrls: ['./audit-trial-details.component.scss']
})
export class AuditTrialDetailsComponent implements OnInit{

  _globals = GlobalService
  @Input() objectId = null
  @Input() filter = null
  @Input() action = null
  page = 0
  rows = 10
  totalRecords = 0
  selectedRow:any = '';
  showDetail:boolean = false;
  isUserRightsLoads:boolean = false;
  locationIds: any[];
  activityTypeIds: any[];
  operationTypeIds: any[];
  usersIds: any[];
  aduitTrialList: any = [];
  customerTypeList = CustomerType
  discountValueTypeList = DiscountValueType
  allowedChannelsList = AllowedChannels
  applyByList = ApplyBy
  ifOneTimeUseList = IfOneTimeUse
  allowedDaysList = AllowedDaysList
  validityList = Validity
  reasonList =[
    {id:0,value:'-'},
    {id:1,value:'Check Out'},
    {id:2,value:'Break'},
    {id:3,value:'Other'},
  ]
  noOfCopiesPrint :any[] = [
    {value:0 , title:1},
    {value:1 , title:2},
    {value:2 , title:3},
  ]
  rePrintType:any[]=[
    {value:0 , title:"Both"},
    {value:1 , title:"InvoiceReceipt"},
    {value:2 , title:"CreditDebitReceipt"},
  ]
  backOfficieCopy :any[] = [
    {value:0 , title:"Primary"},
    {value:1 , title:"Secondary"}
  ]
  type =[
    {id:0,value:'Check-In'},
    {id:1,value:'Check-Out'}
  ]
  expiryDurationList = [
    { name: 'Never', title: 'Never' },
    { name: 'EveryWeek', title: 'Weekly' },
    { name: 'EveryMonth', title: 'Monthly' },
    { name: 'Yearly', title: 'Yearly' },
];

daysList = [
    { name: 'Sunday', title: 'Sunday' },
    { name: 'Monday', title: 'Monday' },
    { name: 'Tuesday', title: 'Tuesday' },
    { name: 'Wednesday', title: 'Wednesday' },
    { name: 'Thursday', title: 'Thursday' },
    { name: 'Friday', title: 'Friday' },
    { name: 'Saturday', title: 'Saturday' },
];

cashierAlerts = [
    { title: 'Display the "Reward Redemption" prompt on the cashier screen when the designed points goal is reached.', active: false },
    { title: 'Trigger a "Sound Alert" for the cashier when the designed points goal is reached', active: false },
];


//  { title: 'Reward Perk Information', active: true },

printInfoList = [
    { title: 'Customer First Name', active: true },
    { title: 'Customer Last Name', active: true },
    { title: 'Customer Phone', active: true },
    { title: 'Customer Email', active: true },
    // { title: 'Customer ID', active: true },
    { title: 'Current Loyalty Points', active: true },

];

smsNotificationList = [
    { title: 'Send SMS when points are redeemed', active: false },
    { title: 'Send SMS when points are accumulated', active: false },
];

  //order
  orderParam = ''
  orderBy = 'asc'
  @ViewChild('p', { static: false }) paginator: Paginator;
  colheader = [[
    'User Name', 'Location', 'Date-Time','Activity Type', 'Operation'
    , 'Old Details', 'Modification Details'
  ]];

  colflds = ['userName', 'location', 'date', 'activityType',
    'operation','oldDetails','modificationDetails' ];
  searchText: string;
  usersList: any;
 isUserListLoad:boolean = false;
  constructor(
    public _customerService: CustomersService,
    private _spinner: NgxSpinnerService,
    public _auditTrailServices:AuditTrailService,
    public _filterService:FilterServiceService,
    public _abd:AppBaseDataService,
    public _userService:UserService,
    public _productService:ProductsService,
  ) { }

  ngOnInit(): void {
    // this._globals.componentName = 'Audit Trial History'
  
    this.getDataList()
    // this.getPosBackOfficeRights();
  }
  getDataList() {
    try {
        this._abd.showSpinner();
        this.searchText = this._filterService.searchText;
        this.page = this.page < 1 ? 0 : this.page;
        this.rows = this.rows < 1 ? 10 : this.rows;

        let obj:any ={
          'pageSize': this.rows,
          'pageNo':this.page,
        }
        if(this.objectId !== null && this.filter === null){
          obj.objectId = this._auditTrailServices.selectedAudit?.objectId
        }else if(this.objectId === null && this.filter !== null){
          obj.filter = this.filter
        }

      this._auditTrailServices.getAuditTrailHistoryList(obj).subscribe((res) => {
        this._abd.hideSpinner()
            if (res.success) {
              if(res.data[0]?.operationType === 'ShippingDimensions' &&  (res.data[0]?.eventType === 'Add' || res.data[0]?.eventType === 'Modify' )){
               this.getProductDetails(res.data)
              }
              else if(res.data[0]?.operationType === 'StockAdjustment' &&  (res.data[0]?.eventType === 'Add' || res.data[0]?.eventType === 'Modify')){
                this.getProductDetails(res.data)
              }
              else if(res.data[0]?.operationType === 'Users'){
                this.getUsersDetails(res.data)
              }
              else{
              this.aduitTrialList = res.data.map((v)=>{
              return{
                ...v,name:this.getHistory(v),changeList:this.getDifferences(v)
              }
            });
          }
          this.totalRecords = res.totalRecords;

            }
        });
    } catch {
      this._abd.hideSpinner()
    }
}
removeDeletedText(input: string): string {
  return input.includes('_Deleted') ? input.replace(/_Deleted$/, '') : input;
}
  getUsersDetails(data: any){
  this.getPosBackOfficeRights(data)
  }
  getProductDetails(value?:any) {
      this.aduitTrialList = value.map((v)=>{
      return{
        ...v,name:v?.primary?.productName,changeList:this.getDifferences(v)
            }
           });
                
  }
seeHistory(value?:any){
    this.objectId = null
  this.filter = null
  this.filter = this.getHistory(value)
  if(this.filter !== null){
    this.getDataList();
  }
}

getHistory(value?:any,obj?:any){
   let val = null;
  if(value?.operationType === 'Department' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.departmentName || ''
  }
  else if(value?.operationType === 'Department' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.departmentName || ''
  }
  else if(value?.operationType === 'Manufacturer' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.manufacturerName || ''
  }
  else if(value?.operationType === 'Manufacturer' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.manufacturerName || ''
  }
  else if(value?.operationType === 'PerksReward' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.displayName || ''
  }
  else if(value?.operationType === 'PerksReward' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.displayName || ''
  }
  else if(value?.operationType === 'Categories' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.categoryName || ''
  }
  else if(value?.operationType === 'Categories' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.categoryName || ''
  }
  else if(value?.operationType === 'Vendor' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.vendorName || ''
  }
  else if(value?.operationType === 'Vendor' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.vendorName || ''
  }
  else if(value?.operationType === 'Product' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.title || ''
  }
  else if(value?.operationType === 'Product' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.title || ''
  }
  else if(value?.operationType === 'ShippingDimensions' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.primary?.productName || ''
  }

  else if(value?.operationType === 'Customer' &&  value?.eventType === 'Delete' ){
    val = this.getCustomerName(value?.newValues?.firstName,value?.newValues?.lastName);
  }
  else if(value?.operationType === 'Customer' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = this.getCustomerName(value?.newValues?.firstName,value?.newValues?.lastName);
  }
  else if(value?.operationType === 'Discount' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.discountName || ''
  }
  else if(value?.operationType === 'Discount' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.discountName || ''
  }
  else if(value?.operationType === 'Location' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.LocationName || ''
  }
  else if(value?.operationType === 'Location' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.LocationName || ''
  }
  else if(value?.operationType === 'Users' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.username || ''
  }
  else if(value?.operationType === 'Users' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.username || ''
  }
  else if(value?.operationType === 'Locations' &&  value?.eventType === 'Delete' ){
    val = value?.primary?.locationName || ''
  }
  else if(value?.operationType === 'Locations' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.primary?.locationName || ''
  }
  else if(value?.operationType === 'BarCodeQue' &&  value?.eventType === 'Delete' ){
    val = value?.oldVales?.productName || ''
  }
  else if(value?.operationType === 'BarCodeQue' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.productName || ''
  }
  else if(value?.operationType === 'PurchaseOrder' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.primary?.poNumber || ''
  }
  else if(value?.operationType === 'StockTransfer'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.primary?.transferNumber || ''
  }
  else if(value?.operationType === 'TenderTypes'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.newValues?.displayName || ''
  }
  else if(value?.operationType === 'BusinessSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'TimeTrackingSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'TaxRate'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'PrinterSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'CashDrawerSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'CashDropSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'SecuritySettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  else if(value?.operationType === 'PurchaseOrderSettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
    val = value?.operationType || ''
  }
  // else if(value?.operationType === 'SecuritySettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
  //   val = value?.operationType || ''
  // }
  // else if(value?.operationType === 'SecuritySettings'  && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
  //   val = value?.operationType || ''
  // }
  else if(value?.operationType === 'StockAdjustment' &&  value?.eventType === 'Add'){
    val = value?.name || ''
  }
  else if(value?.operationType === 'StockAdjustment' &&  value?.eventType === 'Modify'){
    val = value?.primary?.customerName || ''
  }
  // else if(value?.operationType === 'Product' && (value?.eventType === 'Modify' || value?.eventType === 'Add')){
  //   val = value?.newValues?.title || ''
  // }
   return val
  
}
isModefied(item){
  let operation = item?.operationType
  let action    = item?.eventType
  if(action === 'Modify'){
    return true
  }else{
    return false
  }
}
getMainLocation(value){
  return value
  }
  getCustomerName(firstName: any, lastName: any): any {
    if(firstName === undefined){
      firstName = ''
    }
    if(lastName === undefined){
      lastName = ''
    }
    if((firstName+' '+lastName) === " "){
      return '';
    }
    return firstName+' '+lastName
  }
getDifferences(v:any,userRighrsArray?:any) {
 let v1 = v?.oldVales
 let v2 =v?.newValues
 let  operationType = v?.operationType 
//  General
 let ignore =[
              // Transfer  // 
              'receivedById','subTotal',
  
              'manufacturerBarcode','customerGroup','purchaseEnable','sendAppNotification','sendEmail','sendSMS','printImage','eligibleCategory','eligibleDepartments','eligibleProducts','selectedLoyaltyCustomers','maximumItemsValueInDollar','maximumItemsInCart','limitDiscount','maximumAllowedPerDay','isDeleted','useCashDropWarningLimit','isSaved','deleted','receiptPrintCustomerId','taxRateId','isVisible','image',
              'lastUpdated','token','userID','businessId','businessType','subscription','standardsForms','isBusinessSetup',
              'accountStatus','accountStatusString','isSubscriptionCompleted','logo','backgroundImage',
              'slogan','subscriptionFailedInfo','profileCreated','profileCreatedString',
              'ccCustomerProfile','subscriptionInternval','wlApplicationEnvironment','freeTrialStartDate',
              'freeTrialEndDate','productCode','method','locationId','updated','lastEdited',
              'modified','lastModified','createdDate','partitionKey','isDefault','version','id','uomDescription']
//  Printer Configuration
let printerIgnore = [
                  'registerId','locationName',
                  'locationId' ,'enableTillPrinting' ,
                  'receiptBarCodeType' ,'isShowVoidItemsOnReciept' ,
                  'enableNoSalePrinting','enableResetDrawerPrinting','isDeleted','useCashDropWarningLimit','isSaved','deleted',
              'method','locationId','updated','lastEdited',
              'modified','lastModified','createdDate','isDefault','id'
]
 if(v1 !==undefined && v2 !== undefined){
      const differences: any = [];
      let index = 0;
  for (const key in v1) {
    if (operationType === 'TimeAttendance' && key === 'createdDate' &&  v1[key] !== v2[key]) {
      differences[index] = { prop:this.getKeyPropName(key,operationType),v1: this.getPropV(key,v1[key],operationType), v2: this.getPropV(key,v2[key],operationType) };
    }
    else if (operationType === 'PrinterSettings' && !printerIgnore.includes(key) &&  v1[key] !== v2[key]) {
      differences[index] = { prop:this.getKeyPropName(key,operationType),v1: this.getPropV(key,v1[key],operationType), v2: this.getPropV(key,v2[key],operationType) };
      index++
    }
    else if (operationType !== 'PrinterSettings' && !ignore.includes(key) && v1[key] !== v2[key]) {
      differences[index] = { prop:this.getKeyPropName(key,operationType),v1: this.getPropV(key,v1[key],operationType,userRighrsArray), v2: this.getPropV(key,v2[key],operationType,userRighrsArray) };
      index++
    }
  }
  return differences;

   }
   else if(v1 === undefined && v2 !== undefined){
     //  Add
    const differences: any = [];
     let index = 0;
    for (const key in v2) {
      if (!ignore.includes(key)) {
     if(key === 'discountName'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(operationType === 'Vendor' && key === 'vendorName'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(key === 'departmentName'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(key === 'manufacturerName'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }

     else if(operationType === 'InventoryCount' && key === 'quantity'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(operationType === 'StockAdjustment' && key === 'quantity'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(operationType === 'Customer' && key === 'quantity'){
      return  [{ prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key]) }];
     }
     else if(operationType === 'Categories' && (key === 'categoryName' || key === 'departmentId')){
      if(operationType === 'Categories' && key === 'categoryName'){
        differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
      }else if(operationType === 'Categories' && key === 'departmentId'){
        differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
      }
      index++
      return differences;
     }
    //  else if(operationType === 'StockTransfer' && (key === 'senderBy' || key === 'warehouseFrom' || key === 'warehouseTo' || key === 'grandTotal')){
    //   // if(operationType === 'StockTransfer' && key === 'senderBy'){
    //   //   differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
    //   //   // index++
    //   // }
    //   // else
      //  if(operationType === 'StockTransfer' && key === 'warehouseFrom'){
      //   differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
      //   index++
      // }
      // else if(operationType === 'StockTransfer' && key === 'warehouseTo'){
      //   differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
      //   index++
      // }
    //   // else if(operationType === 'StockTransfer' && key === 'grandTotal'){
    //   //   differences[index] = { prop:this.getKeyPropName(key),v1:'-', v2: this.getPropV(key,v2[key])};
    //   //   // index++
    //   // }
    //   // index++
    //   return differences;
    //  }

    }
    }
    return;
   }
   else if(v1 !== undefined && v2 === undefined){
   
  //  Delete
    const differences: any = [];
     let index = 0;
    for (const key in v1) {
      if (!ignore.includes(key)) {
      if(key === 'discountName'){
      return  [{ prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-' }];
     }
     else if(operationType === 'Vendor' && key === 'vendorName'){
      return  [{ prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-' }];
     }
     else if(key === 'departmentName'){
      return  [{ prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-' }];
     }
     else if(key === 'manufacturerName'){
      return  [{ prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-' }];
     }
     else if(operationType === 'InventoryCount' && key === 'quantity'){
      return  [{ prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-' }];
     }
     else if(operationType === 'Categories' && (key === 'categoryName' || key === 'departmentId')){
      if(operationType === 'Categories' && key === 'categoryName'){
        differences[index] = { prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-'};
      }
      else if(operationType === 'Categories' && key === 'departmentId'){
        differences[index] = { prop:this.getKeyPropName(key), v1: this.getPropV(key,v1[key]),v2:'-'};
      }
      index++
      return differences;
     }

    }
    }
    return ;
   }

}
   getPropV(key:any,val: any,operationType?:any,userRighrsArray?:any) {
    if(key === 'departmentId'){
      return this._abd.departments.filter((v)=>v.id === val)[0]?.departmentName
    }
    else if(operationType === 'PrinterSettings' && (key === 'active'|| key === 'enableCashDropPrinting' || key === 'enableCashCountPrinting'  || key === 'enableElectricPaymentPrinting' || key === 'enableElectricPaymentPrinting' || key === 'enablePayInPrinting' || key === 'enablePayOutPrinting' || key === 'enableReceiptBarCodePrinting' || key === 'printBackOfficeCopy' || key === 'printAdditionalReceipt' || key === 'isEnableReprint' || key === 'enableRecieptPrinting' )){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(key === 'sellQuantity'){
      return this._productService.productInfo?.sellQuantity.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(key === 'defaultPrice'){
      return this._productService.productInfo?.defaultPrice.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(key === 'allowDiscount'){
      return this._productService.productInfo?.discount.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(key === 'ageRestriction'){
      return this._productService.productInfo?.ageRestriction.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(operationType === 'Locations' ){
      return val || ''
    }

    else if(key === 'gender'){
      return this._productService.productInfo?.gender.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(key === 'itemType'){
      return this._productService.productInfo?.itemType.filter((v)=>v.value === val)[0]?.name || '-';
    }
    else if(operationType === 'Discount' && key === 'isActive'){
      return val = val === true ? 'Active' : 'Inactive';
    }
    else if(operationType === 'PerksReward' && key === 'active'){
      return val = val === true ? 'Active' : 'Inactive';
    }
    else if(operationType === 'Customer' && key === 'status'){
      return val = val === true ? 'Active' : 'Inactive';
    }
    else if(operationType === 'Discount' && key === 'overrideAllOtherDiscounts'){
      return val = val = true ? 'Yes' : 'No';
    }
    else if(key === 'productSellType'){
      if(val === 'all'){
        return 'All';
      }
      else if(val === 'offline'){
        return 'Sell in Store'
      }else{
        return 'Sell Online'
      }
    }
    else if(key === 'pointsExpire'){
      if(val === 0){
        return 'Never';
      }
      else if(val === 1){
        return 'Every Month'
      }
      else if(val === 2){
        return 'Every Week'
      }
      else if(val === 3){
        return 'Yearly'
      }
    }
    else if(key === 'uomId'){
      return this._abd.uom.filter((v)=>v.id === val)[0]?.unitName || '-';
    }
    else if(key === 'categoryId'){
      return this._abd.categories.filter((v)=>v.categoryId === val)[0]?.categoryName || '-';
    }
    else if(key === 'transfersInStatus'){
      return this._abd.transfersInStatus.filter((v)=>v.id === val)[0]?.title || '-';
    }
    else if(key === 'transfersOutStatus'){
      return this._abd.transferStatusOut.filter((v)=>v.id === val)[0]?.title || '-';
    }
    else if(key === 'weightUOM'){
      return this._abd.WeightUOM.filter((v)=>v.id === val)[0]?.title || '-';
    }
    else if(key === 'dimensionUOM'){
      return this._abd.DimensionUOM.filter((v)=>v.id === val)[0]?.title || '-';
    }
    else if( operationType === 'TimeAttendance'  && key === 'type'){
      return  this.type.filter((v)=> v.id === val)[0].value || '-';
    }
    else if( operationType === 'TimeAttendance'  && key === 'reason'){
      return  this.reasonList.filter((v)=> v.id === val)[0].value || '-';
    }
    else if( operationType === 'TimeAttendance'  && key === 'createdDate'){
      return  formatDate(val, 'HH:mm a', 'en-US');
    }
    else if( operationType === 'TimeTrackingSettings'  && key !== 'totalWorkingHours'){
       return val = val === true ? 'Enable' : 'Disable'
    }
    else if( operationType === 'InvoiceConfiguration'  && (key !== 'allowItemDiscount' || key === 'allowInvoiceDiscount')){
       return val = val === true ? 'Enable' : 'Disable'
    }
    else if( operationType === 'Discount'  && key === 'customerType'){
      return  this.customerTypeList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'discountValueType'){
      return  this.discountValueTypeList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'allowedChannels'){
      return  this.allowedChannelsList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'applyBy'){
      return  this.applyByList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'ifOneTimeUse'){
      return  this.ifOneTimeUseList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'allowedDays'){
      return  this.replaceIdsWithNames(val);
    }
    else if( operationType === 'Discount'  && key === 'notify'){
      return  val = val === true ? 'Yes' : 'No';
    }
    else if( operationType === 'Discount'  && key === 'printTextInFooter'){
      return  val = val === true ? 'Yes' : 'No';
    }
    else if( operationType === 'Discount'  && key === 'printCouponAtRegister'){
      return  val = val === true ? 'Yes' : 'No';
    }
    else if( operationType === 'Discount'  && key === 'validity'){
      return  this.validityList.filter((v)=> v.id === val)[0].viewValue || '-';
    }
    else if( operationType === 'Discount'  && key === 'validityStartDate'){
      return  formatDate(val, 'MM/dd/yyyy', 'en-US');
    }    
    else if( operationType === 'Discount'  && key === 'validityEndDate'){
      return  formatDate(val, 'MM/dd/yyyy', 'en-US');
    }
    else if( operationType === 'Discount'  && key === 'printCouponStartDate'){
      return  formatDate(val, 'MM/dd/yyyy', 'en-US');
    }    
    else if( operationType === 'Customer'  && key === 'firstName'){
      return  val;
    }    
    else if( operationType === 'Customer'  && key === 'lastName'){
      return  val;
    }   
    else if( operationType === 'Product'  && key === 'discountPrice'){
      return  val;
    }   
    else if( operationType === 'Product'  && key === 'openingStock'){
      return  val;
    }   
    else if( operationType === 'Users'  && key === 'roles'){
      return  this.replaceIdsWithRolesNames(val);
    }
    else if( operationType === 'Users'  && key === 'userLocations'){
      return  this.replaceIdsWithLocationsNames(val);
    }
    else if( operationType === 'Discount'  && key === 'allowedLocations'){
      return  this.replaceIdsWithLocationsNames(val);
    }
    else if( operationType === 'Users'  && key === 'userRights'){
      return  this.replaceIdsWithRightsNames(val,userRighrsArray);
    } 
    else if( operationType === 'Discount'  && key === 'printCouponEndDate'){
      return  formatDate(val, 'MM/dd/yyyy', 'en-US');
    }
    else if(operationType === 'PrinterSettings' && key === 'reprintType'){
      return this.rePrintType.filter((v)=>v.value === val)[0]?.title
    }
    else if(operationType === 'PrinterSettings' && key === 'invoicePrintCopyCount'){
      return this.noOfCopiesPrint.filter((v)=>v.value === val)[0]?.title
    }
    else if(operationType === 'PrinterSettings' && key === 'backOfficeCopyPrinter'){
      return this.backOfficieCopy.filter((v)=>v.value === val)[0]?.title
    }
    else if(operationType === 'Product' && key === 'autoPurchaseOrder'){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'CashDropSettings' && key === 'active'){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'TenderTypes' && key === 'status'){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'TenderConfiguration' && key === 'enableTip'){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'TenderConfiguration' && key === 'enableTip'){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'LoyaltyConfigs' && ( key === 'pointsRedeemEmailSubject' || key === 'receiptPrintPointInfo' || key === 'cashierSoundAlert' ||  key === 'cashierPromptRewardRedemptionOnPointsGoalReached' ||  key === 'receiptPrintCustomerFName' || key === 'receiptPrintCustomerLName' || key === 'receiptPrintCustomerPhone' || key === 'receiptPrintCustomerEmail' || key === 'receiptPrintCurrentPoints' || key === 'emailNotificationToCustomers' || key === 'displayPointsOnSecondaryScreen' || key === 'pointsAccumulationOnOpenDeptItems' || 
                                                    key === 'pointsAccumulationOnDiscountedItems' || key === 'cashierAlert' || key ==='isExtraRewardInfoOnReceipt')){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(operationType === 'SecuritySettings' && key === 'idleScreenLockTime'){
      return val.toString();
    }
    else if(operationType === 'CashDrawerSettings' || (operationType === 'SecuritySettings' && key !== 'idleScreenLockTime') || operationType ==='TenderConfiguration'){
      return val = val === true ? 'Enable' : 'Disable'
    }

    else if(operationType === 'Product' && (key === 'taxable' || key === 'sellOnline' || key === 'isTaxable' || key === 'isReturnable' || key === 'allowNegativeStock' || key === 'printOnInvoice' || key === 'trackStock' || key === 'isOpnDept')){
      return val = val === true ? 'Enable' : 'Disable'
    }
    else if(key === 'phone'){
      return this._globals.generatePhoneFormatUS(val)
    }
    else if(key === 'quantity' || key === 'minimumItemsInCart' || key === 'maximumItemsInCart'){ 
      return val.toString()
    }
    return val ? val : '-'
  }
  isShowhistory(value?:any){

    return true
  }
  getPosBackOfficeRights(value?:any){
    try {
      this._spinner.show()
      forkJoin([this._userService.getPosRights(),this._userService.getBackOfficeRights()])
      .subscribe(([res1,res2]) => {
        if (res1.success && res2.success) {
          this.isUserRightsLoads = true;
          let posRights = res1.data.right
          let backOffice = res2.data.right
          const result1 = posRights.flatMap(item => [
            { rightId: item.rightId, name: item.name },
            ...item.childs.map(child => ({ rightId: child.rightId, name: child.name }))
          ]);
          const result2 = backOffice.flatMap(item => [
            { rightId: item.rightId, name: item.name },
            ...item.childs.map(child => ({ rightId: child.rightId, name: child.name }))
          ]);
          let array =  [].concat(result1,result2)
        
          this.aduitTrialList = value.map((v)=>{
            return{
              ...v,name:this.getHistory(v),changeList:this.getDifferences(v,array)
            }
          });
          
        }
        this._spinner.hide()
      })

    }
    catch { this._spinner.hide() }
  }

  replaceIdsWithRightsNames(idString?: string,array?:any[]): string {
  
    let objectArray = array;
    if (!idString) return '-'; 
    const ids = idString.split(','); 
    const names = ids.map(id => {
      const foundObject = objectArray.find(obj => obj.rightId.toString() === id.trim());
      return foundObject ? foundObject.name : id;
    });
    return names.join(', '); 
  }
  replaceIdsWithLocationsNames(idString?: string): string {
   let objectArray = this._abd.locations;
    if (!idString) return '-'; 
    const ids = idString.split(','); 
    const names = ids.map(id => {
      const foundObject = objectArray.find(obj => obj.id.toString() === id.trim());
      return foundObject ? foundObject.locationName : id;
    });
    return names.join(', '); 
  }
  replaceIdsWithRolesNames(idString?: string): string {
   let objectArray = this._abd.roles;
    if (!idString) return '-'; 
    const ids = idString.split(','); 
    const names = ids.map(id => {
      const foundObject = objectArray.find(obj => obj.roleId.toString() === id.trim());
      return foundObject ? foundObject.name : id;
    });
    return names.join(', '); 
  }
  replaceIdsWithNames(idString?: string): string {
   let objectArray = this.allowedDaysList
    if (!idString) return '-'; 
    const ids = idString.split(','); 
    const names = ids.map(id => {
      const foundObject = objectArray.find(obj => obj.id.toString() === id.trim());
      return foundObject ? foundObject.viewValue : id;
    });
    return names.join(', '); 
  }
  getKeyPropName(key: string,operationType?:any) {
    if(key === 'isDeleted'){
      return 'Deleted'
  }
  else if(key === 'description'){
    return 'Description'
}
  else if(key === 'transfersInStatus'){
    return 'Transfers In Status'
}
  else if(key === 'transfersOutStatus'){
    return 'Transfers Out Status'
}
  else if(key === 'allowInvoiceDiscount'){
    return 'Allow Invoice Discount'
}
  else if(key === 'allowItemDiscount'){
    return 'Allow Item Discount'
}
  else if(key === 'departmentName'){
      return 'Department Name'
  }
  else if(key === 'cashierSoundAlert'){
      return 'Trigger a "Sound Alert" for the cashier when the designed points goal is reached'
  }
  else if(key === 'cashierPromptRewardRedemptionOnPointsGoalReached'){
      return 'Display the "Reward Redemption" prompt on the cashier screen when the designed points goal is reached.'
  }
  else if(key === 'maxDiscount'){
      return 'Max Discount ($)'
  }
  else if(key === 'pointsGoal'){
      return 'Points Goal'
  }
  else if(key === 'pointsExpire'){
      return 'Points Expire'
  }
  else if(key === 'cashierAlert'){
      return 'Cashier Alert'
  }
  else if(key === 'receiptPrintPointInfo'){
      return 'Print points info on the receipt'
  }
  else if(key === 'receiptPrintCustomerFName'){
      return 'Print Customer First Name'
  }
  else if(key === 'receiptPrintCustomerLName'){
      return 'Print Customer Last Name'
  }
  else if(key === 'receiptPrintCustomerPhone'){
      return 'Print Customer Phone'
  }
  else if(key === 'receiptPrintCurrentPoints'){
      return 'Print Current Loyalty Points'
  }
  else if(key === 'pointsAccumulateEmailSubject'){
      return 'Points Accumulate Email Subjects'
  }
  else if(key === 'pointsAccumulateEmailBody'){
      return 'Points Accumulate Email Body'
  }
  else if(key === 'pointsRedeemEmailSubject'){
      return 'Points Redeem Email Subject'
  }
  else if(key === 'pointsRedeemEmailSubject'){
      return 'Send Email when points are accumulated'
  }
  else if(key === 'receiptPrintCustomerEmail'){
      return 'Print Customer Email'
  }
  else if(key === 'displayPointsOnSecondaryScreen'){
      return 'Display points info on secondary screen'
  }
  else if(key === 'emailNotificationToCustomers'){
      return 'Email notification to customers'
  }
  else if(key === 'isExtraRewardInfoOnReceipt'){
      return 'Print reward program on receipt'
  }
  else if(key === 'extraRewardInfoOnReceipt'){
      return 'Print reward program text on receipt'
  }
  else if(key === 'maxAllowedPoints'){
      return 'Maximum Points Allowed'
  }
  else if(key === 'minSpendAmountReward'){
      return 'Points Value'
  }
  else if(key === 'minCartValue'){
      return 'Minimum Purchase Required for points Accumulation'
  }
  else if(key === 'minimumItemsInCart'){
      return 'Minimum Items in cart'
  }
  else if(key === 'maximumItemsInCart'){
      return 'Maximum Items in cart'
  }
  else if(key === 'categoryId'){
      return 'Category'
  }
  else if(key === 'warehouseFrom'){
      return 'Location From'
  }
  else if(key === 'warehouseTo'){
      return 'Location To'
  }
  else if(key === 'senderBy'){
      return 'Sender'
  }
  else if(key === 'grandTotal'){
      return 'Grand Total ($)'
  }
  else if(key === 'ageRestriction'){
      return 'Age Restriction'
  }
  else if(key === 'gender'){
      return 'Gender'
  }
  else if(key === 'sellOnline'){
      return 'Sell Online'
  }
  else if(key === 'isTaxable'){
      return 'Taxable'
  }
  else if(key === 'isTaxable'){
      return 'IsTaxable'
  }
  else if(key === 'printOnInvoice'){
      return 'Print Notes at the end of Receipt'
  }
  else if(key === 'productSellType'){
      return 'Product Sell Type'
  }
  else if(key === 'itemType'){
      return 'Item Type'
  }
  else if(key === 'trackStock'){
      return 'Track Stock'
  }
  else if(key === 'isOpnDept'){
      return 'Open Price - Prompt for price on POS'
  }
  else if(key === 'isReturnable'){
      return 'Is Product Returnable'
  }
  else if(key === 'allowNegativeStock'){
      return 'Allow NegativeStock'
  }
  else if(key === 'openingStock'){
      return 'Opening Stock'
  }
  else if(key === 'status'){
      return 'Status'
  }
  else if(key === 'taxable'){
      return 'Taxable'
  }
  else if(key === 'discountPrice'){
      return 'Discount Price'
  }
  else if(key === 'allowDiscount'){
      return 'Discount'
  }
  else if(key === 'allowedLocations'){
      return 'Allowed Locations'
  }
  else if(key === 'height'){
      return 'Height'
  }
  else if(key === 'rGrandTotal'){
      return 'Grand Total ($)'
  }
  else if(key === 'receivedBy'){
      return 'Received By'
  }
  else if(key === 'length'){
      return 'Length'
  }
  else if(key === 'width'){
      return 'Width'
  }
  else if(key === 'weight'){
      return 'Weight'
  }
  else if(key === 'weightUOM'){
      return 'Weight Unit'
  }
  else if(key === 'dimensionUOM'){
      return 'Dimension'
  }
  else if(key === 'defaultPrice'){
      return 'Default Price'
  }
  // Manufacturer
  else if(key === 'manufacturerName'){
    return 'Manufacturer Name'
 }
  else if(key === 'firstName'){
    return 'First Name'
 }
  else if(key === 'lastName'){
    return 'Last Name'
 }
  else if(key === 'enableTip'){
    return 'Enable Tip'
 }
  
  // Category
  else if(key === 'categoryName'){
    return 'Category Name'
 }
  else if(key === 'departmentId'){
    return 'Department Name'
 }

 //  Product
  else if(key === 'salePriceA'){
    return 'Sale Price A'
 }
  else if(key === 'title'){
    return 'Title'
 }
  else if(key === 'salePriceB'){
    return 'Sale Price B'
 }
  else if(key === 'salePriceC'){
    return 'Sale Price C'
 }
  else if(key === 'manufacturerBarcode'){
    return 'Manufacturer Barcode'
 }
   else if(key === 'skuCode'){
    return 'SKU Code'
 }
   else if(key === 'barcode'){
    return 'Barcode'
 }
   else if(key === 'autoPurchaseOrder'){
    return 'Auto PurchaseOrder'
 }

   else if(key === 'minLevel'){
    return 'Min Stock Level'
 }
   else if(key === 'maxLevel'){
    return 'Max Stock Level'
 }
   else if(key === 'reorderLevel'){
    return 'Reorder Level'
 }
   else if(key === 'minOrderQty'){
    return 'Minimum Order Quantity'
 }
   else if(key === 'maxOrderQty'){
    return 'Maximum Order Quantity'
 }
   else if(key === 'costPrice'){
    return 'Cost Price'
 }
   else if(key === 'sellQuantity'){
    return 'Sell Quantity'
 }
   else if(key === 'caseQty'){
    return 'Case Quantity'
 }
   else if(key === 'uomId'){
    return 'UOM (Unit of Measure)'
 }
     else if(key === 'altBarcodes'){
    return 'Alternate Barcodes'
 } 
 else if(key === 'altSkus'){
    return 'Alternate SKUs'
 }

   else if(key === 'metaTags'){
    return 'Tags'
 }
   else if(key === 'quantity'){
    return 'Quantity'
 }
   else if(key === 'address'){
    return 'Address'
 }
   else if(key === 'state'){
    return 'State'
 }
   else if(key === 'zip'){
    return 'Zip'
 }
   else if(key === 'userLocations'){
    return 'User Locations'
 }
   else if(key === 'userRights'){
    return 'User Rights'
 }
   else if(key === 'overrideCode'){
    return 'Over Ride Code'
 }
   else if(key === 'overrideCode2'){
    return 'Over Ride Code2'
 }
   else if(key === 'roles'){
    return 'Roles'
 }
   else if(key === 'rewardType'){
    return 'Reward Type'
 }

//  Department
   else if(key === 'departmentName'){
    return 'Department Name'
 }
//  Discount  
else if(key === 'discountName'){
    return 'Discount Name'
 }
else if(key === 'discountAmount'){
    return 'Discount Amount'
 }
 else if(key === 'couponCodes'){
  return 'Coupon Codes'
}
else if(key === 'discountValueType'){
  return 'Discount Type'
}
else if(key === 'maximumAllowed'){
  return 'Max Discount Allowed ($)'
}
else if(key === 'maxCouponUse'){
  return 'Max Coupon Use Count'
}
else if(key === 'maxCouponCodeUseLimit'){
  return 'Max Coupon Code Use Limit'
}
else if(key === 'customerType'){
  return 'Customer Type'
}
else if(key === 'minimumCartValueInDollar'){
  return 'Min Cart Value In Dollar ($)'
}
else if(key === 'maximumItemsValueInDollar'){
  return 'Maximum Items value ($)'
}
else if(key === 'allowedChannels'){
  return 'Allowed Channels'
}
else if(key === 'applyBy'){
  return 'Apply By'
}
else if(key === 'printText'){
  return 'Coupon Text'
}
else if(key === 'printCouponAtRegister'){
  return 'Print Coupon at Register'
}
else if(key === 'receiptFooterText'){
  return 'Print Text in Footer Content'
}
else if(key === 'printTextInFooter'){
  return 'Print Text in Footer'
}
else if(key === 'notify'){
  return 'Notify'
}
else if(key === 'discountValue'){
  return 'Discount Value'
}
else if(key === 'ifOneTimeUse'){
  return 'Limit Use'
}
else if(key === 'allowedDays'){
  return 'Allowed Days'
}
else if(key === 'validity'){
  return 'Validity'
}
else if(key === 'validityStartDate'){
  return 'Validity Start Date'
}
else if(key === 'validityEndDate'){
  return 'Validity End Date'
}
else if(key === 'printCouponStartDate'){
  return 'Print CouponStart Date'
}
else if(key === 'printCouponEndDate'){
  return 'Print CouponEnd Date'
}
else if(key === 'isActive'){
  return 'Status'
}
//  Loyalty
else if(key === 'pointsAccumulationOnDiscountedItems'){
    return 'Allow points accumulation on discounted items'
 }
 else if(key === 'pointsAccumulationOnOpenDeptItems'){
    return 'Allow points accumulation on open department items'
 }
 else if(key === 'pointsRedeemEmailBody'){
    return 'Points Redeem Email Body'
 }
//  Business
 else if(key === 'businessName'){
    return 'Business Name'
 }
 else if(key === 'businessType'){
    return 'Business Type'
 }
 else if(key === 'noOfLocations'){
    return 'No. of Locations'
 }
 else if(key === 'noOfRegisters'){
    return 'No. of Registers'
 }
 else if(key === 'street'){
    return 'Street'
 }
 else if(key === 'phone'){
    return 'Phone'
 }
 else if(key === 'city'){
    return 'City'
 }
 else if(key === 'city'){
    return 'City'
 }
//  Vendor
 else if(key === 'vendorName'){
    return 'Vendor Name'
 }
 else if(key === 'company'){
    return 'Company'
 }
 else if(key === 'promptCustomerTip'){
    return 'Enable Prompt Customer For Tip'
 }
 else if(key === 'terms'){
    return 'Terms'
 }
 else if(key === 'account'){
    return 'Account'
 }
 else if(key === 'vendorWebsite'){
    return 'Vendor Website'
 }
//  Time Attendace
else if(key === 'type'){
  return 'Type'
}
else if(key === 'reason'){
  return 'Reason'
}
else if(key === 'locationName'){
  return 'Location Name'
}
// Tax Configuration
else if(key === 'tax1'){
  return 'Tax Rate (%)'
}
// Printing Settings
else if(key === 'enableRecieptPrinting'){
  return 'Enable Reciept Printing'
}
else if(key === 'enableElectricPaymentPrinting'){
  return 'Enable Electric Payment Printing'
}
else if(key === 'enableCashDropPrinting'){
  return 'Enable Cash Drop Printing'
}
else if(key === 'enablePayOutPrinting'){
  return 'Enable PayOut Printing'
}
else if(key === 'footerText'){
  return 'Footer Text'
}
else if(key === 'active'){
  return 'Active'
}
else if(key === 'enablePayInPrinting'){
  return 'Enable PayIn Printing'
}
else if(key === 'enableCashCountPrinting'){
  return 'Enable Cash Count Printing'
}
else if(key === 'enableReceiptBarCodePrinting'){
  return 'Enable Receipt BarCode Printing'
}
// Cash Drop Settings
else if(key === 'cashDropWarningLimit'){
  return 'Cash Drop Warning Limit ($)'
}
else if(key === 'cashDropThreshhold'){
  return 'Cash Drop Threshhold ($)'
}
// CashDrawerSettings
else if(key === 'forceTillManagement'){
  return 'Force Till Management'
}
else if(key === 'reqVerificationTillManagement'){
  return 'Require Verification for Cash Management'
}
else if(key === 'showCurrentTillAmount'){
  return 'Show Current Till amount when till removed'
}
else if(key === 'openCashDrawerCashDrop'){
  return 'Open Cash Drawer On Cash Management'
}
else if(key === 'openCashDrawerOnCashTransaction'){
  return 'Open Cash Drawer On Cash Transaction'
}
else if(key === 'overShortAmount'){
  return 'Show Over/Short Amount on Cash Count'
}
else if(key === 'reason'){
  return 'Reason'
}
// Securty Configuration
else if(key === 'enableLogInByUsernamePassword') {
  return 'Enable Login by Username/Password';
}
else if(key === 'enableLogInByCard') {
  return 'Enable Login by Card';
}
else if(key === 'enableLogInByPin') {
  return 'Enable Login by Pin';
}
else if(key === 'enableLogInByFingerPrint') {
  return 'Enable Login by Fingerprint';
}
else if(key === 'idleScreenLockTime') {
  return 'Idle Screen Lock Time in Minutes';
}
// Time Tracking 
else if(key === 'forceCheckIn') {
  return 'Force Check In';
}
else if(key === 'enableTotalWorkingHours') {
  return 'Enable Total Working Hours';
}
else if(key === 'printTimeTrackingReportFromPOSAPP') {
  return 'Print Time Tracking Report From POS APP';
}
else if(key === 'totalWorkingHours') {
  return 'Total Working Hours';
}
else if(key === 'autoCheckoutOnLogout') {
  return 'Auto Checkout On Logout';
}
else if(key === 'poStatus') {
  return 'PO Status';
}
else if(key === 'inventoryStatus') {
  return 'Inventory Status';
}
else if(key === 'cashVoidLimit') {
  return 'Cash Void Limit ($)';
}
else if(key === 'maxDiscountPer') {
  return 'Max Discount (%)';
}
else if(key === 'maxDiscountFlat') {
  return 'Max Discount ($)';
}
else if(key === 'maxLineItemPrice') {
  return 'Max Line Item Price ($)';
}
else if(key === 'isEnableReprint') {
  return 'Enable Reprint Functionality';
}
else if(key === 'reprintType') {
  return 'Reprint Type';
}
else if(key === 'printAdditionalReceipt') {
  return 'Print Additional Receipt';
}
//  Default
    return key
}
getUsersList() {

  const obj:any = {
    "Name": "GETALLEMPLOYEES",
    "User": {
      "ParentID": (this._globals?.userInfo.parentID != '' ? this._globals.userInfo.userID : this._globals.userInfo.parentID),
      "CompanyName": this._globals?.userInfo.businessSetup[0].businessName.toUpperCase()
    },
    "PageNo": 0,
    "PageSize": 999999,
  }
   setTimeout(() => {
    try {
     this._userService.getAllUsers(obj)
    .subscribe((res) => {
   this.usersList = res.data
   this.isUserListLoad =true
    })
    } catch { 
      this.usersList=[]
    }
  }, 500);

}
getUserWithId(id:any){
  if(this.isUserListLoad && id !== undefined){
    return this.usersList.filter((v)=>v.userID === id)[0]?.displayName
  }else{
    return;
  }
}
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)
    this.getDataList()
  }
  filterButtonPressed() {
    this.page = 0
    this.paginator?.changePage(0)
    this.getDataList()
  }
  customSort(e) {
    const last = this.orderParam

    switch (e.sortField) {
      case 'itemName':
        this.orderParam = 'title'
        break;
      case 'barCode':
        this.orderParam = 'Barcode'
        break;
      case 'skuCode':
        this.orderParam = 'SkuCode'
        break;
      case 'stockInhand':
        this.orderParam = 'StockInhand'
        break;
      case 'minOrderQty':
        this.orderParam = 'MinOrderQty'
        break;
      case 'maxOrderQty':
        this.orderParam = 'MaxOrderQty'
        break;
      case 'orderQuantity':
        this.orderParam = 'OrderQuantity'
        break;
      case 'poStatus':
        this.orderParam = 'PoStatus'
        break;
      default:
        this.orderParam = ''
    }

    if (this.orderParam !== '') {
      this.orderBy = this.orderBy == 'asc' ? 'desc' : 'asc'
      if (last !== this.orderParam) this.orderBy = 'asc'
      this.getDataList()
    }
  }
  onRowSelect(e) {
    this._auditTrailServices.selectedAudit = e.data;
    this.showDetail = true

  }
  callbackFuncFromFilter(event) {
    this.getDataList()
  }
  getLocationsList() {
    let self = this;
    this.locationIds = [];
    let selLocation = this._filterService.selectedLocation;

    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.locationIds.push(value.locationId.toString())
    })
  }
  getActivityType() {
    let self = this;
    this.activityTypeIds = [];
    let selA = this._filterService.selectedActivityType;
    selA = (typeof selA != 'undefined' && selA instanceof Array) ? selA : [selA];
    selA.map(function (value: { value: { toString: () => string; }; }) {
      self.activityTypeIds.push(value.value.toString())
    })
  }
  getOperationType() {
    let self = this;
    this.operationTypeIds = [];
    let selO = this._filterService.selectedOperationType;
    selO = (typeof selO != 'undefined' && selO instanceof Array) ? selO : [selO];
    selO.map(function (value: { value: { toString: () => string; }; }) {
      self.operationTypeIds.push(value.value.toString())
    })
  }
  getUsers() {
    let self = this;
    this.usersIds = [];
    let selLocation = this._filterService.selectedLocation;
    selLocation = (typeof selLocation != 'undefined' && selLocation instanceof Array) ? selLocation : [selLocation];
    selLocation.map(function (value: { locationId: { toString: () => string; }; }) {
      self.usersIds.push(value?.locationId.toString())
    })
  }
  exportExcel() {
    let data =this.aduitTrialList.map((v)=>{
      return{
        ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
      }
     })
    this._abd.exportExcel(data, this.colheader[0], this.colflds, 'Audit Trail')
  }

  exportPdf() {
 let data =this.aduitTrialList.map((v)=>{
  return{
    ...v,product:v.productName+"\n"+v.barcode+"\n"+v.sku
  }
 })
    this._abd.exportPdf(data, this.colheader, this.colflds, 'Audit Trail',false,false,false)
   
  }
}


