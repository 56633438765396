import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-transfer-out-form',
  templateUrl: './transfer-out-form.component.html',
  styleUrls: ['./transfer-out-form.component.scss']
})
export class TransferOutFormComponent implements OnInit {
  userName = ''
  creationDate = new Date()
  expectedDate = new Date(new Date().setDate(new Date().getDate() + 7))

  searchText = ''
  lastSearchText = ''
  searchList: any = ''
  selectedProduct: any = ''
  activeItem: any = ''
  activeItem4Editing: any = ''
  localActiveItem: any = ''
  activeItemDetails: any = ''
  isViewOnly = false
  isEdit = false
  isNew = false
  transferId = ''
  id = 0
  requestedById: any = ''
  selectAll = false

  isLocationError = false
  isEnableDeleteButton = true
  isLoading = false
  isListLoading = false
  dataList: any = []
  locations: any = []

  fromLocation: any = ''
  toLocation: any = ''

  notes = ''
  productCost = 0.0
  shippingCost = 0.0
  grandTotal = 0


  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  @ViewChild('opNotes') opNotes: OverlayPanel
  constructor(
    private _productService: ProductsService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Transfer'
    this.locations = this._abd.initActLocations()
    this.isViewOnly = this._productService.isViewOnly
    this.isEdit = this._productService.isEdit
    this.isNew = this._productService.isNew
    if (this.isViewOnly || this.isEdit) {
      this.isNew = false
      this.activeItem = this._productService.activeItem

      // console.log('ACTIVE-ITEM', this.activeItem)
      this.requestedById = this.activeItem.senderById
      this._spinner.show()
      this._productService.searchSingleTransferOrder(this.activeItem.id, 2).subscribe(res => {
        if (res.success) {

          this.localActiveItem = res.data
          this.id = this.localActiveItem.id
          this.userName = this.localActiveItem.senderBy
          this.requestedById = this.localActiveItem.senderById
          this.transferId = res.data.transferNumber
          this.dataList = res.data.stockTransfersDetails
          this.shippingCost = res.data.shipping
          this.notes = res.data.notes
          this.creationDate = new Date(this.activeItem.createdDate)
          this.expectedDate = new Date(this.activeItem.dueDate)

          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].costPrice = this.dataList[i].supplyPrice
            this.dataList[i].description = this.dataList[i].productName
            this.dataList[i].expiryDate = new Date(this.dataList[i].expiryDate)
            this.dataList[i].totalCost = this.dataList[i].costPrice * this.dataList[i].transferQuantity
            this.dataList[i].stockInHand = this.dataList[i].stockInHand
            this.dataList[i].isValueChanged = false
            this.dataList[i].isCostValueChanged = false
            this.dataList[i].uomDescription = this._abd.uom.find(f => f.id == this.dataList[i].uomId).unitName
            this.dataList[i].lineNotes = this.dataList[i].notes
          }
          this.calculateTotals()

          let lid = res.data.warehouseIdFrom
          this.fromLocation = this._abd.locations.find(f => f.id == lid)
          lid = res.data.warehouseIdTo
          this.toLocation = this._abd.locations.find(f => f.id == lid)

          if (!this.fromLocation || !this.toLocation) this._abd.showErrorAndQuit('Invalid Location')
        }

        this._spinner.hide()
      })
    }
    else {
      try {
        this.fromLocation = this._abd.selectedLocation
        if (this.fromLocation) this.toLocation = this.locations.find(f => f.locationId !== this.fromLocation.locationId)
        this._abd.selectedExtra1Location = this.toLocation
        this.transferId = Math.floor(Date.now() / 1000).toString()
        this.userName = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
        this.requestedById = this._globals.userInfo.userID
        if (!this.localActiveItem) {
          this.localActiveItem = {
            transferNumber: ''
          }
        }
        this.localActiveItem.transferNumber = this.transferId
      } catch { }
    }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
  }

  searchButtonClick(e) {
    if (this.isViewOnly) return
    if (!this.searchText.length) return
    if (this.searchText == this.lastSearchText) {
      this.op.toggle(e)
      return
    }

    this.isLoading = true
    this.isListLoading = true
    this.performSearch(e)
    // this.op.toggle(e)

  }

  performSearch(e) {
    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('locationId', this.fromLocation.id)
    params = params.append('filterValue', this.searchText)

    this._spinner.show()
    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {
        this._spinner.hide()

        this.isLoading = false
        this.isListLoading = false
        this.searchList = res.data
        for (let i = 0; i < this.searchList.length; i++) {
          this.searchList[i].transferQuantity = 0
          this.searchList[i].totalCost = 0
          this.searchList[i].selected = false
          //this.searchList[i].costPrice = 12.13 // to be deleted
        }

        this.lastSearchText = this.searchText

        if (this.searchList.length == 1) {
          this.insertRow(this.searchList[0])
          this.op.hide()
          return
        } else
          if (this.searchList.length > 0) this.op.hide()
        this.op.show(e)
      }
    })
  }

  selectAllChanged(event) {
    if (event.checked) {
      if (this.dataList.length) this.isEnableDeleteButton = false
      else {
        this.isEnableDeleteButton = true
      }

      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = true
      }
    } else {
      this.isEnableDeleteButton = true
      for (let i = 0; i < this.dataList.length; i++) {
        this.dataList[i].selected = false
      }
    }

  }

  selectionChanged(event) {
    let ret = false
    if (!event.checked) {
      this.selectAll = this.selectAll ? !this.selectAll : this.selectAll
    }

    for (let i = 0; i < this.dataList.length; i++)
      if (this.dataList[i].selected) {

        ret = true
        break
      }

    if (event.checked) {
      const sel = this.dataList.filter(f => f.selected)
      if (sel.length == this.dataList.length) {
        this.selectAll = true
      }
      else this.selectAll = false
    }

    const sel = this.dataList.filter(f => f.selected)
    if (sel.length > 0) {
      this.isEnableDeleteButton = false
    }
    else
      this.isEnableDeleteButton = true
  }

  onRowSelect(e) {
    this.insertRow(e.data)
    //this._messageService.add({severity: 'info', summary: 'Product Selected', detail: event.data.name});
  }

  insertRow(e) {
    let data = JSON.parse(JSON.stringify(e))
    data.expiryDate = new Date()
    const found = this.dataList.find(f => f.productId == data.productId)
    if (found) {
      this._messageService.add({ severity: 'error', summary: 'Product already selected ', detail: data.itemName })
      return
    }

    let sku = ''
    if (this.isEdit)
      sku = data.skuItemCode
    else
      sku = data.skuCode

    let uom = this._abd.uom.find(f => f.id == data.uomId)
    data.uomDescription = uom.unitName
    data.productName = data.itemName
    data.description = data.itemName
    data.itemSKUId = data.itemSkuId
    data.isValueChanged = true
    data.transferQuantity = 1
    data.totalCost = data.costPrice * data.transferQuantity
    data.lineNotes = ''
    //data.skuItemCode =  data.skuCode
    this.op.hide()

    this.dataList.push(data)
    this.calculateTotals()
  }

  transferQuantityChanged(e, item) {
    try {
      item.transferQuantity = e.value
      if (!item.transferQuantity) item.transferQuantity = 0
      item.totalCost = item.costPrice * e.value
      item.isValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  transferQuantityValid(item: any) {
    if (!item.isValueChanged) return true
    if (!item.transferQuantity) return false
    if (item.transferQuantity > item.stockInHand) return false
    return true
  }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
      item.totalCost = item.costPrice * item.transferQuantity
      item.isCostValueChanged = true
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  costPriceValid(item: any) {
    if (!item.isCostValueChanged) return true
    if (!item.costPrice) return false
    if (item.costPrice < 0) return false
    return true
  }

  locationFromChanged(e) {
    return
    let params = new HttpParams()
    params = params.append('startDate', '1800-01-01');
    params = params.append('endDate', formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    params = params.append('pageNo', 0);
    params = params.append('pageSize', 99999);
    params = params.append('locationId', this.fromLocation.id.toString())
    params = params.append('filterValue', '')

    this._productService.getAllProductList(params).subscribe(res => {
      if (res.success) {

        // this.searchList = res.data
        let list = res.data
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < this.searchList.length; j++) {
            if (list[i].ProductId == this.searchList[j].ProductId) {
              this.searchList[j].stockInHand = list[i].stockInHand
              break
            }
          }
        }
      }
    })
  }

  validateLocation() { }

  shippingCostChanged(e) {
    this.shippingCost = e.value
    this.calculateTotals()
  }

  calculateTotals() {
    this.grandTotal = this.shippingCost
    for (let i = 0; i < this.dataList.length; i++) {
      this.grandTotal += this.dataList[i].totalCost
      this.dataList[i].srNo = i + 1
    }
    this.productCost = this.grandTotal - this.shippingCost
  }

  removeItem() {
    this.dataList = this.dataList.filter(f => !f.selected)
    for (let i = 0; i < this.searchList.length; i++) {
      this.searchList[i].selected = false
    }
    this.isEnableDeleteButton = true
    this.calculateTotals()
    if (this.dataList.length == 0) {
      this.selectAll = false;
    }
  }

  isOk2Save() {
    if (this.isViewOnly) return false
    if (this.dataList.length < 1) return false
    try {
      if (this.fromLocation.id == this.toLocation.id) {
        if (!this.isLocationError) this.isLocationError = true
        return false
      }
    } catch { return false }

    for (let i = 0; i < this.dataList.length; i++) {
      if (!this.transferQuantityValid(this.dataList[i])) return false

      try {
        if (!this.dataList[i].expiryDate) return false
        let date = new Date(this.dataList[i].expiryDate)
        if (isNaN(date.getTime())) return false
      }
      catch { return false }
    }
    return true
  }

  saveTransferInventory(what: any) {
    if (!this.isOk2Save()) return
    const lst = this.dataList
      .filter(f => f.transferQuantity > 0
        && f.transferQuantity <= f.stockInHand)

    if (!lst.length) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Nothing to save' })
      return
    }

    let mlist = []
    for (let i = 0; i < lst.length; i++) {
      let sku = ''
      if (this.isEdit)
        sku = lst[i].skuItemCode
      else
        sku = lst[i].skuCode

      const m = {
        RequestedById: '',
        StockTransferId: this.transferId,
        ProductId: lst[i].productId,
        SkuItemCode: lst[i].sku,
        ProductName: lst[i].productName,
        Barcode: lst[i].barcode,
        UOMId: lst[i].uomId,
        UOMDescription: lst[i].uomDescription,
        EnableExpiryDate: true,
        ItemSKUId: lst[i].ItemSKUId,
        StockInHand: lst[i].stockInHand,
        EnableCostPrice: true,
        CostPrice: lst[i].costPrice,
        TransferQuantity: lst[i].transferQuantity,
        SupplyPrice: lst[i].costPrice,
        SubTotal: lst[i].totalCost,
        TaxRate: 0,
        TaxAmount: 0,
        DiscountPercent: 0,
        DiscountAmount: 0,
        TotalAmount: this.grandTotal,
        Notes: lst[i].lineNotes,
        expiryDate: formatDate(new Date(lst[i].expiryDate), 'yyyy-MM-dd', 'en-US')
      }
      mlist.push(m)
    }

    let id = 0
    let transferNo = ''
    if (this.isNew) transferNo = this.transferId
    else transferNo = this.localActiveItem.transferNumber

    const data = {
      warehouseIdTo: this.toLocation.locationId,
      warehouseTo: this.toLocation.locationName,
      warehouseIdFrom: this.fromLocation.locationId,
      warehouseFrom: this.fromLocation.locationName,
      dated: formatDate(new Date(this.creationDate), 'yyyy-MM-dd', 'en-US'),
      dueDate: formatDate(new Date(this.expectedDate), 'yyyy-MM-dd', 'en-US'),
      vendor: '',
      senderBy: this.userName,
      senderById: this.requestedById,
      receivedById: "",
      receivedBy: "",
      discountTotal: 0,
      grandTotal: this.grandTotal,
      transferNumber: transferNo,
      subTotal: this.productCost,
      taxTotal: 0,
      informViaEmail: false,
      informViaNotification: false,
      stockTransfersDetails: mlist,
      enableDueDate: false,
      enableCostPrice: true,
      enableExpiryDate: false,
      notes: this.notes,
      shipping: this.shippingCost,
      TransfersInStatus: 1,
      TransfersOutStatus: what,
      totalQuantity: this.dataList.reduce((a, b) => a + b.transferQuantity, 0),
      id: this.id,
      ReceivedNotes: ''
    }

    this._spinner.show()
    this._productService.transferInventoryPost(data).subscribe(res => {
      if (res.success) {
        this._messageService.add({
          severity: 'info',
          summary: 'Success', detail: 'Transfer invoice saved successfully!'
        });
        window.history.back()
        this._spinner.hide()
      }
    })
  }

  editButtonClick() {
    this._productService.isViewOnly = !this._productService.isViewOnly
    this.isViewOnly = !this.isViewOnly
    this.isEdit = !this.isEdit
    this._productService.isEdit = !this._productService.isEdit
  }

  calculateItemTotal() {
    let total = 0
    for (let i = 0; i < this.dataList.length; i++) {
      total += this.dataList[i].transferQuantity
    }
    return total
  }

  doShowFooter() {
    if (this.dataList.length > 0) return true
    else false
  }
  backButtonClicked(){
    window.history.back() 
}
  lineNotesEditClick(e, item) {
    this.activeItem4Editing = item
    this.opNotes.toggle(e)
  }

  cancelTransfer() {
    const data = {
      senderBy: this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName,
      senderById: this._globals.userInfo.userID,
      TransfersInStatus: 1,
      TransfersOutStatus: 2,
      id: this.id,
    }

    this._confirmationService.confirm({
      message: 'All data for this transaction will be lost. Do you wish to continue?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._abd.showSpinner()
        try {
          this._spinner.show()
          this._productService.transferInventoryPost(data).subscribe(res => {
            if (res.success) {
              this._messageService.add({
                severity: 'info',
                summary: 'Success', detail: 'Transfer invoice cancelled successfully!'
              });
              window.history.back()
              this._spinner.hide()
            }
          })
        } catch {
          this._abd.hideSpinner()
        }
      },
      reject: () => { }
    });
  }

  // getTransactionClass(data) {
  //   if (data == 0) return 'p-mr-2 new-chip'
  //   if (data == 1) return 'p-mr-2 draft-chip'
  //   if (data == 2) return 'p-mr-2 cancelled-chip'
  //   if (data == 3) return 'p-mr-2 posted-chip'
  //   if (data == 4) return 'p-mr-2 transferred-chip'
  //   if (data == 5) return 'p-mr-2 completed-chip'
  //   return ''
  // }

}
